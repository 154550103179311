import { Component, OnInit, Output } from '@angular/core';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { FormArrayName } from '@angular/forms';

@Component({
  selector: 'app-report-course-avg',
  templateUrl: './report-course-avg.component.html',
  styleUrls: ['./report-course-avg.component.scss']
})
export class ReportCourseAvgComponent implements OnInit {
  dataSourse = new Array<any>();
  reportName = 'reporte de resultados';
  loading: boolean;
  listUser;
  listUserCourse;

  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getReportData();
  }

  getReportData() {
    this.loading = true;
    this.getUsers();

  }

  transformData() {
    const userCompleteInfo = new Array<any>();
    this.listUser.forEach((user) => {
      this.listUserCourse.forEach((userCourse) => {
        if (userCourse.userCourseCode === user.userCode) {
          userCompleteInfo.push({
            userCode: userCourse.userCourseCode,
            userName: user.name,
            userEmail: user.email,
            userRole: user.userRole,
            userCourses: userCourse.userCourses
          });
        }
      });
    });
    const userCompleteInfoReport = new Array<any>();
    const courses = new Array<any>();
    userCompleteInfo.forEach((completeInfo) => {
      if (completeInfo.userCourses !== undefined) {
        completeInfo.userCourses.forEach((course) => {
          if (course.courseExamResults !== undefined) {
            course.courseExamResults.forEach(exam => {
              courses.push({ course, exam });
            });
          }
        });
      }
    });
    courses.forEach((course) => {
      const findCourse = userCompleteInfoReport.find(x => x.courseCode === course.course.courseCode);
      if (findCourse === undefined) {
        const courseAvg = {
          courseCode: course.course.courseCode,
          courseName: course.course.courseName,
          counter: 1,
          avg: Math.round(((course.exam.examScore / course.exam.examScoreOver) * 100))
        };
        userCompleteInfoReport.push(courseAvg);
      } else {
        const courseAvg = {
          courseCode: course.course.courseCode,
          courseName: course.course.courseName,
          counter: findCourse.counter + 1,
          avg: findCourse.avg + Math.round(((course.exam.examScore / course.exam.examScoreOver) * 100))
        };
        const indexFindCourse = userCompleteInfoReport.indexOf(findCourse);
        userCompleteInfoReport.splice(indexFindCourse, 1);
        userCompleteInfoReport.push(courseAvg);
      }
    });
    const userReport = new Array<any>();
    userCompleteInfoReport.forEach((result) => {
      userReport.push({
        Curso: result.courseName,
        Resultado: ((result.avg / result.counter).toFixed(2).toString())
      });
    });
    this.dataSourse = userReport;
  }

  getUserCourse() {
    this.loading = true;
    const dataSourseBackend = new Array<any>();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          this.listUserCourse = result.body;
          this.transformData();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return dataSourseBackend;
  }

  getUsers() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const dataSourseBackend = new Array<any>();
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;

          listUsers.forEach(user => {

            const userId = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

            const userForTable = {
              userCode: userId.Value,
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              company: {
                companyCode: companyCode.Value,
              },
              userRoleName: undefined,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;
              default:
                userForTable.userRoleName = user.Attributes[1].Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                dataSourseBackend.push(userForTable);
              }
            }

          });
          this.listUser = dataSourseBackend;
          this.getUserCourse();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }
}
