<nb-card size="tiny" class="solar-card">
    <nb-card-header>Estado de usuarios</nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="col-md-6">
                <div echarts [options]="options" class="echart">
                </div>
                <div class="info">
                    <div>{{actualUsers}} Usuarios </div>
                    <div class="details subtitle-2"><span> de </span>{{maxUsers}}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div echarts [options]="options2" class="echart">
                </div>
                <div class="info">
                    <div>{{actualEditor}} Editores</div>
                    <div class="details subtitle-2"><span> de </span>{{maxEditor}}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div echarts [options]="options3" class="echart">
                </div>
                <div class="info">
                    <div>{{actualAU}} Administradores de usuarios </div>
                    <div class="details subtitle-2"><span> de </span>{{maxAU}}</div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>