<app-loading *ngIf="loading"></app-loading>
<h3>Cursos</h3>
<div *ngFor="let course of courseList" class="size">
    <nb-flip-card [showToggleButton]="false" [flipped]="course.courseFlippedStatus">
        <nb-card-front>
            <nb-card accent="info" status="warning" class="flip-card-size nbCard-header-size-position">
                <nb-card-header class="nbCard-header-size-position" style="background-color: dimgrey;">
                    <button nbButton ghost class="view-course-button" nbTooltip="Asignar Curso"
                        (click)="assignCourse(course)" style="color:#FF9900; float: left;" *ngIf="!userEditor">
                        <nb-icon icon="person-add-outline" style="height: 30px !important; width: 30px !important;">
                        </nb-icon>
                    </button>
                    <div style="margin: auto; height: 100%;">
                        <span style="line-height: 2rem;">{{course.courseName}}</span>
                    </div>
                </nb-card-header>
                <nb-card-body>
                    <img class="courseImage" (click)="viewCourse(course.courseCode)"
                        [src]="course.courseImage ? course.courseImage : 'assets/images/mTraining.png'">
                </nb-card-body>
                <nb-card-footer class="front-card-footer-div-goBack">
                    <div class="row">
                        <div class="col-md" style="padding-top: 0.4rem;">
                            <button nbButton ghost style="color: dimgray;" size="giant; padding-top: 0 !important" class="info-button"
                            nbTooltip="Editar curso" (click)="edit(course.courseCode)" *ngIf="!userAdmin">
                            <nb-icon icon="edit-outline" style="height: 30px !important; width: 30px !important;"></nb-icon>
                            </button>
                        </div>
                        <div class="col-md" style="padding-top: 0.4rem;">
                            <!-- <nb-icon *ngIf="course.showGoldCertified" icon="award-outline" style="height: 30px !important; width: 30px !important; color: goldenrod"></nb-icon> -->
                        </div>
                        <div class="col-md">
                            <button nbButton ghost class="view-course-button" size="giant" nbTooltip="Ver curso"
                                (click)="viewCourse(course.courseCode,course.courseScormSelected)">
                                <nb-icon icon="eye-outline" style="height: 40px !important; width: 40px !important;"></nb-icon>
                            </button>
                            <button nbButton ghost style="color: #FF9900;" (click)="toggleView(course)" size="giant"
                                class="info-button" nbTooltip="Información">
                                <nb-icon icon="info-outline" style="height: 30px !important; width: 30px !important;"></nb-icon>
                            </button>
                        </div>
                    </div>
                </nb-card-footer>
            </nb-card>
        </nb-card-front>
        <nb-card-back>
            <nb-card accent="info" status="warning" class="flip-card-size">
                <nb-card-header class="nbCard-header-size-position"
                    style="background-color: dimgrey; text-align: -webkit-left; text-align: center;">
                    <h6 style="color: white;">Descripción</h6>
                </nb-card-header>
                <nb-card-body style="padding-top: 1px">
                    <p style="text-align: justify"><strong>{{course.courseDescription}}</strong></p>
                </nb-card-body>
                <nb-card-footer class="back-card-footer-div-goBack">
                    <button nbButton ghost style="color: #FF9900;" (click)="toggleView(course)" size="giant"
                        class="info-button" nbTooltip="Regresar">
                        <nb-icon icon="undo-outline" style="height: 40px !important; width: 40px !important;"></nb-icon>
                    </button>
                </nb-card-footer>
            </nb-card>
        </nb-card-back>
    </nb-flip-card>
</div>
