<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <div>
        <nb-card style="height: 20rem !important;">
            <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
                <h4 style="color: white !important;">Departamento</h4>
            </nb-card-header>
            <nb-card-body size="large" style="text-align: center !important; margin-top: 4rem;">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <input type="text" nbInput fullWidth id="department" name="department" [(ngModel)]="department"
                                placeholder="Departamento" maxlength="80" required>
                        </div>
                    </div>
                </div>
                <button type="button" nbButton ghost style="margin-right: 17rem; color: #0A7E12 !important;" (click)="save()">Guardar</button>
                <button type="button" nbButton ghost style="margin-left: 5px; color: #C81016 !important;" (click)="close()">Cancelar</button>
            </nb-card-body>
        </nb-card>
    </div>
</div>