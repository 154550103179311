import { Component, OnInit, OnChanges } from '@angular/core';
import { Course } from 'src/app/models/course';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { CourseAssignComponent } from './course-assign/course-assign.component';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit, OnChanges {

  loading = false;
  courseList: Course[];
  userAdmin;
  userEditor;


  constructor(
    private router: Router,
    private courseService: CourseService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
        if (user.userRole.toString() === "user_administrator") {
      this.userAdmin = true;
    }
    if (user.userRole.toString() === "editor") {
      this.userEditor = true;
    }
    this.listAllcourse();
  }

  edit(courseCode) {
    this.router.navigate(['/courseFields/' + courseCode]);
  }

  ngOnChanges() {
  }

  toggleView(course) {
    if (course.courseFlippedStatus === undefined) {
      course.courseFlippedStatus = false;
    } else if (course.courseFlippedStatus === false) {
      course.courseFlippedStatus = true;
    } else {
      course.courseFlippedStatus = false;
    }
  }

  viewCourse(courseCode, courseScormSelected = false) {
    if (courseScormSelected === true) {
      this.router.navigate(['/courseScormPlayer/' + courseCode]);
    } else {
      this.router.navigate(['/courseVideoViewer/' + courseCode]);
    }
  }

  assignCourse(course) {
    if (course.courseOrder !== undefined) {
      this.dialogService.open(CourseAssignComponent,
        {
          context: {
            course: course
          },
          hasBackdrop: true,
          closeOnEsc: false,
          closeOnBackdropClick: false
        }).onClose
        .subscribe(
          () => {
          });
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('', 'No es posible asignar curso sin definir orden al mismo', { position, status });
      this.loading = false;
    }

  }

  listAllcourse() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const userCompany = userLogged.company.companyCode.toString();
    this.courseService.allCourseCompanyBackend(userCompany)
      .then(
        (result) => {
          const courseList = result.body;
          courseList.forEach(element => {
            element.courseFlippedStatus = false;
            // element.courseExam.forEach(exam => {
            //   if (exam.examFinal === true){
            //     if (exam.examFinalCertified === true){
            //       element['showGoldCertified'] = true;
            //     }
            //   }
            // });
          });
          this.courseList = courseList;
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );

  }

}
