import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss']
})
export class UserResetPasswordComponent implements OnInit {
  username: string;
  validationCode: string;

  constructor(
    private authService: AuthService,
    private dialogRef: NbDialogRef<UserResetPasswordComponent>) { }

  ngOnInit() {
  }

  onForgotPassword() {
    const username = this.username.trim().toLowerCase();
    this.authService.forgotPassword(username);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
