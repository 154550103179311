import { NewCourseNotificationSES } from './services/newCourseNotificationSES';
import { BarChartCourseNotesComponent } from './pages/charts/user-admin-chart/bar-chart-course-notes/bar-chart-course-notes.component';
import { SesService } from './services/sesService';
import { NgxEchartsModule } from 'ngx-echarts';
import { UserAdminChartComponent } from './pages/charts/user-admin-chart/user-admin-chart.component';
import { UserConfirmationComponent } from './pages/Auth/user-confirmation/user-confirmation.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbCardModule,
  NbIconModule,
  NbInputModule,
  NbButtonModule,
  NbToastrModule,
  NbSelectModule,
  NbMenuModule,
  NbStepperModule,
  NbListModule,
  NbActionsModule,
  NbProgressBarModule,
  NbRadioModule,
  NbDialogModule,
  NbTabsetModule,
  NbTooltipModule,
  NbSearchModule,
  NbCheckboxModule,
  NbUserModule,
  NbChatModule,
  NbAlertModule,
  NbToggleModule, NbDatepickerModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { LoginComponent } from './pages/Auth/login/login.component';
import { NbAuthModule } from '@nebular/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { VgBufferingModule } from 'videogular2/compiled/src/buffering/buffering';
import { VgOverlayPlayModule } from 'videogular2/compiled/src/overlay-play/overlay-play';
import { VgControlsModule } from 'videogular2/compiled/src/controls/controls';
import { VgCoreModule } from 'videogular2/compiled/src/core/core';
import { VideoPlayerComponent } from './core/tools/video-player/video-player.component';
import { CourseComponent } from './pages/course/course.component';
import { NewCourseComponent } from './pages/new-course/new-course.component';
import { NewCourseFieldsComponent } from './pages/new-course/new-course-fields/new-course-fields.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NewCourseToolsComponent } from './pages/new-course/new-course-tools/new-course-tools.component';
import { NewCourseVideoComponent } from './pages/new-course/new-course-fields/new-course-video/new-course-video.component';
import { NewCourseVideoTableComponent } from './pages/new-course/new-course-fields/new-course-video-table/new-course-video-table.component';
import { LoadingComponent } from './core/loading/loading.component';
import { NewCourseTableComponent } from './pages/new-course/new-course-table/new-course-table.component';
import { NewCourseAttachmentComponent } from './pages/new-course/new-course-fields/new-course-attachment/new-course-attachment.component';
import { NgScormPlayerModule } from 'ng-scorm-player';
import {
  NewCourseAttachmentTableComponent
} from './pages/new-course/new-course-fields/new-course-attachment-table/new-course-attachment-table.component';
import { ProgressBarComponent } from './core/tools/progress-bar/progress-bar.component';
import { NewUserComponent } from './pages/new-user/new-user.component';
import { NewUserTableComponent } from './pages/new-user/new-user-table/new-user-table.component';
import { NewUserToolsComponent } from './pages/new-user/new-user-tools/new-user-tools.component';
import { NewUserFieldsComponent } from './pages/new-user/new-user-fields/new-user-fields.component';
import { RouterModule } from '@angular/router';
import { CourseVideoViewerComponent } from './pages/course/course-video-viewer/course-video-viewer.component';
import { NewCourseExamTableComponent } from './pages/new-course/new-course-fields/new-course-exam-table/new-course-exam-table.component';
import { NewCourseExamComponent } from './pages/new-course/new-course-fields/new-course-exam/new-course-exam.component';
import { CourseExamViewerComponent } from './pages/course/course-exam-viewer/course-exam-viewer.component';
import { NewCourseImageComponent } from './pages/new-course/new-course-fields/new-course-image/new-course-image.component';
import { CountdownModule } from 'ngx-countdown';

import { NewCourseOrderComponent } from './pages/new-course/new-course-fields/new-course-order/new-course-order.component';
import { ConfirmWindowComponent } from './core/tools/confirm-window/confirm-window.component';
import { UserResetPasswordComponent } from './pages/Auth/user-reset-password/user-reset-password.component';
import { ExamResultComponent } from './pages/course/course-exam-viewer/exam-result/exam-result.component';
import { MyCourseComponent } from './pages/course/my-course/my-course.component';
import { CourseAssignComponent } from './pages/course/course-assign/course-assign.component';
import { UserChartComponent } from './pages/charts/user-chart/user-chart.component';
import { UserEditorChartComponent } from './pages/charts/user-editor-chart/user-editor-chart.component';
import { UserFotgotPasswordViewComponent } from './pages/Auth/user-fotgot-password-view/user-fotgot-password-view.component';
import { ReportViewerComponent } from './core/tools/report-viewer/report-viewer.component';
import { ReportUserComponent } from './pages/report/report-user/report-user.component';
import { KeysPipe } from './core/tools/report-viewer/keys.pipe';
import { ReportUserResultComponent } from './pages/report/report-user-result/report-user-result.component';
import { ReportCourseAvgComponent } from './pages/report/report-course-avg/report-course-avg.component';
import { DispersionChartAdminComponent } from './pages/charts/user-admin-chart/dispersion-chart-admin/dispersion-chart-admin.component';
import { UserMyProfileComponent } from './pages/Auth/user-my-profile/user-my-profile.component';
import { UserMyProfileImageComponent } from './pages/Auth/user-my-profile-image/user-my-profile-image.component';
import { CompanyComponent } from './pages/company/company.component';
import { CompanyTableComponent } from './pages/company/company-table/company-table.component';
import { CompanyToolsComponent } from './pages/company/company-tools/company-tools.component';
import { CompanyFieldsComponent } from './pages/company/company-fields/company-fields.component';
import { StorageAdminChartComponent } from './pages/charts/user-admin-chart/storage-admin-chart/storage-admin-chart.component';
import { CompanyImageComponent } from './pages/company/company-fields/company-image/company-image.component';
import {
  CompanyDepartmentsComponent
} from './pages/company/company-fields/company-departments/company-departments.component';
import {
  CompanyDepartmentsTableComponent
} from './pages/company/company-fields/company-departments-table/company-departments-table.component';
import { FilterUserAssignPipe } from './pipes/filterUserAssign.pipe';
import { FilterDepartmentAssignPipe } from './pipes/filterDepartmentAssignPipe.pipe';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentViewerComponent } from './core/tools/document-viewer/document-viewer.component';
import { ScormPlayerComponent } from './pages/course/scorm-player/scorm-player.component';
import { NewCourseSelectionTypeComponent } from './pages/new-course/new-course-selection-type/new-course-selection-type.component';
import { NewCourseScromFileComponent } from './pages/new-course/new-course-fields/new-course-scrom-file/new-course-scrom-file.component';
import { UserEditComponent } from './pages/new-user/new-user-fields/user-edit/user-edit.component';
import { UserAutoConfirmComponent } from './pages/Auth/user-auto-confirm/user-auto-confirm.component';
import { DisableAfterClickDirective } from './directives/disableAfterClickDirective';
import { ResendValidationComponent } from './pages/Auth/resend-validation/resend-validation.component';
import { UserCountStatusComponent } from './pages/charts/user-admin-chart/user-count-status/user-count-status.component';
import { CertificationService } from './services/certification.service';
import { WelcomeComponent } from './pages/landingPages/welcome/welcome.component';
import { UserNotesComponent } from './pages/report/user-notes/user-notes.component';
import { UserNotesTableComponent } from './pages/report/user-notes/user-notes-table/user-notes-table.component';
import { WelcomeAdminUserComponent } from './pages/landingPages/welcome-admin-user/welcome-admin-user.component';
import { WelcomeEditorComponent } from './pages/landingPages/welcome-editor/welcome-editor.component';
import { ViewNotesComponent } from './pages/report/user-notes/view-notes/view-notes.component';
import { ViewNotesTableComponent } from './pages/report/user-notes/view-notes/view-notes-table/view-notes-table.component';
import { ViewCourseNotesTableComponent } from './pages/report/user-notes/view-notes/view-course-notes-table/view-course-notes-table.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { ReportUserResultTableComponent } from './pages/report/report-user-result/report-user-result-table/report-user-result-table.component';
import { UnassignMultipleUsersComponent } from './pages/new-user/unassign-multiple-users/unassign-multiple-users.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoadingComponent,
    LoginComponent,
    HomeComponent,
    VideoPlayerComponent,
    CourseComponent,
    NewCourseComponent,
    NewCourseFieldsComponent,
    NewCourseToolsComponent,
    NewCourseVideoComponent,
    NewCourseVideoTableComponent,
    NewCourseTableComponent,
    NewCourseAttachmentComponent,
    NewCourseAttachmentTableComponent,
    ProgressBarComponent,
    NewUserComponent,
    NewUserTableComponent,
    NewUserToolsComponent,
    NewUserFieldsComponent,
    CourseVideoViewerComponent,
    NewCourseExamTableComponent,
    NewCourseExamComponent,
    CourseExamViewerComponent,
    NewCourseImageComponent,
    NewCourseImageComponent,
    NewCourseOrderComponent,
    UserConfirmationComponent,
    ConfirmWindowComponent,
    UserResetPasswordComponent,
    ExamResultComponent,
    MyCourseComponent,
    UserAdminChartComponent,
    CourseAssignComponent,
    UserChartComponent,
    UserEditorChartComponent,
    UserFotgotPasswordViewComponent,
    ReportViewerComponent,
    ReportUserComponent,
    KeysPipe,
    DisableAfterClickDirective,
    ReportUserResultComponent,
    ReportCourseAvgComponent,
    BarChartCourseNotesComponent,
    DispersionChartAdminComponent,
    UserMyProfileComponent,
    UserMyProfileImageComponent,
    CompanyComponent,
    CompanyTableComponent,
    CompanyToolsComponent,
    CompanyFieldsComponent,
    StorageAdminChartComponent,
    CompanyImageComponent,
    CompanyDepartmentsComponent,
    CompanyDepartmentsTableComponent,
    FilterUserAssignPipe,
    FilterDepartmentAssignPipe,
    DocumentViewerComponent,
    ScormPlayerComponent,
    NewCourseSelectionTypeComponent,
    NewCourseScromFileComponent,
    UserEditComponent,
    UserAutoConfirmComponent,
    ResendValidationComponent,
    UserCountStatusComponent,
    WelcomeComponent,
    UserNotesComponent,
    UserNotesTableComponent,
    WelcomeAdminUserComponent,
    WelcomeEditorComponent,
    ViewNotesComponent,
    ViewNotesTableComponent,
    ViewCourseNotesTableComponent,
    ReportUserResultTableComponent,
    UnassignMultipleUsersComponent,
  ],
  imports: [
    BrowserModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    AppRoutingModule,
    HttpClientModule,
    DragDropModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbDatepickerModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbCardModule,
    NbIconModule,
    NbAuthModule,
    NbInputModule,
    FormsModule,
    RouterModule,
    NbButtonModule,
    NbToggleModule,
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbSelectModule,
    NbStepperModule,
    NbListModule,
    NbActionsModule,
    NbDialogModule.forRoot(),
    NbProgressBarModule,
    ReactiveFormsModule,
    NbTabsetModule,
    NbTooltipModule,
    Ng2SmartTableModule,
    NbSearchModule,
    NbCheckboxModule,
    NbRadioModule,
    CountdownModule,
    NbUserModule,
    NgxEchartsModule,
    NbChatModule,
    NbAlertModule,
    NgxDocViewerModule,
    NgxMaterialTimepickerModule,
    NbDateFnsDateModule,
    NgScormPlayerModule.forChild({ debug: true }),
  ],
  providers: [AuthService, AuthGuard, SesService, NewCourseNotificationSES, CertificationService],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmWindowComponent,
    NewCourseVideoComponent,
    NewCourseAttachmentComponent,
    NewCourseExamComponent,
    CourseExamViewerComponent,
    NewCourseImageComponent,
    ExamResultComponent,
    UserResetPasswordComponent,
    UserEditComponent,
    UserConfirmationComponent,
    CourseAssignComponent,
    UserFotgotPasswordViewComponent,
    UserMyProfileImageComponent,
    CompanyImageComponent,
    CompanyDepartmentsComponent,
    ResendValidationComponent,
    UnassignMultipleUsersComponent
  ]
})
export class AppModule { }
