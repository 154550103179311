import { Video } from './../../../../models/video';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CourseService } from 'src/app/services/course.service';
import { Course } from 'src/app/models/course';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-course-order',
  templateUrl: './new-course-order.component.html',
  styleUrls: ['./new-course-order.component.scss']
})
export class NewCourseOrderComponent implements OnInit, OnChanges {
  @Input() course = {} as Course;
  courseOrder;
  loading: boolean;

  constructor(
    private courseService: CourseService,
    private toastrService: NbToastrService,
    private router: Router,
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.courseOrder = new Array();
    let order = 0;
    if (this.course.courseVideo !== undefined) {
      this.course.courseVideo.forEach((video) => {
        this.courseOrder.push({ resource: video.videoName, type: 'video', order, data: video });
        order++;
      });
    }
    if (this.course.courseExam !== undefined) {
      this.course.courseExam.forEach((exam) => {
        if (exam.examStatus === true) {
          this.courseOrder.push({ resource: exam.examName, type: 'exam', order, data: exam });
          order++;
        }
      });
    }
    if (this.course.courseAttachment !== undefined) {
      this.course.courseAttachment.forEach((attachment) => {
        this.courseOrder.push({ resource: attachment.attachmentName, type: 'attachment', order, data: attachment });
        order++;
      });
    }
    const courseOrderAll = this.courseOrder;
    if (this.course.courseOrder !== undefined) {
      this.courseOrder = this.course.courseOrder;
      this.courseOrder.forEach((resources, indexResources) => {
        const findOrderAttribute = courseOrderAll.find((x) => x.resource === resources.resource);
        if (findOrderAttribute === undefined) {
          this.courseOrder.splice(indexResources, 1);
        }
        courseOrderAll.forEach((Allresources) => {
          if (resources.resource === Allresources.resource) {
            resources.data = Allresources.data;
          }
        });
      });
      courseOrderAll.forEach((Allresources) => {
        const found = this.courseOrder.find(
          (x) => x.resource === Allresources.resource);
        if (found === undefined) {
          Allresources.order = order;
          this.courseOrder.push(Allresources);
          order++;
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.courseOrder, event.previousIndex, event.currentIndex);
    this.courseOrder.forEach((resourse, resourceIndex) => {
      resourse.order = resourceIndex;
    });
  }

  saveCourseOrder() {
    if (this.courseOrder === undefined) {
      this.courseOrder = new Array();
    }
    let courseValidation;
    this.courseOrder.forEach(element => {
      if (element.type === 'video' || element.type === 'attachment') {
        courseValidation = true;
      }
    });
    if (courseValidation === undefined) {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show('danger', 'Debe agregar al menos un video o un documento, para asignar orden.',
        { position, status });
      return false;
    }
    this.course.courseOrder = this.courseOrder;
    this.updateCourse();
  }

  cancel() {
    this.router.navigateByUrl('/newCourse');
  }

  updateCourse() {
    this.loading = true;
    this.courseService.updateCourseBackend(this.course)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

}
