import { Component, OnInit, HostListener } from '@angular/core';
import {
  NbSidebarService,
  NbThemeService,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
  NbComponentStatus,
  NbToastrService
} from '@nebular/theme';
import { LayoutService } from '../utils/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { UserCourse } from 'src/app/models/userCourse';
import { CompanyService } from 'src/app/services/company.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userAvatar: any;
  userPicture: any;
  userEmail: any;
  loading: boolean;
  urlAlt: string;
  urlLog = 'assets/images/mTraining.png';

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private themeService: NbThemeService,
    private authService: AuthService,
    private userService: UserService,
    private toastrService: NbToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
  ) { }

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    } else {
      this.logOut();
    }
    if (user) {
      this.userAvatar = user.name;
      this.userPicture = user.picture;
      this.userEmail = user.email;
      this.currentTheme = user.theme ? user.theme : 'default';
      this.changeTheme(this.currentTheme);
      if (user !== undefined) {
        this.userService.oneUserCourseBackend(user.userId)
          .then(
            (result) => {
              const userCourse: UserCourse = result.body !== undefined ? result.body : {userCourses: undefined};
              localStorage.setItem('userCourse', JSON.stringify(userCourse));
              this.loading = false;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
      }
      this.authService.userProfileChanged.subscribe(change => {
        if (localStorage.getItem('user') !== null) {
          user = JSON.parse(localStorage.getItem('user'));
        }
        if (user) {
          this.userAvatar = user.name;
          this.userPicture = user.picture;
        }
      });
    }
    const companyLogo = window.location.hostname;
    this.urlAlt = companyLogo.split('.')[0];
    this.companyService.oneCompanyBackend(user.company.companyCode).then((result) => {
      const logo = result.body.companyLogo;
      this.urlLog = logo === undefined ? 'assets/images/mTraining.png' : logo;
    }).catch((err) => {
      this.urlLog = 'assets/images/mTraining.png';
    });
  }

  navigateHome() {
    this.router.navigate(['']);
  }

  navigateUserProfile() {
    this.router.navigate(['userProfile']);
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    this.forceClose();
    return false;
  }

  forceClose() {
    if (this.router.url.startsWith('/courseVideoViewer')) {
      this.router.navigate(['']);
    }
  }

  logOut() {
    this.loading = true;
    if (this.router.url.startsWith('/courseVideoViewer')) {
      this.router.navigate(['']);
      setTimeout(() => {
        this.authService.logout();
        this.loading = false;
      }, 800);
    } else {
      this.authService.logout();
      this.loading = false;
    }

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

}
