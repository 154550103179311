<div class="card-col">
    <div>
      <nb-card style="height: 20rem !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
          <h4 style="color: white !important;">Reenviar correo de validación</h4>
        </nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
          <form>
            <div class="form-group">
              <div style="padding-bottom: 3px !important; ">
                <input type="text" autocomplete="off" nbInput fullWidth [(ngModel)]="username" name="username"
                  id="username" placeholder="Correo electrónico" style="width: 30rem !important; margin-bottom: 1rem;">
              </div>
            </div>
            <div class="form-group" style="text-align: -webkit-center;">
              <div style="margin-top: 5px;">
                <button type="submit" nbButton ghost style="margin-right: 17rem; color: #0A7E12 !important;"
                  (click)="onResendValid()">Reenviar</button>
                <button type="button" nbButton ghost style="margin-left: 5px; color: #C81016 !important;"
                  (click)="close()">Regresar</button>
              </div>
            </div>
          </form>
        </nb-card-body>
      </nb-card>
