<app-loading *ngIf="loading"></app-loading>
<div class="card-row">
    <div style="width: 100%;">
        <nb-card>
            <nb-card-header>
              <div class="row">
                <div class="col-md-10">
                  <h4>{{course.courseName}}</h4>
                </div>
                <div class="col-md-2">
                  <button nbButton size="small" style="float: right !important;" (click)="playlistButton()"><nb-icon icon="menu-2-outline"></nb-icon>Lista</button>
                </div>
              </div>
            </nb-card-header>
            <nb-card-body>
                <app-document-viewer *ngIf="viewer ==='attachment' && fileUrl" [url]="fileUrl"></app-document-viewer>
                <app-video-player *ngIf="viewer ==='video'" [course]="course" [currentIndex]="currentIndex"
                    [playlist]="playlist" [playPause]="playPause" [currentItem]="currentItem">
                </app-video-player>
            </nb-card-body>
            <nb-card-footer>{{course.courseDescription}}</nb-card-footer>
        </nb-card>
    </div>
    <div class="card-col2" *ngIf="!playlist">
        <nb-card style="height: 100% !important;">
            <nb-card-header>
                <h6>Lista de reproducción:</h6>
            </nb-card-header>
            <nb-card-body style="height: 100% !important;">
                <nb-list nbInfiniteList listenWindowScroll [threshold]="10">
                    <nb-list-item *ngFor="let resources of resourceList; let $index = index" style="height: 100% !important;">
                        <div *ngIf="resources.type === 'video'"><span [class.selected]="resources === currentItem"
                                (click)="onClickPlaylistItem(resources.data, $index)">{{resources.resource}}</span>
                            <nb-icon style="margin-left: 0.5rem;" *ngIf="resources.data === currentItem"
                                icon="eye-outline">
                            </nb-icon>
                            <nb-icon icon="done-all-outline" style="color: green !important;" nbTooltip="Completado"></nb-icon>
                        </div>
                        <div *ngIf="resources.type === 'attachment'"><span
                                (click)="onClickAttachmentSelect(resources.data, $index)">
                                {{resources.resource}}</span>
                        </div>
                        <div *ngIf="resources.type === 'exam'">
                            <span [class.selected]="resources.data === currentItem"
                                (click)="onClickExamSelect(resources.data, $index)">{{resources.resource}}</span>
                            <span style="margin-left: 0.5rem;">{{resources.resultEx}}</span>
                            <nb-icon icon="done-all-outline" *ngIf="resources.resultEx" style="color: green !important;" nbTooltip="Completado"></nb-icon>
                        </div>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
</div>
