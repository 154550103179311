<vg-player (onPlayerReady)="onPlayerReady($event)" *ngIf="currentItem">
  <vg-overlay-play></vg-overlay-play>
  <vg-buffering></vg-buffering>



  <vg-controls [vgAutohide]="true" [vgAutohideTime]="3">
    <vg-play-pause></vg-play-pause>
    <vg-playback-button></vg-playback-button>

    <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"></vg-time-display>

    <vg-scrub-bar>
      <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
      <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-time-display vgProperty="left" vgFormat="hh:mm:ss"></vg-time-display>

    <vg-mute></vg-mute>
    <vg-volume></vg-volume>

    <vg-fullscreen></vg-fullscreen>
  </vg-controls>

  <video #media [vgMedia]="media" class="video" [src]="currentItem.videoUrl" id="singleVideo" preload="auto"
    [autoplay]="false" crossorigin>
  </video>
</vg-player>