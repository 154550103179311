import { ResendValidationComponent } from './../resend-validation/resend-validation.component';
import { UserAutoConfirmComponent } from './../user-auto-confirm/user-auto-confirm.component';
import { UserResetPasswordComponent } from './../user-reset-password/user-reset-password.component';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NbDialogService } from '@nebular/theme';
import { CompanyService } from 'src/app/services/company.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loading = false;
  urlLog = 'assets/images/mTraining.png';
  urlAlt;
  constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit() {
    const companyLogo = window.location.hostname;
    this.urlAlt = companyLogo.split('.')[0];
    this.companyService.searchCompanyBackend(companyLogo.split('.')[0]).then((result) => {
      const logo = result.body[0].companyLogo;
      this.urlLog = logo === undefined ? 'assets/images/mTraining.png' : logo;
    }).catch((err) => {
      this.urlLog = 'assets/images/mTraining.png';
    });
  }

  onSubmit(form: NgForm) {
    const email = form.value.email;
    const password = form.value.password;
    this.authService.signIn(email, password);
  }

  async signInWithGoogle() {
    this.loading = true;
    await this.authService.socialSignIn(AuthService.GOOGLE).then(
      (auth) => {
        this.loading = false;
      }
    ).catch(
      (err) => {
        this.loading = false;
      }
    ).finally(
      () => {
        this.loading = false;
      }
    );
  }

  async signInWithAzure() {
    window.location.assign(
      'https://' +
      environment.cognitoDomain +
      '/oauth2/authorize?' +
      'identity_provider=office365&redirect_uri=' +
      environment.cognitoRedirectSignIn +
      '&response_type=code&client_id=' +
      environment.ClientId +
      '&scope=aws.cognito.signin.user.admin+email+openid+phone+profile'
    );
  }

  onClickValidateUser() {
    this.dialogService.open(UserAutoConfirmComponent,
      {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

  onClickResetPassword() {
    this.dialogService.open(UserResetPasswordComponent,
      {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

  onResendValid() {
    this.dialogService.open(ResendValidationComponent,
      {
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
  }

}
