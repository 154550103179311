import { Component, OnInit, Input } from '@angular/core';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import allUserNotesTableSettings from '../../../../../assets/config/userNotesTable/allUserNotesTableSettings.json';


@Component({
  selector: 'app-report-user-result-table',
  templateUrl: './report-user-result-table.component.html',
  styleUrls: ['./report-user-result-table.component.scss']
})
export class ReportUserResultTableComponent implements OnInit {
  dataSourse = new Array<any>();
  loading: boolean;
  listUser;
  listUserCourse;
  settings = allUserNotesTableSettings;

  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  transformData() {
    const userCompleteInfo = new Array<any>();
    this.listUser.forEach((user) => {
      this.listUserCourse.forEach((userCourse) => {
        if (userCourse.userCourseCode === user.userCode) {
          userCompleteInfo.push({
            userCode: userCourse.userCourseCode,
            userName: user.name,
            userEmail: user.email,
            userRole: user.userRole,
            userCourses: userCourse.userCourses
          });
        }
      });
    });
    const userCompleteInfoReport = new Array<any>();
    userCompleteInfo.forEach((completeInfo) => {
      if (completeInfo.userCourses !== undefined) {
        completeInfo.userCourses.forEach((course) => {
          if (course.courseExamResults !== undefined) {
            course.courseExamResults.forEach(exam => {
              userCompleteInfoReport.push({
                'Nombre': completeInfo.userName,
                'Correo': completeInfo.userEmail,
                'Curso': course.courseName,
                'Realizado': new Date(exam.examDate).toLocaleString(),
                'Examen': exam.examName,
                'Resultado': (Math.round(((exam.examScore / exam.examScoreOver) * 100)).toString()),
                'Curso completado': course.examCertified ? 'Completado' : 'Incompleto',
                'Curso certificado': course.courseCertified ? 'Aprobado' : 'Reprobado'
              });
            });
          }
        });
      }
    });
    this.dataSourse = userCompleteInfoReport;
  }

  getUserCourse() {
    this.loading = true;
    const dataSourseBackend = new Array<any>();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          this.listUserCourse = result.body;
          this.transformData();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return dataSourseBackend;
  }

  getUsers() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const dataSourseBackend = new Array<any>();
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;

          listUsers.forEach(user => {

            const userId = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

            const userForTable = {
              userCode: userId.Value,
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              company: {
                companyCode: companyCode.Value,
              },
              userRoleName: undefined,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;
              default:
                userForTable.userRoleName = user.Attributes[1].Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                dataSourseBackend.push(userForTable);
              }
            }

          });
          this.listUser = dataSourseBackend;
          this.getUserCourse();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }

}
