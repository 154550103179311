<nb-card style="height: 100%;">
    <nb-card-body>
        <div>
            <img src="assets/images/mTraining.png" style="height: 70px;">
        </div>
      <div style="text-align: center;">
          <h1>Bienvenido</h1>
      </div>
      <div style="text-align: center;">
        <h2>{{userName}}</h2>
      </div>
      <div style="text-align: center;">
        <button nbButton outline status="primary" style="background: transparent;" (click)="mycourses()"><nb-icon icon="book-outline"></nb-icon>Mis cursos</button>
      </div>
    </nb-card-body>
  </nb-card>