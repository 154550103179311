import { Component, OnInit, AfterViewInit } from '@angular/core';
import adminChartSettings from '../../../../../assets/config/adminChartSettings.json';
import { CompanyService } from 'src/app/services/company.service.js';
import { User } from 'src/app/models/user.js';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbThemeService } from '@nebular/theme';
import { EChartOption } from 'echarts';
import { Company } from 'src/app/models/company.js';

@Component({
  selector: 'app-user-count-status',
  templateUrl: './user-count-status.component.html',
  styleUrls: ['./user-count-status.component.scss']
})
export class UserCountStatusComponent implements OnInit, AfterViewInit {
  options: EChartOption;
  options2: EChartOption;
  options3: EChartOption;
  actualUsers;
  maxUsers;
  actualEditor;
  maxEditor;
  actualAU;
  maxAU;
  constructor(
    private companyService: CompanyService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {  }

  ngAfterViewInit() { 
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
      (result) => {
        const company: Company = result.body;
        this.actualUsers = company.companyCountUser;
        this.maxUsers = company.companyMaxUser;
        this.actualEditor = company.companyCountUserEditor;
        this.maxEditor = company.companyMaxUserEditor;
        this.actualAU = company.companyCountUserAdmin;
        this.maxAU = company.companyMaxUserAdmin;
        this.options = {
          // tooltip: {
          //     trigger: 'item',
          //     formatter: '{a} <br/>{b}: {c} ({d}%)'
          // },
          color: [
            "#ff8c21",
            "#b0b0b0"
                  ],
          series: [
              {
                  name: '',
                  hoverAnimation: true,
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: true,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  labelLine: {
                      show: false
                  },
                  emphasis: {
                    label: {
                        show: true,
                        fontWeight: 'bold',
                        fontSize: 5,
                    }
                },
                  data: [
                    { name: 'Usuarios Activos', value: company.companyCountUser },
                    { name: 'Usuarios Disponibles', value: (company.companyMaxUser - company.companyCountUser) }
                  ]
              }
          ]
      };
      this.options2 = {
        // tooltip: {
        //     trigger: 'item',
        //     formatter: '{a} <br/>{b}: {c} ({d}%)'
        // },
        color: [
          "#ff8c21",
          "#b0b0b0"
                ],
        series: [
            {
                name: '',
                hoverAnimation: true,
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: true,
                label: {
                    show: false,
                    position: 'center'
                },
                labelLine: {
                    show: false
                },
                emphasis: {
                  label: {
                      show: true,
                      fontWeight: 'bold',
                      fontSize: 5,
                  }
              },
                data: [
                  { name: 'Usuarios Activos', value: company.companyCountUserEditor },
                  { name: 'Usuarios Disponibles', value: (company.companyMaxUserEditor - company.companyCountUserEditor) }
                ]
            }
        ]
    };
    this.options3 = {
      // tooltip: {
      //     trigger: 'item',
      //     formatter: '{a} <br/>{b}: {c} ({d}%)'
      // },
      color: [
        "#ff8c21",
        "#b0b0b0"
              ],
      series: [
          {
              name: '',
              hoverAnimation: true,
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: true,
              label: {
                  show: false,
                  position: 'center'
              },
              labelLine: {
                  show: false
              },
              emphasis: {
                label: {
                    show: true,
                    fontWeight: 'bold',
                    fontSize: 5,
                }
            },
              data: [
                { name: 'Usuarios Activos', value: company.companyCountUserAdmin },
                { name: 'Usuarios Disponibles', value: (company.companyMaxUserAdmin - company.companyCountUserAdmin) }
              ]
          }
      ]
  };
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    );
  }

}
