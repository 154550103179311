import { CourseService } from './../../../../services/course.service';
import { Component, OnInit, Output } from '@angular/core';
import { User } from 'src/app/models/user.js';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbThemeService } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-bar-chart-course-notes',
  templateUrl: './bar-chart-course-notes.component.html',
  styleUrls: ['./bar-chart-course-notes.component.scss']
})
export class BarChartCourseNotesComponent implements OnInit {
  loading: boolean;
  options: any;
  courseName;
  listUser: any;
  listUserCourse: any;
  dataSourse: any[];

  constructor(
    private toastrService: NbToastrService,
    private courseService: CourseService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getReportData();
  }

  getReportData() {
    this.loading = true;
    this.getUsers();

  }

  transformData() {
    const userCompleteInfo = new Array<any>();
    this.listUser.forEach((user) => {
      this.listUserCourse.forEach((userCourse) => {
        if (userCourse.userCourseCode === user.userCode) {
          userCompleteInfo.push({
            userCode: userCourse.userCourseCode,
            userName: user.name,
            userEmail: user.email,
            userRole: user.userRole,
            userCourses: userCourse.userCourses
          });
        }
      });
    });
    const userCompleteInfoReport = new Array<any>();
    let avg = 0;
    let count = 0;
    let courseName;
    let courseCounter = 0;
    const courseNames = new Array();
    const coursePoints = new Array();
    userCompleteInfo.forEach((completeInfo) => {
      if (completeInfo.userCourses !== undefined) {
        completeInfo.userCourses.forEach((course) => {
          if (course.courseExamResults !== undefined) {
            course.courseExamResults.forEach(exam => {
              count++;
              avg = avg + Math.round(((exam.examScore / exam.examScoreOver) * 100));
              if (courseName !== undefined) {
              }
            });
            courseName = course.courseName;
            if (courseCounter < completeInfo.userCourses.length) {
              if (courseName !== undefined) {
                courseNames.push(courseName);
                coursePoints.push(((avg / count).toFixed(2).toString()));
              }
            }
            courseCounter++;
          }
        });
      }
    });
    userCompleteInfoReport.push({
      Curso: courseName,
      Resultado: ((avg / count).toFixed(2).toString())
    });
    // const courseNames = new Array();
    // const coursePoints = new Array();
    // if (courseName !== undefined) {
    //   courseNames.push(courseName);
    //   coursePoints.push(((avg / count).toFixed(2).toString()));
    // }

    this.options = {
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      yAxis: [
        {
          type: 'category',
          data: courseNames,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      xAxis: [
        {
          type: 'value',
          max: 100
        }
      ],
      series: [
        {
          name: 'Nota promedio del curso',
          type: 'bar',
          barWidth: '25%',
          data: coursePoints
        }
      ]
    };
  }

  getUserCourse() {
    this.loading = true;
    const dataSourseBackend = new Array<any>();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          this.listUserCourse = result.body;
          this.transformData();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return dataSourseBackend;
  }

  getUsers() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const dataSourseBackend = new Array<any>();
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;
          listUsers.forEach(user => {
            const userId = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');
            const userForTable = {
              userCode: userId.Value,
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              company: {
                companyCode: companyCode.Value,
              },
              userRoleName: undefined,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;
              default:
                userForTable.userRoleName = user.Attributes[1].Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                dataSourseBackend.push(userForTable);
              }
            }

          });
          this.listUser = dataSourseBackend;
          this.getUserCourse();
          this.loading = false;
        }
      ).catch(
        (err) => {
          console.error("Aqui entre al error de getUser" + err);
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }

}
