import { Component, OnInit, Output } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-notes',
  templateUrl: './view-notes.component.html',
  styleUrls: ['./view-notes.component.scss']
})
export class ViewNotesComponent implements OnInit {
  loading: boolean;
  @Output() listCourses;
  userName;

  constructor(
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const userCode = params.params.userCode;
      if (userCode !== undefined) {
        this.listCourses = userCode;
        this.userInfo(userCode);
      }
    });
  }

  userInfo(userCode) {
    this.authService.getUser(userCode).then((result: any) => {
      const userBackend = result.UserAttributes;
      const name = userBackend.find((x) => x.Name === 'name');
      this.userName = name.Value;
    }).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
      this.loading = false;
    });
  }

}
