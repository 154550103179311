import { CourseExamViewerComponent } from './../course-exam-viewer.component';
import { NbDialogRef } from '@nebular/theme';
import { Component, OnInit, Input } from '@angular/core';
import { CertificationService } from 'src/app/services/certification.service';
import { UserService } from 'src/app/services/user.service';
import { UserCourse } from 'src/app/models/userCourse';
import { Exam } from 'src/app/models/exam';
import { User } from 'src/app/models/user';
import { Course } from 'src/app/models/course';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.scss']
})
export class ExamResultComponent implements OnInit {
  @Input() examResult;
  @Input() examResultOf;
  @Input() courseName;
  @Input() examCountQuestions;
  @Input() examType;
  company = {} as any;
  userCourse = {} as UserCourse;
  public lambdaResponse: any;
  nota;
  user;

  constructor(
    private dialogRef: NbDialogRef<ExamResultComponent>,
    private certificationService: CertificationService,
    private companyService: CompanyService
    ) { }

  ngOnInit() {
    const e: User = JSON.parse(localStorage.getItem('user'));
    this.user = e;
    const c: Course = JSON.parse(localStorage.getItem('userCourse'));
    // console.log(this.user);
    this.nota = ((100 / this.examCountQuestions) * this.examResult);
    this.listCourse();
    this.getCompany();
  }

  getCompany() {
    this.companyService.oneCompanyBackend(this.user.company.companyCode).then(
      (result) => {
        this.company = result.body;
        // console.log(this.company);
      }
    ).catch(
      (err) => {
      }
    );
  }

  listCourse(){
      if (localStorage.getItem('userCourse') !== null) {
        const uc = JSON.parse(localStorage.getItem('userCourse'));
        this.userCourse = {
          userCourseCode: uc.userCourseCode,
          userCourses: uc.userCourses
        };
    }
  }

  public async sendCert(){
    if (this.nota >= 50){
      if (this.examType === true){
      // if(this.userCourse.userCourses[0].examCertified !== true){
        let request = {
          userName: this.user.name,
          userEmail: this.user.email,
          userCourse: this.courseName,
          userCompany: this.company.companyName,
          userCompanyImage: this.company.companyLogo
        };
        let response = await this.certificationService.sendEmail(request);
        // console.log(response);
      } else {
      }
    }
  }


  close() {
    this.sendCert();
    this.dialogRef.close();
  }

}
