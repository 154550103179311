import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Course } from 'src/app/models/course';
import { Video } from 'src/app/models/video';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { VgAPI } from 'videogular2/compiled/src/core/core';
import { CourseExamViewerComponent } from '../course-exam-viewer/course-exam-viewer.component';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { UserCourse, UserCourseCourseVideoProgress, UserCourseCourses } from 'src/app/models/userCourse';
import { Exam } from 'src/app/models/exam';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-course-video-viewer',
  templateUrl: './course-video-viewer.component.html',
  styleUrls: ['./course-video-viewer.component.scss']
})
export class CourseVideoViewerComponent implements OnInit {
  loading = false;
  course = {} as Course;
  video = {} as Video;
  videoList: Video[];
  videoProgress: UserCourseCourseVideoProgress[];
  userCourseCourse: UserCourseCourses[];
  resourceList: { resource, type, order, data };
  currentProgress = 0;
  playlist = false;
  playPause;
  viewer;
  fileUrl;
  videoCompleted;

  currentIndex = 0;
  currentItem;
  api: VgAPI;

  constructor(
    private route: ActivatedRoute,
    private courseService: CourseService,
    private userService: UserService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const videoCode = params.params.courseCode;
      if (videoCode !== undefined) {
        this.course.courseCode = videoCode;
        this.getCourse();
      }
    });
    // this.listAllCourse();
  }

  onClickPlaylistItem(item, index: number) {
    this.currentIndex = index;
    this.currentItem = item;
    this.viewer = 'video';
  }

  onClickAttachmentSelect(item, index: number) {
    this.viewer = 'attachment';
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    this.courseService.signS3Url(
      {
        name: item.attachmentName
      },
      companyCode,
      this.course,
      this.viewer
    ).then(
      (result) => {
        this.fileUrl = result;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );

  }

  listAllCourse(){
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    let userCourse = {} as UserCourse;
    if (localStorage.getItem('userCourse') !== null) {
      const uc = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses,
      };
      this.userService.oneUserCourseBackend(user.userId).then(
        (result) => {
          let courseUser = result.body.userCourses[0];
          this.videoProgress = result.body.userCourses[0].courseVideo;
          this.videoProgress.forEach((video) => {
            if (video.videoStatus === true){
              this.currentProgress++;
            }
          });
          if (this.currentProgress === this.course.courseVideo.length){
            if (courseUser.courseCertified !== true){
              userCourse.userCourses[0].courseCertified = true;
              // userCourse.userCourses[0].examCertified = true;
              this.userService.updateUserCourseBackend(userCourse)
              .then(
                (result) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'success';
                  this.toastrService.show('', 'Guardado', { position, status });
                  this.loading = false;
                }
              ).catch(
                (err) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err,
                    { position, status });
                  this.loading = false;
                }
              );
            } else {
            }
          }
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    }
  }

  onClickExamSelect(exam: Exam, index) {
    this.currentIndex = 0;
    this.currentItem = this.videoList[this.currentIndex];
    let canRepete = true;
    this.playPause = false;

    let userCourse: UserCourse;
    if (localStorage.getItem('userCourse')) {
      const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses
      };
      userCourse.userCourses.forEach((userC) => {
        if (userC.courseExamResults !== undefined) {
          userC.courseExamResults.forEach((examR) => {
            if (examR.examName === exam.examName && exam.examFinal === true) {
              canRepete = false;
            }
          });
        }
      });
    }
    if (canRepete) {
      this.dialogService.open(ConfirmWindowComponent,
        {
          context: {
            message: '¿Esta seguro de comenzar el examen?'
          },
          hasScroll: false,
          hasBackdrop: true,
          closeOnEsc: false,
          closeOnBackdropClick: false
        }).onClose
        .subscribe(
          (accept) => {
            this.playPause = true;
            if (accept) {
              this.dialogService.open(CourseExamViewerComponent,
                {
                  context: {
                    course: this.course,
                    exam,
                  },
                  hasBackdrop: true,
                  closeOnEsc: false,
                  closeOnBackdropClick: false
                }).onClose
                .subscribe(
                  () => {
                    this.getCourse();
                  });
            }
          }
        );
    }
  }

  getCourse() {
    this.loading = true;
    this.courseService.oneCourseBackend(this.course.courseCode)
      .then(
        (result) => {
          this.course = result.body;
          this.videoList = new Array<Video>();
          if (this.course.courseOrder !== undefined) {
            this.viewer = this.course.courseOrder[0].type;
            this.course.courseOrder.forEach((video) => {
              if (video.type === 'video') {
                this.videoList.push(video.data);
                const point = video.resource.indexOf('.');
                video.resource = video.resource.substring(0, point);
              }
              if (video.type === 'attachment') {
                // this.fileUrl = video.data.attachmentUrl;
                const point = video.resource.indexOf('.');
                video.resource = video.resource.substring(0, point);
              }
              if (video.type === 'exam') {
                let userCourse: UserCourse;
                if (localStorage.getItem('userCourse') !== undefined) {
                  const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
                  userCourse = {
                    userCourseCode: uc.userCourseCode,
                    userCourses: uc.userCourses
                  };
                  if (userCourse.userCourses !== undefined) {
                    userCourse.userCourses.forEach((passExam) => {
                      if (passExam.courseCode === this.course.courseCode) {
                        if (passExam.courseExamResults !== undefined) {
                          passExam.courseExamResults.forEach((resultExam) => {
                            if (resultExam.examName === video.data.examName) {
                              video.resultEx = resultExam.examScore.toString() + '/' + resultExam.examScoreOver.toString();
                            }
                          });
                        }
                      }
                    });
                  }
                }
              }
            });
          } else {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              'faltan parametros al curso',
              { position, status });
          }
          this.resourceList = this.course.courseOrder;
          this.currentItem = this.videoList[this.currentIndex];
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );

  }

  playlistButton() {
    if (this.playlist === false) {
      this.playlist = true;
    } else {
      this.playlist = false;
    }
  }
}
