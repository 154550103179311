<app-loading *ngIf="loading"></app-loading>
<h4>
  <span>Nuevo Curso</span>
  <button nbButton ghost nbTooltip="Crear Curso" (click)="addNewCourse()">
    <nb-icon icon="plus-outline"></nb-icon>
  </button>
  <nb-search hint=" " type="rotate-layout" (keyup)="onSearchKeyPress($event.target.value)" tag="modal-move"
    nbTooltip="Buscar Curso" placeholder="Buscar..." style="size: inherit"></nb-search>
  <button nbButton ghost nbTooltip="Listar Cursos" (click)="listAllCourse()">
    <nb-icon icon="refresh-outline"></nb-icon>
  </button>
</h4>