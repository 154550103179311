import { Component, OnInit, DoCheck, EventEmitter } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService, NbDialogService } from '@nebular/theme';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../../services/course.service';
import { Course } from '../../../models/course';
import { NewCourseVideoComponent } from './new-course-video/new-course-video.component';
import { NewCourseAttachmentComponent } from './new-course-attachment/new-course-attachment.component';
import { NewCourseExamComponent } from './new-course-exam/new-course-exam.component';
import { NewCourseImageComponent } from './new-course-image/new-course-image.component';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
import { userInfo } from 'os';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-new-course-fields',
  templateUrl: './new-course-fields.component.html',
  styleUrls: ['./new-course-fields.component.scss']
})
export class NewCourseFieldsComponent implements OnInit {
  loading = false;
  course = {} as Course;
  courseScormSelected;
  scormView: any;
  scormToggle = false;
  company = {} as any;
  companyScormSelected: any;
  imageFilter;
  companyCode;
  showAlert = false;
  reenableButton = new EventEmitter<boolean>();

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private courseService: CourseService
  ) { }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.route.paramMap.subscribe((params: any) => {
      const courseCode = params.params.courseCode;
      if (courseCode !== undefined) {
        this.getCourse(courseCode);
      }
    });
    this.getCompany(user.company.companyCode);
    this.companyCode = user.company.companyCode;
  }

  getCourse(courseCode) {
    this.loading = true;
    this.courseService.oneCourseBackend(courseCode)
      .then(
        (result) => {
          const selectedCourse = result.body;
          this.course = selectedCourse;
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

  getCompany(companyCode) {
    this.loading = true;
    this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        this.company = result.body;
        this.companyScormSelected = result.body.companyScormSelected;
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          status || 'Danger',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  courseOnSave() {
    if (this.course.courseName === undefined && this.course.courseDescription === undefined){
      this.showAlert = true;
      this.loading = false;
    } else {
      const userLogged: User = JSON.parse(localStorage.getItem('user'));
      this.course.courseCompanyCode = userLogged.company.companyCode;
      if (this.courseScormSelected === true) {
        this.scormView = true;
      }
      this.course.courseNameForFilter = this.course.courseName.toLowerCase();
      if (this.course.deleteStatus !== undefined) { this.course.deleteStatus = 'active'; }
      if (this.course.courseCode) {
        this.updateCourse();
        this.scormView = this.course.courseScormSelected;
        this.scormToggle = true;
      } else {
        this.insertNewCourse();
      }
    }
  }

  insertNewCourse() {
    this.loading = true;
    this.courseService.insertCourseBackend(this.course)
      .then(
        (result) => {
          this.course.courseCode = result.courseCode;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.router.navigate(['/courseFields/' + this.course.courseCode]);
          this.reenableButton.emit();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.reenableButton.emit();
          this.loading = false;
        }
      );
  }

  addNewCourseVideo() {
    this.dialogService.open(NewCourseVideoComponent,
      {
        context: {
          course: this.course
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (course) => {
          this.course = course;
          this.getCourse(this.course.courseCode);
        });
  }

  addNewCourseImage() {
    this.dialogService.open(NewCourseImageComponent,
      {
        context: {
          course: this.course
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (course) => {
          this.course = course;
          this.getCourse(this.course.courseCode);
        });
  }

  addNewCourseAttachment() {
    this.dialogService.open(NewCourseAttachmentComponent,
      {
        context: {
          course: this.course
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (course) => {
          this.course = course;
          this.getCourse(this.course.courseCode);
        });
  }

  addNewCourseExam() {
    this.dialogService.open(NewCourseExamComponent,
      {
        context: {
          course: this.course
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (course) => {
          this.course = course;
          this.getCourse(this.course.courseCode);
        });
  }

  deleteCourseVideo(event) {
    const index = event.index;
    this.course.courseVideo.splice(index, 1);
    this.course.courseVideosCounter = this.course.courseVideo.length;
    this.updateCourse();
    this.getCourse(this.course.courseCode);
  }

  deleteCourseAttachment(event) {
    const index = event.index;
    this.course.courseAttachment.splice(index, 1);
    this.course.courseAttachmentsCounter = this.course.courseAttachment.length;
    this.updateCourse();
    this.getCourse(this.course.courseCode);
  }

  deleteCourseExam(event) {
    const index = event.index;
    this.course.courseExam.splice(index, 1);
    this.course.courseExamCounter = this.course.courseExam.length;
    this.updateCourse();
    this.getCourse(this.course.courseCode);
  }

  editCourseExam(eventJson) {
    const event = eventJson.event;
    const edit = eventJson.edit;
    this.dialogService.open(NewCourseExamComponent,
      {
        context: {
          course: this.course,
          exam: this.course.courseExam[event.index],
          edit,
          index: event.index
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (course) => {
          this.course = course;
          this.getCourse(this.course.courseCode);
        });
  }

  updateCourse() {
    this.loading = true;
    this.courseService.updateCourseBackend(this.course)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.reenableButton.emit();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.reenableButton.emit();
          this.loading = false;
        }
      );
  }

  cancel() {
    this.router.navigateByUrl('/newCourse');
  }

}
