<app-loading *ngIf="loading"></app-loading>
  <nb-card style="height: 100% !important;">
    <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
      <h4 style="color: white !important;">Agregar archivo</h4>
    </nb-card-header>
    <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
      <form class="form-inline" style="text-align: center">
        <div id="upload_button">
          <label>
            <input type="file" nbInput readonly fieldSize="giant" webkitDirectory multiple (change)="attachmentEvent($event)">
            <span class="btn btn-primary" nbButton outline
              style="color: #8d8d8d; background-color: white; padding: 0.6875rem 1.125rem; border-radius: 0.25rem;">Buscar
              archivo</span>
          </label>
        </div>
        <span style="text-align: center; width: 100%; display: block; margin-top: 1rem;"></span>
      </form>
      <button type="button" nbButton ghost
        style="margin-right: 17rem; color: #0A7E12 !important; vertical-align: baseline; margin-top: 1rem; margin-bottom: 1rem;"
        (click)="upload()" [disabled]="!selectedFiles || !noSpaceFree">Subir</button>
      <app-progress-bar [progress]="progress"></app-progress-bar>
    </nb-card-body>
  </nb-card>
