import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { UserCourse } from 'src/app/models/userCourse';

@Component({
    selector: 'app-user-chart',
    templateUrl: './user-chart.component.html',
    styleUrls: ['./user-chart.component.scss']
})
export class UserChartComponent implements OnInit {
    loading: boolean;
    options = {
        dataset: {
            source: [
                ['Porcentaje', 'Progreso', 'Curso'],
            ]
        },
        grid: { containLabel: true },
        xAxis: { name: 'Progreso' },
        yAxis: { type: 'category' },
        visualMap: {
            orient: 'horizontal',
            left: 'center',
            min: 10,
            max: 100,
            text: ['Final', 'Inicio'],
            // Map the score column to color
            dimension: 0,
            inRange: {
                color: ['#ff0000', '#ffff00', '#00ff00']
            }
        },
        series: [
            {
                type: 'bar',
                encode: {
                    // Map the "amount" column to X axis.
                    x: 'Progreso',
                    // Map the "product" column to Y axis
                    y: 'Curso'
                }
            }
        ]
    };

    constructor(
        private userService: UserService,
        private toastrService: NbToastrService
    ) { }

    ngOnInit() {
        let userCourse: UserCourse;
        if (localStorage.getItem('userCourse')) {
            const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
            userCourse = {
                userCourseCode: uc.userCourseCode,
                userCourses: uc.userCourses,
            };
            if (userCourse.userCourses !== undefined) {
                userCourse.userCourses.forEach((course) => {
                    if (course.courseExamResults !== undefined) {
                        let avg = 0;
                        let count = 0;
                        course.courseExamResults.forEach((exam) => {
                            avg = avg + Math.round(((exam.examScore / exam.examScoreOver) * 100))
                            count++;
                        });
                        const porcent = Math.round((avg / count)).toString();
                        this.options.dataset.source.push([
                            porcent,
                            porcent,
                            course.courseName
                        ]);
                    }
                });
            }
        }
    }



}
