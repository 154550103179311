<nb-layout>

  <nb-layout-header responsive *ngIf="isLogged">
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar state="compacted" containerFixed responsive *ngIf="isLogged">
    <app-sidebar></app-sidebar>
  </nb-sidebar>

  <nb-layout-column responsive style="padding: 0.8rem !important;">
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer responsive *ngIf="isLogged">
    <app-footer></app-footer>
  </nb-layout-footer>
</nb-layout>
