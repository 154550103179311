import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';


@Injectable()
export class CertificationService {
  AWS = require('aws-sdk');

  constructor() { }

  sendEmail(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});
    let params = {
      FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:mtrainingbackend-qa-userSendCertification',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
    return new Promise(async (resolve, reject) => {
      await lambda.invoke(params,  (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }
}
