import { Component, OnInit, Input } from '@angular/core';
import userNotesCoursesTable from '../../../../../../assets/config/userNotesTable/userNotesCoursesTable.json';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Course } from 'src/app/models/course';
import { UserCourse, UserCourseCourses } from 'src/app/models/userCourse';


@Component({
  selector: 'app-view-notes-table',
  templateUrl: './view-notes-table.component.html',
  styleUrls: ['./view-notes-table.component.scss']
})
export class ViewNotesTableComponent implements OnInit {
  settings = userNotesCoursesTable;
  @Input() listCourses;
  userCourses;
  loading: boolean;
  course = {} as Course;
  userCourseCourses = {} as UserCourseCourses;

  constructor(
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.listUserCourses(this.listCourses);
  }

  listUserCourses(userCode) {
    this.loading = true;
    const dataSourseBackend = new Array<any>();
    this.userService.oneUserCourseBackend(userCode)
      .then(
        (result) => {
          const allData = result.body;
          console.log(allData);
          if (allData.userCourses !== undefined) {
            allData.userCourses.forEach(courses => {
              dataSourseBackend.push(courses);
            });
          } else {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              'Este usuario no ha iniciado ningún curso ó no tiene cursos asignados.',
              { position, status });
            this.loading = false;
          }
          this.userCourses = dataSourseBackend;
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return dataSourseBackend;
  }

  userCourseNotesNavigate($event) {
    const courseCode = $event.data.courseCode;
    this.router.navigate(['/courseNotes/' + this.listCourses + '/' + courseCode]);
  }

  deactivateCourse($event) {
    const data = $event.data;
    this.userService.updateUserCourseBackend(data);
    console.log(data);
  }

  activateCourse($event) {
    console.log($event.data);
  }
}
