import { NewCourseNotificationSES } from './../../../services/newCourseNotificationSES';
import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { UserCourse, UserCourseCourses } from 'src/app/models/userCourse';
import { Course } from 'src/app/models/course';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
@Component({
  selector: 'app-course-assign',
  templateUrl: './course-assign.component.html',
  styleUrls: ['./course-assign.component.scss']
})
export class CourseAssignComponent implements OnInit {
  @Input() course = {} as Course;
  loading: boolean;
  userList = new Array();
  departmentList = new Array();
  disableUsers = false;
  userName;
  userEmail;
  filterUser = '';
  filterDepartment = '';
  company = {} as any;
  companyCodeForSub: any;

  checkedUser = false;
  checkedDepartment = false

  toggleUser(checked: boolean) {
    this.checkedUser = checked;
  }

  toggleDepartment(checked: boolean) {
    this.checkedDepartment = checked;
  }

  constructor(
    private dialogRef: NbDialogRef<CourseAssignComponent>,
    private authService: AuthService,
    private userService: UserService,
    private toastrService: NbToastrService,
    private newCourseNotification: NewCourseNotificationSES,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.authService.getUsers().then(
      (result: any) => {
        const listUsers = result.Users;
        this.userList = new Array();
        this.departmentList = new Array();
        listUsers.forEach(user => {
          const userId = user.Attributes.find((x) => x.Name === 'sub');
          const username = user.Attributes.find((x) => x.Name === 'email');
          const email = user.Attributes.find((x) => x.Name === 'email');
          const name = user.Attributes.find((x) => x.Name === 'name');
          const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
          const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
            user.Attributes.find((x) => x.Name === 'custom:department') : '';
          const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

          if (userRole.Value !== 'administrator') {
            if (companyCode.Value === userLogged.company.companyCode) {
              let userCourse: UserCourse;
              this.userService.oneUserCourseBackend(userId.Value).then(
                (resultUserCourse) => {
                  userCourse = resultUserCourse.body;
                  if (userCourse.userCourses !== undefined) {
                    let exist = true;
                    userCourse.userCourses.forEach(courses => {
                      if (this.course.courseCode === courses.courseCode) {
                        exist = false;
                      }
                    });
                    if (exist) {
                      this.userList.push({
                        userId: userId.Value,
                        userName: username.Value,
                        name: name.Value,
                        email: email.Value,
                        department: department.Value,
                        assign: false,
                        userCourse
                      });
                      const departmentExist = this.departmentList.find((x) => x.department === department.Value);
                      if (departmentExist === undefined) {
                        this.departmentList.push({
                          department: department.Value,
                          assign: false
                        });
                      }
                    }
                  } else {
                    this.userList.push({
                      userId: userId.Value,
                      userName: username.Value,
                      name: name.Value,
                      email: email.Value,
                      department: department.Value,
                      assign: false,
                      userCourse
                    });
                    const departmentExist = this.departmentList.find((x) => x.department === department.Value);
                    if (departmentExist === undefined) {
                      this.departmentList.push({
                        department: department.Value,
                        assign: false
                      });
                    }
                  }
                }
              ).catch(
                (err) => {
                  // console.error(err);
                }
              );
            }
          }
          this.companyCodeForSub = companyCode.Value;
        });
        this.loading = false;
      }
    ).catch(
      (err) => {
        this.loading = false;
      }
    );
  }

  changeDepartment(department, assing) {
    this.userList.forEach((user) => {
      if (user.department === department) {
        user.assign = assing;
      }
    });
    this.departmentList.some((departments) => {
      if (departments.assign === true) {
        this.disableUsers = true;
        return true;
      } else {
        this.disableUsers = false;
      }
    });
  }

  assignUser() {
    this.userList.forEach(user => {
      if (user.assign) {
        const userCourseAssign: UserCourse = user.userCourse;
        if (userCourseAssign.userCourses === undefined) {
          userCourseAssign.userCourses = new Array<UserCourseCourses>();
        }
        const userCourseCoursesAssign: UserCourseCourses = {
          courseCode: this.course.courseCode,
          courseDescription: this.course.courseDescription,
          courseImage: this.course.courseImage,
          courseName: this.course.courseName,
          courseStatus: undefined,
          courseVideo: undefined,
          courseScormSelected: undefined,
          courseExamResults: undefined,
          courseCertified: false,
          examCertified: false,
          courseFlippedStatus: false,
          userCourseEnable: true
        };
        userCourseAssign.userCourses.push(userCourseCoursesAssign);
        const userAssign = {
          userCourseCode: userCourseAssign.userCourseCode,
          userCourses: userCourseAssign.userCourses
        };
        const companyLogo = window.location.hostname;
        const subdomain = companyLogo.split('.')[0];
        this.updateUserCourse(userAssign, user.name);
        this.userName = user.name;
        this.userEmail = user.email;
        this.newCourseNotification.sendEmail(this.userName, this.userEmail, subdomain);
      }
    });
    this.dialogRef.close();
  }

  updateUserCourse(userCourse, userName) {
    this.loading = true;
    this.userService.updateUserCourseBackend(userCourse)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Curso Asignado a ' + userName, { position, status });
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err,
            { position, status });
          this.loading = false;
        }
      );
  }

  cancelAssignUser() {
    this.dialogRef.close();
  }

}
