import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Company } from 'src/app/models/company';
import companyDepartmentsTable from '../../../../../assets/config/companyTable/companyDepartmentsTable.json';

@Component({
  selector: 'app-company-departments-table',
  templateUrl: './company-departments-table.component.html',
  styleUrls: ['./company-departments-table.component.scss']
})
export class CompanyDepartmentsTableComponent implements OnInit, OnChanges {
  @Input() company = {} as Company;
  @Output() departmentsDelete = new EventEmitter<any[]>();
  settings = companyDepartmentsTable;
  companyDepartmentsList = new Array();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.companyDepartmentsList = new Array();
    if (this.company.companyDepartments !== undefined) {
      this.company.companyDepartments.forEach(element => {
        this.companyDepartmentsList.push({
          department: element
        });
      });
    }
  }

  deleteDepartments(event) {
    this.departmentsDelete.emit(event);
  }

}
