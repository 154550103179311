<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <nb-card style="height: 20rem !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
            <h4 style="color: white !important;">Agregar video</h4>
        </nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
            <form class="form-inline" style="text-align: center">
                <div id="upload_button">
                    <label>
                        <input type="file" nbInput readonly fieldSize="giant" (change)="videoEvent($event)"
                            accept=".mp4">
                        <span class="btn btn-primary" nbButton outline
                            style="color: #8d8d8d; background-color: white; padding: 0.6875rem 1.125rem; border-radius: 0.25rem;">Buscar
                            video</span>
                    </label>
                </div>
                <span style="text-align: center; width: 100%; display: block; margin-top: 1rem;">{{fileNameShow}}</span>
            </form>
            <div style="display: flex; justify-content: space-evenly;">
                <div class="col-md-6" style="text-align: center !important;" *ngIf="selectedFiles || noSpaceFree">
                    <button type="button" nbButton ghost
                        style="color: #0A7E12 !important; vertical-align: baseline; text-align: center !important;"
                        (click)="upload()">Subir</button>
                    </div>
                    <div class="col-md-6" style="text-align: center !important;">
                    <button type="button" nbButton ghost
                        style="color: #C81016 !important; vertical-align: baseline"
                        (click)="close()">Cancelar</button>
                    </div>
            </div>
            <app-progress-bar [progress]="progress"></app-progress-bar>
        </nb-card-body>
    </nb-card>
</div>