<app-loading *ngIf="loading"></app-loading>
<nb-card style="height: 95% !important;">
  <nb-card-body>
    <nb-tabset fullWidth>
      <nb-tab tabTitle="Curso" tabIcon="file-add-outline" responsive>
        <nb-alert status="warning" *ngIf="showAlert">Debes ingresar un titulo y descripción para poder crear el curso.
        </nb-alert>
        <h4>Información de curso</h4>
        <div class="input-group">
          <input type="text" placeholder="Ingrese el nombre del curso" class="form-control" name="courseName"
            autocomplete="off" [(ngModel)]="course.courseName" required maxlength="40" nbInput fullWidth>
        </div>
        <div class="input-group" style="padding-top: 10px">
          <textarea type="text" nbInput required fullWidth placeholder="Ingrese un descripción del curso"
            class="form-control" name="courseDescription" [(ngModel)]="course.courseDescription" style="height: 10rem;"
            maxlength="340"></textarea>
        </div>
        <div style="display: flex;">
          <span style="margin-right: 0.5rem; padding-top: 0.55rem;"
            *ngIf="(course.courseCode && course.courseScormSelected) || (!course.courseCode && companyScormSelected)">Scorm</span>
          <nb-toggle [(checked)]="courseScormSelected" id="scormSelected" name="scormSelected"
            [(ngModel)]="course.courseScormSelected" [disabled]="course.courseCode"
            *ngIf="(course.courseCode && course.courseScormSelected) || (!course.courseCode && companyScormSelected)">
          </nb-toggle>
          <div>
            <button nbButton (click)="courseOnSave()"  nbTooltip="Guardar" ghost
              style="color: #0A7E12 !important;">
              Guardar
            </button>
            <button nbButton nbTooltip="Cancelar" (click)="cancel()" ghost style="color: #C81016 !important;">
              Cancelar
            </button>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Portada" tabIcon="image-outline" responsive *ngIf="course.courseCode">
        <button nbButton ghost (click)="addNewCourseImage()">
          <nb-icon icon="plus-outline" size="giant"></nb-icon>
        </button>
        <button nbButton ghost>
          <nb-icon icon="refresh-outline" size="giant"></nb-icon>
        </button>
        <div *ngIf="course.courseImage">
          <h2>Imagen de portada actual: </h2>
          <div style="padding-left: 8rem !important;"><img src={{course.courseImage}} style="width: 10rem;"></div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Videos" tabIcon="film-outline" responsive
        *ngIf="course.courseCode && !course.courseScormSelected">
        <button nbButton ghost nbTooltip="Agregar Video" (click)="addNewCourseVideo()">
          <nb-icon icon="plus-outline"></nb-icon>
        </button>
        <button nbButton ghost nbTooltip="Actualizar Video" (click)="getCourse(this.course.courseCode)">
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
        <app-new-course-video-table [course]="course" (courseDelete)="deleteCourseVideo($event)">
        </app-new-course-video-table>
      </nb-tab>
      <nb-tab tabTitle="Archivos" tabIcon="folder-outline" responsive
        *ngIf="course.courseCode && !course.courseScormSelected">
        <button nbButton ghost nbTooltip="Agregar Archivos" (click)="addNewCourseAttachment()">
          <nb-icon icon="plus-outline"></nb-icon>
        </button>
        <button nbButton ghost nbTooltip="Actualizar Archivos" (click)="getCourse(this.course.courseCode)">
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
        <app-new-course-attachment-table [course]="course" (attachmentDelete)="deleteCourseAttachment($event)">
        </app-new-course-attachment-table>
      </nb-tab>
      <nb-tab tabTitle="Examenes" tabIcon="book-open-outline" responsive
        *ngIf="course.courseCode && !course.courseScormSelected">
        <button nbButton ghost nbTooltip="Agregar Examen" (click)="addNewCourseExam()">
          <nb-icon icon="plus-outline"></nb-icon>
        </button>
        <button nbButton ghost nbTooltip="Actualizar Examenes" (click)="getCourse(this.course.courseCode)">
          <nb-icon icon="refresh-outline"></nb-icon>
        </button>
        <app-new-course-exam-table [course]="course" (examEdit)="editCourseExam($event)"
          (examDelete)="deleteCourseExam($event)">
        </app-new-course-exam-table>
      </nb-tab>
      <nb-tab tabTitle="Ordenar" tabIcon="layers-outline" responsive
        *ngIf="course.courseCode && !course.courseScormSelected">
        <app-new-course-order [course]="course"></app-new-course-order>
      </nb-tab>
      <nb-tab tabTitle="Subir scorm" tabIcon="folder-outline" responsive
        *ngIf="course.courseCode && course.courseScormSelected">
        <app-new-course-scrom-file [course]="course"></app-new-course-scrom-file>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
