<app-loading *ngIf="loading"></app-loading>
<nb-card class="inline-form-card" style="height: 95% !important;">
  <nb-card-header>
    <h4>Agregar usuario</h4>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-4">
        <div class=" form-group">
          <input type="text" nbInput fullWidth placeholder="Nombre" [(ngModel)]="user.name" name="username" ngModel
            required autocomplete="off">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <input type="text" nbInput fullWidth placeholder="Correo Electronico" [(ngModel)]="user.email" name="email"
            required autocomplete="off">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <nb-select placeholder="Seleccione Rol" fullWidth id="userRole" name="userRole" [(ngModel)]="userRole.value"
            required maxlength="0" nbTooltip="Editor: encargado de crear contenido, como los cursos sus exámenes.  Administrador de usuarios: es el encargado de crear usuarios y administrar los mismos, dándole de bajé o alta a cada uno, asignación de cursos. Usuario: es el alumno el cual solo puede ver los cursos y tomarlos.">
            <nb-option [value]="userRole.value" *ngFor="let userRole of userRoleList">{{userRole.type}}
            </nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <nb-select placeholder="Seleccione Departmento" fullWidth id="department" name="department"
            [(ngModel)]="department" required maxlength="0">
            <nb-option [value]="department" *ngFor="let department of departmentList">{{department}}
            </nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <button nbButton (click)="onSignUpSubmit()" nbTooltip="Guardar" ghost style="color: #0A7E12 !important;" *ngIf="user.name && user.email && department && userRole">
      Guardar
    </button>
    <button nbButton nbTooltip="Cancelar" (click)="cancel()" ghost style="color: #C81016 !important;">
      Cancelar
    </button>
  </nb-card-body>
</nb-card>