import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';
import { CourseService } from 'src/app/services/course.service';
import courseTableSettings from '../../../../assets/config/courseTable/courseTable.json';
import { Course } from 'src/app/models/course';
import { LocalDataSource } from 'ng2-smart-table';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-course-table',
  templateUrl: './new-course-table.component.html',
  styleUrls: ['./new-course-table.component.scss']
})
export class NewCourseTableComponent implements OnInit, OnChanges {

  @Input() courseList: any[];
  @Output() courseListResult = new EventEmitter<any[]>();
  courseListLocal = new LocalDataSource();

  settings = courseTableSettings;
  loading: boolean;
  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private courseService: CourseService,
    private dialogService: NbDialogService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    // this.listAllcourse();
  }

  ngOnChanges() {
    this.courseListLocal = new LocalDataSource(this.courseList);
  }

  onCourseFieldsNavigation(event): void {
    const courseCode = event.data.courseCode;
    this.router.navigate(['/courseFields/' + courseCode]);
  }

  listAllcourse() {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const userCompany = userLogged.company.companyCode.toString();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          const userList = result.body;
          userList.forEach(element => {
            if (element.userCourses !== undefined){
              element.userCourses.forEach(courses =>{
                // console.log(courses);
              });
            }
          });
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

  deleteCourse(event) {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const userCompany = userLogged.company.companyCode.toString();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          const userList = result.body;
          let canDelete = true;
          userList.forEach(element => {
            if (element.userCourses !== undefined) {
              element.userCourses.forEach(courses =>{
                if (courses.courseCode === event.data.courseCode) {
                  canDelete = false;
                }
              });
            }
          });
          if (canDelete === true ){
          this.dialogService.open(ConfirmWindowComponent,
            {
              context: {
                message: '¿Esta seguro de dar de baja este curso?'
              },
              hasScroll: false,
              hasBackdrop: true,
              closeOnEsc: false,
              closeOnBackdropClick: false
            }).onClose
            .subscribe(
              (accept) => {
                if (accept) {
                  const course = event.data;
                  course.deleteStatus = 'deleted';
                  this.courseListLocal.remove(event.data);
                  this.updateCourse(course);
                }
              }
            );
            this.loading = false;
          }else{
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              'Este curso ya fue asignado no puede ser dado de baja.',
              '',
              { preventDuplicates: true, position, status });
            this.loading = false;
          }
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

  updateCourse(course) {
    this.loading = true;
    this.courseService.updateCourseBackend(course)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

}
