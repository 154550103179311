<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <nb-card style="height: 30rem !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
            <h4 style="color: white !important;">Asignar usuario</h4>
        </nb-card-header>
        <nb-card-body style="text-align: left !important; ">
            <nb-tabset fullWidth>
                <nb-tab tabTitle="Usuario" tabIcon="file-add-outline" responsive [disabled]="disableUsers">
                    <input type="text" nbInput fullWidth class="form-control" name="filterUser" placeholder="Buscar..."
                        [(ngModel)]="filterUser">
                    <nb-list nbInfiniteList listenWindowScroll [threshold]="10">
                        <nb-list-item *ngFor="let user of userList | filterUserAssign:filterUser; let $index = index">
                            <div style=" width: 100%;">
                                <div style="width: 50%; float: left;">
                                    <nb-checkbox (checkedChange)="toggleUser($event)" [(ngModel)]="user.assign"></nb-checkbox>
                                    <span style="margin-left: 1rem;">{{user.name}}</span>
                                </div>
                                <div style="width: 50%; float: right;">
                                    <span>{{user.email}}</span>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </nb-tab>
                <nb-tab tabTitle="Departamento" tabIcon="file-add-outline" responsive>
                    <input type="text" nbInput fullWidth class="form-control" name="filterDepartment"
                        placeholder="Buscar..." [(ngModel)]="filterDepartment">
                    <nb-list nbInfiniteList listenWindowScroll [threshold]="10">
                        <nb-list-item
                            *ngFor="let department of departmentList | filterDepartmentAssignPipe:filterDepartment; let $index = index">
                            <div style="width: 100%;" *ngIf="department.department">
                                <div style="width: 50%; float: left;">
                                    <nb-checkbox (checkedChange)="toggleDepartment($event)" [(ngModel)]="department.assign"
                                        (change)="changeDepartment(department.department,department.assign)">
                                    </nb-checkbox>
                                    <span style="margin-left: 1rem;">{{department.department}}</span>
                                </div>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </nb-tab>
            </nb-tabset>
            <div style="margin-top: 2rem;" class="row">
                <div class="col-md-6" style="text-align: center !important;">
                    <button nbButton ghost style="color: #0A7E12 !important;"
                    (click)="assignUser()" *ngIf="checkedUser || checkedDepartment">Asignar</button>
                </div>
                <div class="col-md-6" style="text-align: center !important;">
                    <button nbButton ghost style="color: #C81016 !important;"
                    (click)="cancelAssignUser()">Cancelar</button>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>