<app-loading *ngIf="loading"></app-loading>
<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
  </div>
  <div class="image-contaner">
    <img class="logo-img" [src]="urlLog" [nbTooltip]="urlAlt">
  </div>
  <div class="header-container">
    <a class="logo">
      <span style="color:#ff8c21">m</span><span>Training</span>
    </a>
  </div>
</div>
<div class="header-container">
  <nb-user [name]="userAvatar" class="user-action" nbTooltip="{{userAvatar}}" (click)="navigateUserProfile()"
    nbTooltipStatus="info"
    [picture]="userPicture === userEmail ? 'assets/images/avatar/avatarStudent.png' : userPicture" [showTitle]="true"
    onlyPicture></nb-user>
  <nb-icon icon="log-out-outline" style="cursor: pointer;" (click)="logOut()"></nb-icon>
</div>