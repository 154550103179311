<app-loading *ngIf="loading"></app-loading>
<h4>
  <span>Crear usuario</span>
  <button nbButton ghost nbTooltip="Nuevo usuario" (click)="addNewUser()">
    <nb-icon icon="plus-outline" ></nb-icon>
  </button>
  <button nbButton ghost nbTooltip="Inactivar/Activar multiples usuarios" (click)="deleteMultipleUsers()">
    <nb-icon icon="people-outline" ></nb-icon>
  </button>
  <button nbButton ghost nbTooltip="Listar usuarios" (click)="listAllUser()">
    <nb-icon icon="refresh-outline" ></nb-icon>
  </button>
</h4>
