import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit, OnChanges {
  @Input() url: any;

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() { }

}
