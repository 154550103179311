<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <nb-card style="height: 20rem !important; border-radius: 30px !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important; border-top-right-radius: 30px;border-top-left-radius: 30px;"><h4 style="color: white !important;">Confirmación</h4></nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <h3>{{message}}</h3>
                    </div>
                </div>
            </div>
            <button nbButton ghost  style="margin-right: 17rem; color: #0A7E12 !important;" (click)="closeWindow(true)"
                style="margin-right: 5px; margin-left: 3px;">Aceptar</button>
            <button nbButton ghost  style="margin-left: 5px; color: #C81016 !important;" (click)="closeWindow(false)">Cancelar</button>
        </nb-card-body>
    </nb-card>
</div>