import { UserAutoConfirmComponent } from './pages/Auth/user-auto-confirm/user-auto-confirm.component';
import { ScormPlayerComponent } from './pages/course/scorm-player/scorm-player.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/Auth/login/login.component';
import { UserResetPasswordComponent } from './pages/Auth/user-reset-password/user-reset-password.component'
import { UserFotgotPasswordViewComponent } from './pages/Auth/user-fotgot-password-view/user-fotgot-password-view.component'
import { AuthGuard } from './services/auth-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { CourseComponent } from './pages/course/course.component';
import { NewCourseComponent } from './pages/new-course/new-course.component';
import { NewCourseFieldsComponent } from './pages/new-course/new-course-fields/new-course-fields.component';
import { NewUserComponent } from './pages/new-user/new-user.component';
import { NewUserFieldsComponent } from './pages/new-user/new-user-fields/new-user-fields.component';
import { CourseVideoViewerComponent } from './pages/course/course-video-viewer/course-video-viewer.component';
import { MyCourseComponent } from './pages/course/my-course/my-course.component';
import { ReportUserComponent } from './pages/report/report-user/report-user.component';
import { ReportUserResultComponent } from './pages/report/report-user-result/report-user-result.component';
import { ReportCourseAvgComponent } from './pages/report/report-course-avg/report-course-avg.component';
import { UserMyProfileComponent } from './pages/Auth/user-my-profile/user-my-profile.component';
import { CompanyComponent } from './pages/company/company.component';
import { WelcomeComponent } from './pages/landingPages/welcome/welcome.component';
import { UserChartComponent } from './pages/charts/user-chart/user-chart.component';
import { UserNotesComponent } from './pages/report/user-notes/user-notes.component';
import { ViewNotesComponent } from './pages/report/user-notes/view-notes/view-notes.component';
import { ViewCourseNotesTableComponent } from './pages/report/user-notes/view-notes/view-course-notes-table/view-course-notes-table.component';

const routes: Routes = [
  { path: '', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'welcome', canActivate: [AuthGuard], component: WelcomeComponent},
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'userReport', canActivate: [AuthGuard], component: UserChartComponent},
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },
  { path: 'course', canActivate: [AuthGuard], component: CourseComponent },
  { path: 'userProfile', canActivate: [AuthGuard], component: UserMyProfileComponent },
  { path: 'myCourse', canActivate: [AuthGuard], component: MyCourseComponent },
  { path: 'myCourse/:userCourseCode', canActivate: [AuthGuard], component: MyCourseComponent },
  { path: 'courseVideoViewer/:courseCode', canActivate: [AuthGuard], component: CourseVideoViewerComponent },
  { path: 'courseScormPlayer/:courseCode', canActivate: [AuthGuard], component: ScormPlayerComponent },
  { path: 'newCourse', canActivate: [AuthGuard], component: NewCourseComponent },
  { path: 'courseFields', canActivate: [AuthGuard], component: NewCourseFieldsComponent },
  { path: 'courseFields/:courseCode', canActivate: [AuthGuard], component: NewCourseFieldsComponent },
  { path: 'newUser', canActivate: [AuthGuard], component: NewUserComponent },
  { path: 'userFields', canActivate: [AuthGuard], component: NewUserFieldsComponent },
  { path: 'ReportUser', canActivate: [AuthGuard], component: ReportUserComponent },
  { path: 'ReportUserResult', canActivate: [AuthGuard], component: ReportUserResultComponent },
  { path: 'ReportCourseAvg', canActivate: [AuthGuard], component: ReportCourseAvgComponent },
  { path: 'company', canActivate: [AuthGuard], component: CompanyComponent },
  { path: 'userAutoConfirm/:userEmail/:verificationCode', component: UserAutoConfirmComponent},
  { path: 'userNotes', canActivate: [AuthGuard], component: UserNotesComponent},
  { path: 'notes/:userCode', canActivate: [AuthGuard], component: ViewNotesComponent},
  { path: 'courseNotes/:userCode/:courseCode', canActivate: [AuthGuard], component: ViewCourseNotesTableComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
