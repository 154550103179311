import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import userRole from '../../../../../assets/data/userRole/userRole.json';
import { UserRole, User } from '../../../../models/user';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { CompanyService } from 'src/app/services/company.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmWindowComponent } from 'src/app/core/tools/confirm-window/confirm-window.component';





@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  @Input() userRoleForEdit = {} as any;
  userRoleList = userRole;
  departmentList = new Array();
  loading: boolean;
  user = {} as User;
  userNewRol;
  userNewDepartment;
  company;
  companyCode;

  constructor(
    private dialogRef: NbDialogRef<UserEditComponent>,
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.authService.getUser(this.userRoleForEdit).then((result: any) => {
      const userBackend = result.UserAttributes;
      const sub = userBackend.find((x) => x.Name === 'sub');
      const username = userBackend.find((x) => x.Name === 'email');
      const email = userBackend.find((x) => x.Name === 'email');
      const name = userBackend.find((x) => x.Name === 'name');
      const picture = userBackend.find((x) => x.Name === 'picture');
      const userRole = userBackend.find((x) => x.Name === 'custom:role');
      const theme = userBackend.find((x) => x.Name === 'custom:theme');
      const department = userBackend.find((x) => x.Name === 'custom:department');
      const email_verified = userBackend.find((x) => x.Name === 'email_verified');
      const companyCode = userBackend.find((x) => x.Name === 'custom:company');
      let rol = '';
      switch (userRole.Value) {
        case 'editor':
          rol = 'Editor';
          break;
        case 'user_administrator':
          rol = 'Administrator de Usuarios';
          break;
        case 'user':
          rol = 'Usuario';
          break;
        case 'administrator':
          rol = 'Administrador';
          break;
        default:
          break;
      }
      this.companyService.oneCompanyBackend(companyCode.Value).then(
        (resultCompany: any) => {
          const company = resultCompany.body;
          this.company = company;
          this.departmentList = resultCompany.body.companyDepartments;
          this.user = {
            userId: sub.Value,
            userRole: userRole.Value,
            userRoleName: rol,
            username: email.Value,
            email: email.Value,
            name: name.Value,
            department: resultCompany.body.companyDepartments[0],
            theme: theme.Value,
            picture: picture.Value,
            userStatus: email_verified.Value,
            password: undefined,
            company: {
              companyCode: companyCode.Value,
              companyName: company.companyName
            }
          } as User;
          this.loading = false;
        }
      ).catch(
        (err: any) => {
          this.dialogRef.close();
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    }).catch((err) => {
      this.dialogRef.close();
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
      this.loading = false;
    });
  }

  saveUser() {
    this.loading = true;
    const userRoleOld = this.user.userRole.toString();
    const company = this.company;
    this.user.department = this.userNewDepartment ? this.userNewDepartment : this.user.department;
    let userRole = this.user.userRole.toString();
    if (this.userNewRol !== undefined) {
    userRole = this.userNewRol.value;
    this.user.userRole = this.userNewRol.value ? this.userNewRol.value : this.user.userRole;
    this.user.userRoleName = this.userNewRol.type ? this.userNewRol.type : this.user.userRoleName;
    }
    this.dialogService.open(ConfirmWindowComponent,
      {
        context: {
          message: '¿Esta seguro de actualizar los datos del usuario?'
        },
        hasScroll: false,
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (accept) => {
          if (accept) {
            if (this.updateUser(userRole)) {
              this.authService.updateUser(this.user).then(
                (result) => {
                  switch (userRole) {
                    case 'user':
                      company.companyCountUser++;
                      break;
                    case 'user_administrator':
                      company.companyCountUserAdmin++;
                      break;
                    case 'editor':
                      company.companyCountUserEditor++;
                      break;

                    default:
                      break;
                  }
                  switch (userRoleOld) {
                    case 'user':
                      company.companyCountUser--;
                      break;
                    case 'user_administrator':
                      company.companyCountUserAdmin--;
                      break;
                    case 'editor':
                      company.companyCountUserEditor--;
                      break;

                    default:
                      break;
                  }
                  this.companyService.updateCompanyBackend(company).then(
                    (resultCompanyUpdate) => {
                      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                      const status: NbComponentStatus = 'success';
                      this.toastrService.show('', 'Guardado', { position, status });
                      this.loading = false;
                      this.dialogRef.close(this.user);
                    }
                  ).catch(
                    (err) => {
                      this.dialogRef.close()
                      const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                      const status = 'danger';
                      this.toastrService.show(
                        '',
                        err.message,
                        { position, status });
                      this.loading = false;
                    });
                }
              ).catch(
                (err) => {
                  this.dialogRef.close();
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                  }
                );
              }
          }
          this.loading = false;
        }
      );
  }

  updateUser(userRole): boolean {
    let response = true;
    const companyValidator = this.company;
    switch (userRole) {
      case 'user':
        if (companyValidator.companyCountUser >= companyValidator.companyMaxUser) {
          const position = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status = 'danger';
          this.toastrService.show(
            '',
            'Cantidad máxima de tipo USUARIO excedida',
            { position, status });
          this.loading = false;
          return false;
        }
        break;
      case 'user_administrator':
        if (companyValidator.companyCountUserAdmin >= companyValidator.companyMaxUserAdmin) {
          const position = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status = 'danger';
          this.toastrService.show(
            '',
            'Cantidad máxima de tipo ADMINISTRADOR DE USUARIO excedida',
            { position, status });
          this.loading = false;
          return false;
        }
        break;
      case 'editor':
        if (companyValidator.companyCountUserEditor >= companyValidator.companyMaxUserEditor) {
          const position = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status = 'danger';
          this.toastrService.show(
            '',
            'Cantidad máxima de tipo EDITOR excedida',
            { position, status });
          this.loading = false;
          return false;
        }
        break;

      default:
        break;
    }
    return response;
  }

  resendUserCode() {
    this.loading = true;
    const email = this.user.email;
    if (this.user.userStatus !== 'true') {
      this.authService.resendCode(email).then(
        (resultCompanyUpdate) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'El nuevo correo de validación a sido enviado a ' + email, { position, status });
          this.loading = false;
          this.dialogRef.close(this.user);
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'warning';
          this.toastrService.show('', 'Ha excedido la cantidad de reenvios intente mas tarde.', { position, status });
          this.loading = false;
          this.dialogRef.close(this.user);
        });
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'warning';
      this.toastrService.show('', 'El usuario ya esta validado no se le puede enviar el código.', { position, status });
      this.loading = false;
      this.dialogRef.close(this.user);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
