<nb-card size="tiny">
  <nb-card-header>Almacenamiento</nb-card-header>
  <nb-card-body>
    <div class="container" style="margin-top: 0.5rem !important;">
      <div class="info">
        <div class="subtitle">Almacenamiento: {{allSpace | number : '1.0-5'}} {{allSpaceCognotation}}</div>
        <div class="subtitle">Utilizado: {{usedSpace | number : '1.0-5'}} {{usedSpaceCognotation}} ({{percent | number : '1.0-2'}}%)</div>
      </div>
      <nb-progress-bar [value]="percent" [status]="status" nbTooltip="{{percent | number : '1.0-2'}} %"
        nbTooltipStatus='info'>
      </nb-progress-bar>
      <div class="caption description">
        <bdi>Almacenamiento restante: {{freeSpace | number : '1.0-5'}} {{freeSpaceCognotation}}</bdi>
      </div>
      <!-- <table style="margin-top: 1rem;">
        <thead>
          <tr>
            <th colspan="2" style="text-align: center;">
              Resumen
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Almacenamiento Total</th>
            <td>{{allSpace | number : '1.0-5'}} {{allSpaceCognotation}}</td>
          </tr>
          <tr>
            <th>Almacenamiento Utilizado</th>
            <td>{{usedSpace | number : '1.0-5'}} {{usedSpaceCognotation}}</td>
          </tr>
          <tr>
            <th>Almacenamiento Libre</th>
            <td>{{freeSpace | number : '1.0-5'}} {{freeSpaceCognotation}}</td>
          </tr>
        </tbody>
      </table> -->
    </div>
  </nb-card-body>
</nb-card>