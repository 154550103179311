import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Course } from 'src/app/models/course';
import { CourseService } from 'src/app/services/course.service';
import {
  NbToastrService,
  NbDialogRef,
  NbGlobalPosition,
  NbGlobalPhysicalPosition,
  NbComponentStatus
} from '@nebular/theme';
import { Video } from 'src/app/models/video';
import { CompanyService } from 'src/app/services/company.service';
import videoAccept from '../../../../../assets/data/restrictions/videoAccept.json';
import videoMaxSizeAccept from '../../../../../assets/data/restrictions/videoMaxSizeAccept.json';


@Component({
  selector: 'app-new-course-video',
  templateUrl: './new-course-video.component.html',
  styleUrls: ['./new-course-video.component.scss']
})
export class NewCourseVideoComponent implements OnInit {
  selectedFiles: FileList;
  @Input() course = {} as Course;
  progress;
  loading: boolean;
  fileNameShow;
  fileSizeUsed: number;
  noSpaceFree = undefined;

  constructor(
    private courseService: CourseService,
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<NewCourseVideoComponent>
  ) {

  }

  ngOnInit() {
    this.courseService.courseProgress.subscribe(courseProgress => {
      courseProgress = Math.round(courseProgress);
      this.progress = courseProgress;
    });
  }

  videoEvent(event) {
    this.fileNameShow = event.target.files[0].name;
    const fileType = event.target.files[0].type;
    const fileNameSplit = this.fileNameShow.split('.')[1];
    const fileSizeShow = event.target.files[0].size;
    this.fileSizeUsed = 0;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.selectedFiles = event.target.files;
    this.loading = true;
    const videoMaxSizeShow = (videoMaxSizeAccept / 1000000);
    this.noSpaceFree = undefined;
    let findAccept = 'false';
    let message;
    videoAccept.forEach(element => {
      message = 'El archivo que esta intentando subir no es permitido';
      if (this.fileNameShow.length > 50) {
        findAccept = 'false';
        message = 'El nombre del video es mayor a 50 caracteres';
      }
      if (element === fileNameSplit) {
        findAccept = 'true';
      }
    });
    if (fileSizeShow >= videoMaxSizeAccept) {
      findAccept = 'false';
      message = 'El archivo exede el tamaño permitido: ' + videoMaxSizeShow + 'mb';
    }
    if (findAccept === 'false') {
      this.selectedFiles = undefined;
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        message,
        { position, status });
      this.loading = false;
      return false;
    }
    this.courseService.listFileS3().then(
      (result) => {
        const files = result.Contents;
        const sizeByCourse = new Array();
        files.forEach((file) => {
          const key = file.Key;
          const fileKey = file.Key.split('/');
          const companyFolderRoot = fileKey[0];
          const companyFolder = fileKey[1];
          if (companyFolderRoot === 'company') {
            if (companyFolder === userLogged.company.companyCode) {
              sizeByCourse[companyFolder] = (sizeByCourse[companyFolder] ? sizeByCourse[companyFolder] : 0) + file.Size;
              const newTemporalKey = 'company/' +
                userLogged.company.companyCode +
                '/course/' +
                this.course.courseCode + '/video/' +
                event.target.files[0].name;
              if (key === newTemporalKey) {
                findAccept = 'false';
              }
            }
          }
        });
        if (findAccept === 'false') {
          this.selectedFiles = undefined;
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            'Archivo Duplicado',
            { position, status });
          this.loading = false;
          return false;
        }
        this.courseService.allCourseCompanyBackend(userLogged.company.companyCode)
          .then(
            (resultCompany) => {
              const companys = resultCompany.body;
              const valueBucket = (sizeByCourse[userLogged.company.companyCode] ? sizeByCourse[userLogged.company.companyCode] : 0);
              this.fileSizeUsed = valueBucket;
              this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
                (company) => {
                  const maxStorageSpace = company.body.companyMaxFileStorage === undefined ? 0 : company.body.companyMaxFileStorage;
                  const freeSpace = maxStorageSpace - this.fileSizeUsed;
                  if (fileSizeShow < freeSpace) {
                    this.noSpaceFree = freeSpace;
                  } else {
                    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status: NbComponentStatus = 'danger';
                    this.toastrService.show(
                      '',
                      'Espacio insuficiente',
                      { position, status });
                    this.loading = false;
                  }
                }
              ).catch(
                (err) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              );
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  upload() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    this.loading = true;
    const file = this.selectedFiles.item(0);
    this.courseService.uploadFileS3(file, companyCode, this.course, 'video')
      .then(
        (data: any) => {
          if (this.course.courseVideo === undefined) {
            this.course.courseVideo = new Array<Video>();
          }
          const video: Video = {
            videoName: file.name,
            videoUrl: data.Location
          };
          this.course.courseVideo.push(video);
          this.course.courseVideosCounter = this.course.courseVideo.length;
          this.courseService.updateCourseBackend(this.course)
            .then(
              (result) => {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show('', 'Guardado', { position, status });
                this.loading = false;
              }
            ).catch(
              (err) => {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show(
                  '',
                  err.message,
                  { position, status });
                this.loading = false;
              }
            ).finally(
              () => {
                this.loading = false;
                this.dialogRef.close(this.course);
              });
        },
      ).catch(
        (error) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', error, { position, status });
          this.loading = false;
        });

  }

  close() {
    this.dialogRef.close(this.course);
  }

}
