import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { UserCourse } from '../models/userCourse';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userProgress = new Subject<any>();
  userCourseTable = 'mtraining-userCourse';
  userFolder = 'user';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  oneUserCourseBackend(userCourseCode): Promise<any> {
    const filter = userCourseCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.userCourseTable + '/one/' + filter + queryParam, { headers }).toPromise();
  }

  oneUserCourseBackendNoLog(userCourseCode): Promise<any> {
    const filter = userCourseCode;
    return this.http.get(environment.API_URL + 'mtraining-userCourseNoLog' + '/one/' + filter).toPromise();
  }

  async allUserCourseBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    let pages = new Array();
    let paginationToken;
    const firstPage: any = await this.http.get(environment.API_URL + this.userCourseTable + queryParam, { headers }).toPromise();
    if (firstPage.nextPage === undefined) {
      return firstPage;
    } else {
      firstPage.body.forEach(element => {
        pages.push(element);
      });
      paginationToken = firstPage.nextPage;

      while (paginationToken !== undefined){
        const nextPage: any = await this.allUserCourseBackendNextPage(paginationToken.userCourseCode);
        if (nextPage.nextPage === undefined) {
          paginationToken = undefined;
          nextPage.body.forEach(element => {
            pages.push(element);
          });
          return {body: pages};
        } else {
          paginationToken = nextPage.nextPage;
          nextPage.body.forEach(element => {
            pages.push(element);
          });
        }
      }
    }
    // console.log(firstPage);
    // return firstPage;
  }

  allUserCourseBackendNextPage(nextPageToken): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.userCourseTable + '/next/' + nextPageToken + queryParam, { headers }).toPromise();
  }

  insertUserCourseBackend(userCourse): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.API_URL + this.userCourseTable, userCourse, { headers }).toPromise();
  }

  updateUserCourseBackend(userCourse: UserCourse): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.put(environment.API_URL + this.userCourseTable + '/', userCourse, { headers }).toPromise();
  }

  uploadFileS3(file, companyFolder, user: User, folder): Promise<any> {
    this.userProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company'+ '/' + companyFolder + '/' + this.userFolder + '/' + user.userId + '/' + folder + '/' + file.name,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.userProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }
}
