import { Component, OnInit } from '@angular/core';
import userNotesTableSettings from '../../../../../assets/config/userNotesTable/userNotesTableSettings.json';
import { UserService } from '../../../../services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { userInfo } from 'os';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-notes-table',
  templateUrl: './user-notes-table.component.html',
  styleUrls: ['./user-notes-table.component.scss']
})
export class UserNotesTableComponent implements OnInit {
  settings = userNotesTableSettings;
  loading: boolean;
  listUser;

  constructor(
    private router: Router,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService
  ) {  }

  ngOnInit() {
    this.loading = true;
    this.getUsers();
  }

  getUsers() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const dataSourseBackend = new Array<any>();
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;

          listUsers.forEach(user => {
            const userId = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

            const userForTable = {
              userCode: userId.Value,
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              company: {
                companyCode: companyCode.Value,
              },
              userRoleName: undefined,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;
              default:
                userForTable.userRoleName = user.Attributes[1].Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                dataSourseBackend.push(userForTable);
              }
            }

          });
          this.listUser = dataSourseBackend;
          // this.getUserCourse();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }

  userNotesNavigate($event) {
    const userInfo = $event.data;
    this.navigate(userInfo.userCode);
    // this.listUserCourses(userInfo.userCode);
  }

  navigate(userCode) {
    this.router.navigate(['/notes/' + userCode]);
  }

}
