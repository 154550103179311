import { Component, OnInit, Input } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';
import { NbToastrService, NbDialogRef, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import imageAccept from '../../../../../assets/data/restrictions/imageAccept.json';
import companyMaxSizeAccept from '../../../../../assets/data/restrictions/companyMaxSizeAccept.json';
import { CourseService } from 'src/app/services/course.service.js';

@Component({
  selector: 'app-company-image',
  templateUrl: './company-image.component.html',
  styleUrls: ['./company-image.component.scss']
})
export class CompanyImageComponent implements OnInit {

  selectedFiles: FileList;
  @Input() company = {} as Company;
  progress;
  loading: boolean;
  fileNameShow;
  fileSizeUsed: number;
  noSpaceFree = undefined;

  constructor(
    private courseService: CourseService,
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<null>
  ) { }

  ngOnInit() {
    this.companyService.companyProgress.subscribe(companyProgress => {
      companyProgress = Math.round(companyProgress);
      this.progress = companyProgress;
    });
  }

  imageEvent(event) {
    this.fileNameShow = event.target.files[0].name;
    const fileType = event.target.files[0].type;
    const fileNameSplit = this.fileNameShow.split('.')[1];
    const fileSizeShow = event.target.files[0].size;
    this.fileSizeUsed = 0;
    const imageMaxSizeShow = (companyMaxSizeAccept / 1000000);
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.selectedFiles = event.target.files;
    this.loading = true;
    this.noSpaceFree = undefined;
    let findAccept = true;
    imageAccept.forEach(element => {
      if (element === fileNameSplit && fileSizeShow <= companyMaxSizeAccept) {
        findAccept = false;
      }
    });
    if (findAccept) {
      this.selectedFiles = undefined;
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'El archivo que esta intentando subir no es permitido,o excede el tamaño permitido: ' + imageMaxSizeShow + 'mb',
        { position, status });
      this.loading = false;
      return false;
    }
    this.courseService.listFileS3().then(
      (result) => {
        const files = result.Contents;
        const sizeByCourse = new Array();
        files.forEach((file) => {
          const fileKey = file.Key.split('/');
          const companyFolderRoot = fileKey[0];
          const companyFolder = fileKey[1];
          if (companyFolderRoot === 'company') {
            if (companyFolder === userLogged.company.companyCode) {
              sizeByCourse[companyFolder] = (sizeByCourse[companyFolder] ? sizeByCourse[companyFolder] : 0) + file.Size;
            }
          }
        });
        this.courseService.allCourseCompanyBackend(userLogged.company.companyCode)
          .then(
            (resultCompany) => {
              const companys = resultCompany.body;
              const valueBucket = (sizeByCourse[userLogged.company.companyCode] ? sizeByCourse[userLogged.company.companyCode] : 0);
              this.fileSizeUsed = valueBucket;
              this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
                (company) => {
                  const maxStorageSpace = company.body.companyMaxFileStorage === undefined ? 0 : company.body.companyMaxFileStorage;
                  const freeSpace = maxStorageSpace - this.fileSizeUsed;
                  if (fileSizeShow < freeSpace) {
                    this.noSpaceFree = freeSpace;
                  } else {
                    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status: NbComponentStatus = 'danger';
                    this.toastrService.show(
                      '',
                      'Espacio insuficiente',
                      { position, status });
                    this.loading = false;
                  }
                }
              ).catch(
                (err) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              );
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  upload() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    this.loading = true;
    const file = this.selectedFiles.item(0);
    if (this.company.companyLogo !== undefined) {
      const deleteFileArr = this.company.companyLogo.split('/');
      const deleteFile = { name: deleteFileArr[deleteFileArr.length - 1] };
      this.companyService.deleteObject(deleteFile, companyCode, 'image').then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          // this.toastrService.show('', 'Logo anterior eliminado', { position, status });
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
    }
    this.companyService.uploadFileS3(file, this.company, 'image')
      .then(
        (data: any) => {
          this.company.companyLogo = data.Location,
            this.companyService.updateCompanyBackend(this.company)
              .then(
                (result) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'success';
                  this.toastrService.show('', 'Guardado', { position, status });
                  this.loading = false;
                }
              ).catch(
                (err) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              ).finally(
                () => {
                  this.loading = false;
                  this.dialogRef.close(this.company);
                });
        },
      ).catch(
        (error) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', error, { position, status });
          this.loading = false;
        });

  }

  close() {
    this.dialogRef.close(this.company);
  }

}
