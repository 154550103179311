import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SesService } from 'src/app/services/sesService';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-auto-confirm',
  templateUrl: './user-auto-confirm.component.html',
  styleUrls: ['./user-auto-confirm.component.scss']
})
export class UserAutoConfirmComponent implements OnInit {
  loading = false;
  userName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userCourseService: UserService,
    private sesService: SesService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const userEmail = params.params.userEmail;
      const verificationCode = params.params.verificationCode;
      if (userEmail !== undefined || verificationCode !== undefined) {
        this.userAutoValidation(userEmail, verificationCode);
      }
    });
  }

  userAutoValidation(userEmail, verificationCode) {
    this.loading = true;
    const companyLogo = window.location.hostname;
    const usrName = userEmail;
    const subdomain = companyLogo.split('.')[0];
    let userId = '';
    let password = '';
    this.authService.confirmUser(userEmail, verificationCode).then(
      (result) => {
        this.authService.getUsers().then(
          (resultUsers: any) => {
            const listUsers = resultUsers.Users;
            listUsers.forEach(user => {
              const sub = user.Attributes.find((x) => x.Name === 'sub');
              const email = user.Attributes.find((x) => x.Name === 'email');
              if (userEmail === email.Value) {
                userId = sub.Value;
              }
            });
            this.userCourseService.oneUserCourseBackendNoLog(userId).then(
              (resultUsersCourse: any) => {
                const userCourse = resultUsersCourse.body;
                password = userCourse.firstPassword;
                let request = {
                  userName: usrName,
                  userEmail: usrName,
                  userCompanyURL: 'https://' + subdomain + environment.emailSES.replace('https://' , ''),
                  userPassword: password,
                };
                this.sesService.sendEmail(request);
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show('', 'Usuario validado', { position, status });
                this.loading = false;
                this.router.navigate(['/login']);
              }
            ).catch((err) => {
              // console.error(err);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err,
                { position, status });
              this.loading = false;
              this.router.navigate(['/login']);
            });
          }
        ).catch((err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err,
            { position, status });
          this.loading = false;
          this.router.navigate(['/login']);
        });

      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          'Usuario o código incorrecto.',
          { position, status });
        this.loading = false;
        this.router.navigate(['/login']);
      }
    );
  }
}
