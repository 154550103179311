import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.js';
import { User } from 'src/app/models/user.js';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbThemeService } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dispersion-chart-admin',
  templateUrl: './dispersion-chart-admin.component.html',
  styleUrls: ['./dispersion-chart-admin.component.scss']
})
export class DispersionChartAdminComponent implements OnInit {
  loading: boolean;
  options: any;
  listUser: any;
  listUserCourse: any;

  constructor(
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getReportData();
  }

  getReportData() {
    this.loading = true;
    this.getUsers();

  }

  transformData() {
    const userCompleteInfo = new Array<any>();
    this.listUser.forEach((user) => {
      this.listUserCourse.forEach((userCourse) => {
        if (userCourse.userCourseCode === user.userCode) {
          userCompleteInfo.push({
            userCode: userCourse.userCourseCode,
            userName: user.name,
            userEmail: user.email,
            userRole: user.userRole,
            userCourses: userCourse.userCourses
          });
        }
      });
    });
    const userCompleteInfoReport = new Array<any>();
    userCompleteInfo.forEach((completeInfo) => {
      if (completeInfo.userCourses !== undefined) {
        completeInfo.userCourses.forEach((course) => {
          if (course.courseExamResults !== undefined) {
            course.courseExamResults.forEach(exam => {
              userCompleteInfoReport.push({
                name: completeInfo.userName,
                Correo: completeInfo.userEmail,
                Curso: course.courseName,
                Realizado: new Date(exam.examDate).toLocaleString(),
                Examen: exam.examName,
                data: [(((exam.examScore / exam.examScoreOver) * 100).toString())],
                type: 'scatter',
                symbolSize: 10,
              });
            });
          }
        });
      }
    });
    this.options = {
      title: {
        text: 'Notas',
        left: 'center',
        top: 0
      },
      visualMap: {
        min: 0,
        max: 100,
        dimension: 1,
        orient: 'vertical',
        right: 5,
        top: 'center',
        text: ['HIGH', 'LOW'],
        calculable: true,
        inRange: {
          color: ['#b0b0b0', '#ff8c21']
        }
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'cross',
        }
      },
      xAxis: [{
        type: 'value',
        max: 100
      }],
      yAxis: [{
        type: 'value',
        max: 100
      }],
      series: userCompleteInfoReport
    };
  }

  getUserCourse() {
    this.loading = true;
    const dataSourseBackend = new Array<any>();
    this.userService.allUserCourseBackend()
      .then(
        (result) => {
          this.listUserCourse = result.body;
          this.transformData();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return dataSourseBackend;
  }

  getUsers() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const dataSourseBackend = new Array<any>();
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;
          // this.userTooltip = listUsers;

          listUsers.forEach(user => {

            const userId = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

            const userForTable = {
              userCode: userId.Value,
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              company: {
                companyCode: companyCode.Value,
              },
              userRoleName: undefined,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;
              default:
                userForTable.userRoleName = user.Attributes[1].Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                dataSourseBackend.push(userForTable);
              }
            }

          });
          this.listUser = dataSourseBackend;
          this.getUserCourse();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }

}
