import { Component, OnInit } from '@angular/core';
import adminChartSettings from '../../../../assets/config/adminChartSettings.json';
import { CompanyService } from 'src/app/services/company.service.js';
import { User } from 'src/app/models/user.js';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService, NbThemeService } from '@nebular/theme';
import { EChartOption } from 'echarts';
import { Company } from 'src/app/models/company.js';

@Component({
  selector: 'app-user-admin-chart',
  templateUrl: './user-admin-chart.component.html',
  styleUrls: ['./user-admin-chart.component.scss']
})
export class UserAdminChartComponent implements OnInit {
  options: EChartOption;
  loading: boolean;

  constructor(
    private companyService: CompanyService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.loading = true;
    this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
      (result) => {
        this.options = adminChartSettings;
        const company: Company = result.body;
        const serie = {
          name: 'Usuarios',
          type: 'pie',
          radius: '80%',
          center: '50%,50%',
          data: [
            { name: 'Usuarios Activos', value: company.companyCountUser },
            { name: 'Usuarios Disponibles', value: (company.companyMaxUser - company.companyCountUser) }
          ]
        }
        this.options.series.push(serie);
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

}
