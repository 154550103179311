import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { NbToastrService, NbDialogRef, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';

@Component({
  selector: 'app-user-my-profile-image',
  templateUrl: './user-my-profile-image.component.html',
  styleUrls: ['./user-my-profile-image.component.scss']
})
export class UserMyProfileImageComponent implements OnInit {
  selectedFiles: FileList;
  @Input() user = {} as User;
  progress;
  loading: boolean;
  fileNameShow;

  constructor(
    private userService: UserService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<null>
  ) { }

  ngOnInit() {
    this.userService.userProgress.subscribe(userProgress => {
      userProgress = Math.round(userProgress);
      this.progress = userProgress;
    });
  }

  imageEvent(event) {
    this.fileNameShow = event.target.files[0].name;
    this.selectedFiles = event.target.files;
  }

  upload() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    this.loading = true;
    const file = this.selectedFiles.item(0);
    this.userService.uploadFileS3(file, companyCode, this.user, 'image')
      .then(
        (data: any) => {
          this.user.picture = data.Location;
          this.dialogRef.close(this.user);
        },
      ).catch(
        (error) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', error, { position, status });
          this.loading = false;
        });

  }

  close() {
    this.dialogRef.close(this.user);
  }
}
