import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import userRole from '../../../../assets/data/userRole/userRole.json';
import { UserRole, User } from '../../../models/user';
import { Router } from '@angular/router';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-new-user-fields',
  templateUrl: './new-user-fields.component.html',
  styleUrls: ['./new-user-fields.component.scss']
})
export class NewUserFieldsComponent implements OnInit {

  user = {} as User;
  department;

  userRole = {} as UserRole;

  userRoleList = userRole;
  departmentList = new Array();

  loading: boolean;

  submitEnable;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private toastrService: NbToastrService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.getCompany(user.company.companyCode);
  }

  getCompany(companyCode) {
    this.loading = true;
    this.companyService.oneCompanyBackend(companyCode).then(
      (result) => {
        this.departmentList = result.body.companyDepartments;
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  cancel() {
    this.router.navigateByUrl('/newUser');
  }


  generatePassword() {
    // No cambiar los var loquea el generador.
    const length = 5;
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const punctuation = '!@#$%&';
    let retVal = '';
    for (var i = 0, n = lowercase.length; i < length; ++i) {
      retVal += lowercase.charAt(Math.floor(Math.random() * n));
      // tslint:disable-next-line:no-shadowed-variable
      for (var i = 0, n = uppercase.length; i < length; ++i) {
        retVal += uppercase.charAt(Math.floor(Math.random() * n));
        // tslint:disable-next-line:no-shadowed-variable
        for (var i = 0, n = numbers.length; i < length; ++i) {
          retVal += numbers.charAt(Math.floor(Math.random() * n));
          // tslint:disable-next-line:no-shadowed-variable
          for (var i = 0, n = punctuation.length; i < length; ++i) {
            retVal += punctuation.charAt(Math.floor(Math.random() * n));
          }
        }
      }
    }
    return retVal;
  }

  inputValidate() {
    if (this.user.name !== '') {
      this.submitEnable = false;
    }
  }

  onSignUpSubmit() {
    this.loading = true;
    const usrName = this.user.email;
    const email = this.user.email;
    const password = this.generatePassword();
    const name = this.user.name;
    const userRol = this.userRole.value;
    const picture = this.user.email;
    const department = this.department;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
      (resultGetCompany: any) => {
        let position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        let status: NbComponentStatus = 'success';
        const company: Company = resultGetCompany.body;
        let canCreateUser = false;
        switch (userRol) {
          case 'user':
            if (company.companyMaxUser >= company.companyCountUser + 1) {
              canCreateUser = true;
            }
            break;
          case 'user_administrator':
            if (company.companyMaxUserAdmin >= company.companyCountUserAdmin + 1) {
              canCreateUser = true;
            }
            break;
          case 'editor':
            if (company.companyMaxUserEditor >= company.companyCountUserEditor + 1) {
              canCreateUser = true;
            }
            break;

          default:
            canCreateUser = false;
            break;
        }
        if (canCreateUser) {
          this.authService.signUp(usrName, email, password, name, userRol, picture, department).then(
            (result: any) => {

              const userCourse = {
                userCourseCode: result.userSub,
                firstPassword: password
              };
              this.userService.insertUserCourseBackend(userCourse).then(
                (resultInsert: any) => {
                  this.toastrService.show(
                    '',
                    'Usuario ' + name + ' creado.',
                    { position, status });

                  switch (userRol) {
                    case 'user':
                      company.companyCountUser = company.companyCountUser + 1;
                      break;
                    case 'user_administrator':
                      company.companyCountUserAdmin = company.companyCountUserAdmin + 1;
                      break;
                    case 'editor':
                      company.companyCountUserEditor = company.companyCountUserEditor + 1;
                      break;

                    default:

                      break;
                  }
                  this.companyService.updateCompanyBackend(company).then(
                    (result: any) => {
                      this.router.navigate(['/newUser']);
                    }
                  ).catch(
                    (err) => {
                      position = NbGlobalPhysicalPosition.TOP_RIGHT;
                      status = 'danger';
                      this.toastrService.show(
                        '',
                        err.message,
                        { position, status });
                      this.loading = false;
                    }
                  );
                }
              ).catch(
                (err) => {
                  position = NbGlobalPhysicalPosition.TOP_RIGHT;
                  status = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              );
              this.loading = false;
            }
          ).catch(
            (err) => {
              position = NbGlobalPhysicalPosition.TOP_RIGHT;
              status = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        } else {
          position = NbGlobalPhysicalPosition.TOP_RIGHT;
          status = 'danger';
          this.toastrService.show(
            '',
            'Limite excedido del tipo de usuario seleccionado.',
            { position, status });
        }

      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

}
