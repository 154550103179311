import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import courseExamTable from '../../../../../assets/config/courseTable/courseExamTable.json';
import { Course } from 'src/app/models/course';
import { Exam } from 'src/app/models/exam.js';
import { UserService } from 'src/app/services/user.service';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-new-course-exam-table',
  templateUrl: './new-course-exam-table.component.html',
  styleUrls: ['./new-course-exam-table.component.scss']
})
export class NewCourseExamTableComponent implements OnInit, OnChanges {

  @Input() course = {} as Course;
  @Output() examEdit = new EventEmitter<any>();
  @Output() examDelete = new EventEmitter<any[]>();
  settings = courseExamTable;
  courseExamList = new Array<any>();
  loading;

  constructor(
    private userCourseService: UserService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.courseExamList = this.course.courseExam;
    if (this.courseExamList !== undefined) {
      this.courseExamList.forEach((exam) => {
        exam.examTypeShow = exam.examFinal ? 'Final' : 'Intermedio';
        exam.examStatusShow = exam.examStatus ? 'Activo' : 'Inactivo';
        if (exam.examFinal === true) {
          exam.examFinalCertifiedShow = exam.examFinalCertified ? 'Si' : 'No';
        } else {
          exam.examFinalCertifiedShow = "No es examen final";
        }
        // console.log(exam.examFinalCertified);
      });
    }
  }

  searchResults(event, functionEvent) {
    this.loading = true;
    this.userCourseService.allUserCourseBackend().then(
      (result) => {
        const userCourses = result.body;
        let response;
        userCourses.forEach(userCourse => {
          if (userCourse.userCourses !== undefined) {
            userCourse.userCourses.forEach(course => {
              if (course.courseExamResults !== undefined) {
                course.courseExamResults.forEach(examResult => {
                  if (examResult.examCode === event.data.examCode) {
                    response = true;
                  }
                });
              }
            });
          }
        });
        if (functionEvent === 'edit') {

          if (response === undefined) {
            this.examEdit.emit({ event, edit: 'true' });
          } else {
            this.examEdit.emit({ event, edit: 'solved' });
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'warning';
            this.toastrService.show(
              '',
              'Examen fue resuelto por uno o más usuarios, no puede ser editado, solo colocarlo inactivo',
              { position, status });
          }
        } else {
          if (response === undefined) {
            this.examDelete.emit(event);
          } else {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'danger';
            this.toastrService.show(
              '',
              'Examen fue resuelto por uno o más usuarios, no puede ser eliminado',
              { position, status });
          }
        }
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err,
          { position, status });
        this.loading = false;
      }
    );
  }

  deleteExam(event) {
    this.searchResults(event, 'delete');
  }

  editExam(event) {
    this.searchResults(event, 'edit');
  }

}
