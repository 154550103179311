import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { NbDialogService } from '@nebular/theme';
import { UnassignMultipleUsersComponent } from '../unassign-multiple-users/unassign-multiple-users.component';
@Component({
  selector: 'app-new-user-tools',
  templateUrl: './new-user-tools.component.html',
  styleUrls: ['./new-user-tools.component.scss']
})
export class NewUserToolsComponent implements OnInit {
  loading = false;
  userListTable: Array<User>;
  userList: any[];
  status: string;
  @Output() userListResult = new EventEmitter<any[]>();



  constructor(
    private router: Router,
    private authService: AuthService,
    private dialogService: NbDialogService,) { }

  ngOnInit() {
    this.listAllUser();
  }

  addNewUser() {
    this.router.navigate(['/userFields']);
  }

  deleteMultipleUsers() {
    this.dialogService.open(UnassignMultipleUsersComponent,
      {
        context: {
          userList: this.userListTable,
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      });
    console.log('Multiples');
  }

  listAllUser() {
    this.loading = true;
    this.authService.getUsers()
      .then(
        (result: any) => {
          const listUsers = result.Users;
          this.userListTable = new Array<User>();
          const userLogged: User = JSON.parse(localStorage.getItem('user'));
          listUsers.forEach(user => {
            const userSub = user.Attributes.find((x) => x.Name === 'sub');
            const username = user.Attributes.find((x) => x.Name === 'email');
            const email = user.Attributes.find((x) => x.Name === 'email');
            const name = user.Attributes.find((x) => x.Name === 'name');
            const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
            const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
              user.Attributes.find((x) => x.Name === 'custom:department') : '';
            const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');
            const userForTable: User = {
              username: username.Value,
              email: email.Value,
              name: name.Value,
              userRole: userRole.Value,
              userStatus: user.Enabled ? 'Activo' : 'Inactivo',
              picture: '',
              password: '',
              department: department.Value ? department.Value : '',
              theme: undefined,
              company: {
                companyCode: companyCode.Value,
                companyName: undefined,
                companyScormSelected: undefined,
                companyCountUser: undefined,
                companyMaxUser: undefined,
                companyCountUserAdmin: undefined,
                companyMaxUserAdmin: undefined,
                companyCountUserEditor: undefined,
                companyMaxUserEditor: undefined,
                companyNameForFilter: undefined,
                companyLogo: undefined,
                companyDepartments: undefined,
                companyMaxFileStorage: undefined,

              },
              userRoleName: undefined,
              userId: userSub.Value,
            };

            switch (userRole.Value) {
              case 'editor':
                userForTable.userRoleName = 'Editor';
                break;
              case 'user_administrator':
                userForTable.userRoleName = 'Administrador de Usuarios';
                break;
              case 'user':
                userForTable.userRoleName = 'Usuario';
                break;

              default:
                userForTable.userRoleName = userRole.Value;
                break;
            }

            if (userRole.Value !== 'administrator') {
              if (userLogged.company.companyCode === userForTable.company.companyCode) {
                this.userListTable.push(userForTable);
              }
            }
          });
          this.loading = false;
          this.userListResult.emit(this.userListTable);
        }
      ).catch(
        (err) => {
          this.loading = false;
        }
      );
  }

}
