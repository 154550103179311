import { Component, OnInit, OnChanges } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/models/company';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-report-user',
  templateUrl: './report-user.component.html',
  styleUrls: ['./report-user.component.scss']
})
export class ReportUserComponent implements OnInit, OnChanges {
  dataSourse = new Array<any>();
  reportName = 'reporte de usuarios';
  loading: boolean;
  counterAdminU;
  counterEditor;
  counterUser;
  counterStorage = 0;

  constructor(
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private courseService: CourseService
  ) { }

  ngOnChanges() { }

  ngOnInit() {
    this.getReportData();
    this.getReportStorageData();
  }

  getReportData() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.loading = true;
    this.companyService.oneCompanyBackend(userLogged.company.companyCode)
      .then(
        (result) => {
          const company: Company = result.body;
          this.counterAdminU = company.companyCountUserAdmin.toString() + ' / ' + company.companyMaxUserAdmin.toString();
          this.counterEditor = company.companyCountUserEditor.toString() + ' / ' + company.companyMaxUserEditor.toString();
          this.counterUser = company.companyCountUser.toString() + ' / ' + company.companyMaxUser.toString();
          this.authService.getUsers()
            .then(
              (result: any) => {
                const listUsers = result.Users;
                const dataSourse: Array<any> = new Array<any>();
                const dataSourseBackend = new Array<any>();
                listUsers.forEach(user => {
                  const username = user.Attributes.find((x) => x.Name === 'email');
                  const email = user.Attributes.find((x) => x.Name === 'email');
                  const name = user.Attributes.find((x) => x.Name === 'name');
                  const userRole = user.Attributes.find((x) => x.Name === 'custom:role');
                  const department = user.Attributes.find((x) => x.Name === 'custom:department') ?
                    user.Attributes.find((x) => x.Name === 'custom:department') : '';
                  const companyCode = user.Attributes.find((x) => x.Name === 'custom:company');

                  const userForTable = {
                    username: username.Value,
                    email: email.Value,
                    name: name.Value,
                    userRole: userRole.Value,
                    userStatus: user.Enabled ? 'Activo' : 'Inactivo',
                    UserConfirm: user.UserStatus,
                    department: department.Value,
                    company: {
                      companyCode: companyCode.Value,
                    },
                    userRoleName: undefined,
                  };

                  switch (userRole.Value) {
                    case 'editor':
                      userForTable.userRoleName = 'Editor';
                      break;
                    case 'user_administrator':
                      userForTable.userRoleName = 'Administrador de Usuarios';
                      break;
                    case 'user':
                      userForTable.userRoleName = 'Usuario';
                      break;
                    default:
                      userForTable.userRoleName = user.Attributes[1].Value;
                      break;
                  }

                  if (userRole.Value !== 'administrator') {
                    if (userLogged.company.companyCode === userForTable.company.companyCode) {
                      dataSourseBackend.push(userForTable);
                    }
                  }

                });
                dataSourseBackend.forEach((sourse) => {
                  dataSourse.push({
                    Usuario: sourse.name,
                    Correo: sourse.email,
                    'Rol usuario': sourse.userRoleName,
                    Estado: sourse.userStatus,
                    Confirmado: sourse.UserConfirm === 'CONFIRMED' ? 'Si' : 'No',
                    Departamento: sourse.department,
                  });
                });
                this.dataSourse = dataSourse;
                this.loading = false;
              }
            ).catch(
              (err) => {
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'danger';
                this.toastrService.show(
                  '',
                  err.message,
                  { position, status });
                this.loading = false;
              }
            );
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
  }

  getReportStorageData() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.loading = true;
    this.courseService.listFileS3().then(
      (result) => {
        const content = result.Contents;
        const sizeByCourse = new Array();
        content.forEach((file) => {
          const fileKey = file.Key.split('/');
          const companyFolderRoot = fileKey[0];
          const companyFolder = fileKey[1];
          if (companyFolderRoot === 'company') {
            if (companyFolder === userLogged.company.companyCode) {
              sizeByCourse[companyFolder] = (sizeByCourse[companyFolder] ? sizeByCourse[companyFolder] : 0) + file.Size;
            }
          }
        });
        this.courseService.allCourseCompanyBackend(userLogged.company.companyCode)
          .then(
            (resultCompany) => {
              const companys = resultCompany.body;
              const valueBucket = (sizeByCourse[userLogged.company.companyCode] ? sizeByCourse[userLogged.company.companyCode] : 0);
              this.counterStorage = valueBucket;
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }
}
