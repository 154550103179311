import { Component, Input, OnInit, OnChanges, OnDestroy, AfterContentInit, DoCheck, HostListener, Output } from '@angular/core';
import { Video } from 'src/app/models/video';
import { VgAPI } from 'videogular2/compiled/src/core/core';
import { UserCourse, UserCourseCourseVideoProgress } from 'src/app/models/userCourse';
import { UserService } from 'src/app/services/user.service';
import { Course } from 'src/app/models/course';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentIndex;
  @Input() playlist: Array<Video>;
  @Input() currentItem = {} as Video;
  @Input() course = {} as Course;
  @Input() playPause = true;
  videoInterval;
  actualTime;

  api: VgAPI;
  currentItemOld = this.currentItem;
  changeDetected;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    clearInterval(this.videoInterval);
    let userCourse: UserCourse;
    if (localStorage.getItem('userCourse') !== undefined) {
      const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses,
      };
      this.updateUserCourse(userCourse);
    }
  }

  timerSave() {
    let userCourse: UserCourse;
    if (localStorage.getItem('userCourse') !== undefined) {
      const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses,
      };
      const videoCurrentTime = {
        videoName: this.currentItem.videoName,
        videoCurrent: this.api.getDefaultMedia().currentTime,
        videoStatus: false,
        videoCompleted: false,
      } as UserCourseCourseVideoProgress;
      userCourse.userCourses.forEach((actualCourse) => {
        if (actualCourse.courseCode === this.course.courseCode) {
          if (actualCourse.courseVideo !== undefined) {
            let indexSavedVideo = -1;
            actualCourse.courseVideo.forEach((savedVideos, indexSavedVideos) => {
              if (savedVideos.videoName === videoCurrentTime.videoName) {
                indexSavedVideo = indexSavedVideos;
              }
            });
            if (indexSavedVideo !== -1) {
              actualCourse.courseVideo.splice(indexSavedVideo, 1);
            }
            if (this.api.getDefaultMedia().time.left < 5) {
              videoCurrentTime.videoStatus = true;
            }
            if ((this.api.duration - this.api.currentTime) < 20) {
              videoCurrentTime.videoCompleted = true;
            } else {
              videoCurrentTime.videoCompleted = false;
            }
            actualCourse.courseVideo.push(videoCurrentTime);
          } else {
            actualCourse.courseVideo = new Array<UserCourseCourseVideoProgress>();
            actualCourse.courseVideo.push(videoCurrentTime);
          }
          localStorage.setItem('userCourse', JSON.stringify(userCourse));
        }
      });
    }
  }

  updateUserCourse(userCourse) {
    this.userService.updateUserCourseBackend(userCourse)
      .then(
        (result) => {
          return true;
        }
      ).catch(
        (err) => {
          // console.error(err);
          return true;
        }
      );
  }

  ngOnChanges() {
    if (this.playPause === false) {
      this.api.pause();
    }
    this.playPause = true;
    clearInterval(this.videoInterval);
    let user: any = {};
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
      const role = user.userRole;
      if (role !== 'administrator') {
        if (this.currentItem !== undefined) {
          setTimeout(() => {
            const current = this.checkCurrentInverval();
            if (current !== undefined) {
              this.api.currentTime = current;
            }

          }, 1000);
          this.videoInterval = setInterval(() => { this.timerSave(); }, 5000);
        }
      }
    }
  }

  checkCurrentInverval(): number {
    let userCourse: UserCourse;
    let result: number;
    if (localStorage.getItem('userCourse') !== undefined) {
      const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses,
      };
      userCourse.userCourses.forEach((actualCourse) => {
        if (actualCourse.courseCode === this.course.courseCode) {
          if (actualCourse.courseVideo !== undefined) {
            actualCourse.courseVideo.forEach((savedVideos, indexSavedVideos) => {
              if (savedVideos.videoName === this.currentItem.videoName) {
                if ((savedVideos.videoCurrent !== 0) || (savedVideos.videoCurrent !== undefined)) {
                  result = savedVideos.videoCurrent ? savedVideos.videoCurrent : 0;
                }
              }
            });
          }
        }
      });
    }
    return result;
  }

  onPlayerReady(api: VgAPI) {
    this.api = api;
  }



  nextVideo() {
    if (this.playlist !== undefined) {
      this.currentIndex++;

      if (this.currentIndex === this.playlist.length) {
        this.currentIndex = 0;
      }

      this.currentItem = this.playlist[this.currentIndex];
    }
  }

  playVideo() {
    // this.api.play();
  }

}
