import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss']
})
export class NewCourseComponent implements OnInit {

  courseList: any[];

  constructor() { }

  ngOnInit() {
  }

  courseListReceiver($event) {
    this.courseList = $event;
  }
}
