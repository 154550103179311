import { Component, Input, OnInit } from '@angular/core';
import { CourseService } from '../../../../services/course.service';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Course } from '../../../../models/course';
import { Attachment } from 'src/app/models/attachment';
import { CompanyService } from 'src/app/services/company.service';
import attachmentAccept from '../../../../../assets/data/restrictions/attachmentAccept.json';

@Component({
  selector: 'app-new-course-scrom-file',
  templateUrl: './new-course-scrom-file.component.html',
  styleUrls: ['./new-course-scrom-file.component.scss']
})
export class NewCourseScromFileComponent implements OnInit {
  selectedFiles: FileList;
  @Input() course = {} as Course;
  progress;
  loading: boolean;
  fileNameShow;
  fileType;
  fileSizeUsed: number;
  noSpaceFree = undefined;

  constructor(
    private courseService: CourseService,
    private companyService: CompanyService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
    this.courseService.courseProgress.subscribe(courseProgress => {
      courseProgress = Math.round(courseProgress);
      this.progress = courseProgress;
    });
  }


  attachmentEvent(event) {
    this.selectedFiles = event.target.files;
    let fileSizeShow = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      fileSizeShow = fileSizeShow + file.size;
    }
    this.fileSizeUsed = 0;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.loading = true;
    this.noSpaceFree = undefined;
    this.courseService.listFileS3().then(
      (result) => {
        const files = result.Contents;
        const sizeByCourse = new Array();
        files.forEach((file) => {
          const fileKey = file.Key.split('/');
          const companyFolderRoot = fileKey[0];
          const companyFolder = fileKey[1];
          if (companyFolderRoot === 'company') {
            if (companyFolder === userLogged.company.companyCode) {
              sizeByCourse[companyFolder] = (sizeByCourse[companyFolder] ? sizeByCourse[companyFolder] : 0) + file.Size;
            }
          }
        });
        this.courseService.allCourseCompanyBackend(userLogged.company.companyCode)
          .then(
            (resultCompany) => {
              const companys = resultCompany.body;
              const valueBucket = (sizeByCourse[userLogged.company.companyCode] ? sizeByCourse[userLogged.company.companyCode] : 0);
              this.fileSizeUsed = valueBucket;
              this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
                (company) => {
                  const maxStorageSpace = company.body.companyMaxFileStorage === undefined ? 0 : company.body.companyMaxFileStorage;
                  const freeSpace = maxStorageSpace - this.fileSizeUsed;
                  if (fileSizeShow < freeSpace) {
                    this.noSpaceFree = freeSpace;
                  } else {
                    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status: NbComponentStatus = 'danger';
                    this.toastrService.show(
                      '',
                      'Espacio insuficiente',
                      { position, status });
                    this.loading = false;
                  }
                }
              ).catch(
                (err) => {
                  const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status: NbComponentStatus = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              );
            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  upload() {
    this.loading = true;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    const files = this.selectedFiles;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file: any = files[i];
      const path = file.webkitRelativePath;
      this.courseService.blockFileS3(file, companyCode, this.course, path, i)
      .then(
        (data: any) => {
          if ( data === (files.length - 1)) {
             this.loading = false;
          }
        },
      ).catch(
        (error) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show('', error, { position, status });
        });
    }
  }

}
