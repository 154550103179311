import { Component, OnInit, Output } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { Router, ActivatedRoute } from '@angular/router';
import userPerCourseNotesSettings from '../../../../../../assets/config/userNotesTable/userPerCourseNotesSettings.json';
import { groupBy } from 'rxjs/operators';

@Component({
  selector: 'app-view-course-notes-table',
  templateUrl: './view-course-notes-table.component.html',
  styleUrls: ['./view-course-notes-table.component.scss']
})
export class ViewCourseNotesTableComponent implements OnInit {
  loading: boolean;
  settings = userPerCourseNotesSettings;
  courseName;
  userCourses;

  constructor(
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const userCode = params.params.userCode;
      const courseCode = params.params.courseCode;
      if (userCode !== undefined) {
        this.listUserCourses(userCode, courseCode);
      }
    });
  }

  listUserCourses(userCode, courseCode) {
    this.loading = true;
    const userCompletInfoReport = new Array<any>();
    this.userService.oneUserCourseBackend(userCode)
      .then(
        (result) => {
          const allData = result.body;
          // listUserCourse.forEach(allData => {
          //   if (allData.userCourses !== undefined) {
          allData.userCourses.forEach(userCourses => {
            if (userCourses.courseCode === courseCode) {
              this.courseName = userCourses.courseName;
              if (userCourses.courseExamResults !== undefined) {
                userCourses.courseExamResults.forEach(examNotes => {
                  userCompletInfoReport.push({
                    'Curso': userCourses.courseName,
                    'Realizado': new Date(examNotes.examDate).toLocaleString(),
                    'Examen': examNotes.examName,
                    'Resultado': (Math.round(((examNotes.examScore / examNotes.examScoreOver) * 100)).toString())
                  });
                });
              }
            }
          });
          //   }
          // });
          this.userCourses = userCompletInfoReport.sort();
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    return userCompletInfoReport;
  }
}
