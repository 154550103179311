<nb-layout-column>
  <nb-card>
    <nb-card-body>
      <nb-auth-block class="card-col2">
        <div class="brand-logo">
          <img class="brand-logo-img" [src]="urlLog" [nbTooltip]="urlAlt">
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm" style="text-align: center !important;">
              <button nbButton ghost (click)="signInWithGoogle()">
                <img width="20px" alt="Google"
                  src="https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg">
                <span style="margin-left: 0.1rem; color: gray;">Google</span>
              </button>
            </div>
            <div class="col-sm" style="text-align: center !important;">
              <button nbButton ghost (click)="signInWithAzure()">
                <img width="20px" alt="Office 365"
                  src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg">
                  <span style="margin-left: 0.1rem; color: gray;">Microsoft</span>
              </button>
            </div>
          </div>
        </div>
        <form aria-labelledby=" title" (ngSubmit)="onSubmit(f)" #f="ngForm">
          <div class="form-control-group">
            <input nbInput fullWidth class="inputText" pattern=".+@.+\..+" autofocus type="email" shape="semi-round"
              autocomplete="off" placeholder="Correo electrónico" name="email" id="email" ngModel maxlength="50"
              required>
          </div>
          <div class="form-control-group">
            <input nbInput fullWidth class="inputText" [type]="hide ? 'password' : 'text'" shape="semi-round"
              autocomplete="off" placeholder="Contraseña" name="password" id="password" ngModel maxlength="50" required>
            <nb-icon nbButton class="mat-icon-button-hide" (click)="hide = !hide" icon="eye-outline"></nb-icon>
            <div class="container">
              <div class="row">
                <div class="col-md" style="float: right !important;">
                  <label style="float:right !important; font-size: 0.6rem !important; font-weight:bold !important"
                    (click)="onClickResetPassword()">Restablecer contraseña</label>
                </div>
              </div>
            </div>
          </div>
          <button nbButton fullWidth type="submit" class="button-login">
            Ingresar
            <nb-icon icon="checkmark-outline"></nb-icon>
          </button>
          <div class="extra-button">
            <!-- <button type="button" fullWidth nbButton ghost responsive size="tiny" class="reset-button"
            style=" color: #1378DE;" (click)="onClickResetPassword()">
              Restablecer contraseña
              <nb-icon icon="unlock-outline"></nb-icon>
            </button> -->
            <button type="button" fullWidth nbButton ghost responsive size="tiny" animation="pulse" class="reset-button"
              style="color: #1378DE;" (click)="onResendValid()">Reenviar validación
              <nb-icon icon="email-outline"></nb-icon>
            </button>
          </div>
        </form>
        <div class="brand-logo2">
          <img class="brand-logo-img2" src="assets/images/mTrainingWorld.png">
        </div>
      </nb-auth-block>
    </nb-card-body>
    <nb-card-footer>
      <div class="footer-container">
        <span class="span-footer">
          <a href="https://myappsoftware.com/" target="_blank" class="span-footer-a">
            <span class="span-footer-myapp">Myappsoftware</span>
          </a>
          <span class="span-footer-powered"> powered by </span>
        </span>
        <a href="https://aws.amazon.com/partners/find/partnerdetails/?n=Criptonube%20-%20Myappsoftware&id=001E000000VKaCSIA1"
          target="_blank">
          <img class="span-footer-img" src="assets/images/awsLogo.png">
        </a>
      </div>
    </nb-card-footer>
  </nb-card>
</nb-layout-column>
