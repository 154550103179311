import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class SesService {
  AWS = require('aws-sdk');

  constructor() { }

  sendEmail(funcionArgs: any) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });
    const lambda = new this.AWS.Lambda({apiVersion: '2015-03-31'});
    let params = {
      FunctionName: 'arn:aws:lambda:us-east-1:405522164029:function:mtrainingbackend-qa-userSendCredentials',
      Payload: JSON.stringify(funcionArgs),
      InvocationType: 'RequestResponse'
      // Qualifier: "1"
     };
    return new Promise(async (resolve, reject) => {
      await lambda.invoke(params,  (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  // sendEmail(userName, userPassword, userEmail, subdomain) {
  //   this.AWS.config.update({
  //     accessKeyId: environment.accessKeyId,
  //     secretAccessKey: environment.secretAccessKey,
  //     region: environment.regionSES
  //   });

  //   const ses = new this.AWS.SES({ apiVersion: '2010-12-01' });
  //   subdomain = subdomain === 'myapptraining' ? '' : subdomain + '.';
  //   const params = {
  //     Destination: {
  //       ToAddresses: [userEmail]
  //     },
  //     Message: {
  //       Body: {
  //         Html: {
  //           Charset: 'UTF-8',
  //           Data:
  //             `
  //             <html>
  //               <body>
  //                 <img src=` + environment.emailImage + ` style="width: 22rem;">
  //                   <h2>Usuario: ` + userName + `</h2>
  //                   <h2>Contraseña: ` + userPassword + `</h2>
  //                   <h3>` + 'https://' + subdomain + environment.emailSES.replace('https://' , '') + `</h3>
  //               </body>
  //             </html>
  //           `
  //         },
  //         Text: {
  //           Charset: 'UTF-8',
  //           Data: 'Bienvenido a mTraining.'
  //         }
  //       },
  //       Subject: {
  //         Charset: 'UTF-8',
  //         Data: 'Credenciales de mTraining.'
  //       }
  //     },
  //     Source: 'mtraining@myappsoftware.com'
  //   };

  //   const sendEmail = ses.sendEmail(params).promise();

  //   sendEmail
  //     .then(data => {
  //     })
  //     .catch(error => {
  //       // console.error(error);
  //     });

  // }
}
