import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Course } from 'src/app/models/course';
import { Exam, Question, Answer } from 'src/app/models/exam';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { CourseService } from 'src/app/services/course.service';
import courseQuestionTable from '../../../../../assets/config/courseTable/courseQuestionTable.json';
import courseAnswerTable from '../../../../../assets/config/courseTable/courseAnswerTable.json';
import { LocalDataSource } from 'ng2-smart-table';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-new-course-exam',
  templateUrl: './new-course-exam.component.html',
  styleUrls: ['./new-course-exam.component.scss']
})
export class NewCourseExamComponent implements OnInit, OnChanges {
  @Input() course = {} as Course;
  @Input() exam = {} as Exam;
  @Input() edit;
  @Input() index;
  changeExam;
  enableEdit;

  question = {} as Question;
  answer = {} as Answer;
  addAnswer;
  editAnswer;
  deleteAnswer;
  editAnswerIndex;

  addQuestion;
  editQuestion;
  deleteQuestion;
  editQuestionIndex;

  loading: boolean;
  settingsQuestion = courseQuestionTable;
  settingsAnswer = courseAnswerTable;
  examQuestionList: LocalDataSource = new LocalDataSource(this.exam.examQuestion);
  examAnswerList: LocalDataSource = new LocalDataSource();

  constructor(
    private courseService: CourseService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<NewCourseExamComponent>
  ) { }

  ngOnInit() {
    if (this.course.courseExam !== undefined) {
      this.course.courseExam.forEach((exam) => {
        if (exam.examFinal === true) {
          this.enableEdit = true;
        }
        if (exam.examName === this.exam.examName) {
          this.enableEdit = false;
        }
      });
      if (this.index !== undefined) {
        this.examQuestionList = new LocalDataSource(this.course.courseExam[this.index].examQuestion);
      }
    }
    if (this.edit === 'solved') {
      this.settingsQuestion.actions.edit = false;
      this.settingsQuestion.actions.delete = false;
    } else {
      this.settingsQuestion.actions.edit = true;
      this.settingsQuestion.actions.delete = true;
    }
    if (this.edit === undefined) {
      this.exam.examStatus = true;
    }
  }

  ngOnChanges() {

  }

  validateExam(): boolean {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message;
    if (this.exam.examQuestion === undefined) { message = 'debe tener almenos una pregunta para guardar'; } else {
      if (this.exam.examQuestion.length < 0) { message = 'debe tener almenos una pregunta para guardar'; }
    }
    if (this.exam.examTime < 1) { message = 'Tiempo del examen no puede ser menor a 1 minuto'; }
    if (this.exam.examTime > 600) { message = 'Tiempo del examen no puede ser mayor a 600 minutos'; }
    if (this.exam.examTime === undefined) { message = 'Tiempo del examen no puede ser vacio'; }
    if (this.exam.examName === undefined) { message = 'Nombre del examen no puede ser vacio'; }
    // if (this.course.courseExam !== undefined) {
    //   this.course.courseExam.forEach((exam) => {
    //     console.log(exam.examName, this.exam.examName);
    //     if (exam.examName === this.exam.examName) {
    //       message = 'Nombre del examen ya existe';
    //     }
    //   });
    // }

    if (message !== undefined) {
      this.toastrService.show(
        '',
        message,
        { position, status });
      return false;
    } else {
      return true;
    }
  }

  saveCourseExam() {
    if (this.exam.examName !== null) {
      if (this.validateExam()) {
        this.getCombosValues();
        if ((this.index !== undefined) && (this.edit === 'true')) {
          this.course.courseExam.splice(this.index, 1);
        }
        if ((this.index !== undefined) && (this.edit === 'solved')) {
          this.course.courseExam.splice(this.index, 1);
        }
        this.course.courseExamCounter = this.course.courseExam.length;
        this.updateCourse();
      }
    } else {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        'Ingrese un nombre al examen para guardar.',
        { position, status });
    }
  }

  getCombosValues() {
    if (this.course.courseExam === undefined) {
      this.course.courseExam = new Array<Exam>();
    }
    if (this.exam.examCode === undefined) {
      this.exam.examCode = uuidv4();
    }
    this.course.courseExam.push(this.exam);
  }

  updateCourse() {
    this.loading = true;
    this.courseService.updateCourseBackend(this.course)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.dialogRef.close(this.course);
          this.loading = false;
          // console.log(result);

        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
  }

  close() {
    this.dialogRef.close(this.course);
  }

  addNewQuestion() {
    this.addQuestion = true;
    this.question = {} as Question;
    this.answer = {} as Answer;
    this.examAnswerList = new LocalDataSource();
  }

  editNewQuestion(event) {
    this.editQuestion = true;
    this.question = event.data;
    this.editQuestionIndex = event.index;
    this.examAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
  }

  deleteNewQuestion(event) {
    this.deleteQuestion = true;
    this.question = event.data;
    this.editQuestionIndex = event.index;
    this.examQuestionList.remove(event.data);
    this.saveCourseExamQuestion();
  }

  cancelAddNewQuestion() {
    this.addQuestion = false;
    this.editQuestion = false;
  }

  validateExamQuestion(): boolean {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message;
    if (this.question.examQuestionAnswer === undefined) { message = 'Debe tener almenos una respuesta para guardar'; } else {
      let countCorrectAnswer = 0;
      this.question.examQuestionAnswer.forEach((answer) => {
        if (answer.examQuestionAnswerResponseIsCorrect === true) { countCorrectAnswer++; }
      });
      if (this.question.examQuestionAnswer.length < 0) { message = 'debe tener almenos una respuesta para guardar'; }
      if (countCorrectAnswer < 1) { message = 'Debe tener almenos una respuesta correcta'; }
    }
    if (this.question.examQuestion === undefined) { message = 'El titulo de la pregunta no puede ser vacio'; }

    if (this.question.examQuestion !== undefined) {
      if (this.question.examQuestion.length < 3) {
        message = 'El titulo de la pregunta es muy corto';
      }
    }

    if (message !== undefined) {
      this.toastrService.show(
        '',
        message,
        { position, status });
      return false;
    } else {
      return true;
    }
  }

  saveCourseExamQuestion() {
    if (this.validateExamQuestion()) {
      if ((this.index === undefined) && (this.edit !== 'true')) {
        this.getCombosValues();
        this.index = (this.course.courseExam !== undefined) ? this.course.courseExam.length - 1 : 0;
        this.edit = 'true';
      }

      if (this.course.courseExam[this.index].examQuestion === undefined) {
        this.course.courseExam[this.index].examQuestion = new Array<Question>();
      }
      if (this.addQuestion) {
        this.question.examQuestionCode = uuidv4();
        this.course.courseExam[this.index].examQuestion.push(this.question);
      }
      if (this.editQuestion) {
        this.course.courseExam[this.index].examQuestion.splice(this.editQuestionIndex, 1);
        this.question.examQuestionCode = uuidv4();
        this.course.courseExam[this.index].examQuestion.push(this.question);
      }
      if (this.deleteQuestion) {
        this.course.courseExam[this.index].examQuestion.splice(this.editQuestionIndex, 1);
      }
      this.examQuestionList = new LocalDataSource(this.course.courseExam[this.index].examQuestion);
      this.addQuestion = false;
      this.editQuestion = false;
    }
  }

  addNewAnswer() {
    this.addAnswer = true;
    this.answer = {} as Answer;
    this.examAnswerList = new LocalDataSource(Array<Answer>());
  }

  cancelAddNewAnswer() {
    this.addAnswer = false;
    this.editAnswer = false;
    this.examAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
  }

  editNewAnswer(event) {
    this.editAnswer = true;
    this.answer = event.data;
    this.editAnswerIndex = event.index;
  }

  deleteNewAnswer(event) {
    this.deleteAnswer = true;
    this.answer = event.data;
    this.editAnswerIndex = event.index;
    this.examAnswerList.remove(event.data);
    this.question.examQuestionAnswer.splice(this.editAnswerIndex, 1);
    this.saveCourseExamAnswer();
  }

  validateExamQuestionAnswer(): boolean {
    const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    const status: NbComponentStatus = 'danger';
    let message;
    if (this.answer.examQuestionAnswerResponse === undefined) { message = 'Respuesta no puede ser vacio'; }

    if (message !== undefined) {
      this.toastrService.show(
        '',
        message,
        { position, status });
      return false;
    } else {
      return true;
    }
  }

  saveCourseExamAnswer() {
    if (this.validateExamQuestionAnswer()) {
      if (this.question.examQuestionAnswer === undefined) {
        this.question.examQuestionAnswer = new Array<Answer>();
      }
      if (this.addAnswer) {
        this.answer.examQuestionAnswerCode = uuidv4();
        this.question.examQuestionAnswer.push(this.answer);
      }
      if (this.editAnswer) {
        this.question.examQuestionAnswer.splice(this.editAnswerIndex, 1);
        this.answer.examQuestionAnswerCode = uuidv4();
        this.question.examQuestionAnswer.push(this.answer);
      }
      // if (this.deleteAnswer) {
      //   this.question.examQuestionAnswer.splice(this.editAnswerIndex, 1);
      // }
      let countAnswers = 0;
      this.question.examQuestionAnswer.forEach(element => {
        if (element.examQuestionAnswerResponseIsCorrect === true) {
          countAnswers++;
        }
      });
      this.question.examQuestionAnswerCounter = countAnswers;
      this.examAnswerList = new LocalDataSource(this.question.examQuestionAnswer);
      this.addAnswer = false;
      this.editAnswer = false;
    }
  }

}
