<app-loading *ngIf="loading"></app-loading>
<nb-card>
    <nb-card-body>
        <h4> Mi Perfil</h4>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="email" class="label">Correo Electronico</label>
                    <input type="text" nbInput fullWidth id="email" name="email" [(ngModel)]="user.email"
                        disabled="true" placeholder="Correo Electronico" maxlength="80" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="name" class="label">Nombre</label>
                    <input type="text" nbInput fullWidth id="name" name="name" [(ngModel)]="user.name"
                        placeholder="Nombre" maxlength="80" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="userRoleName" class="label">Rol</label>
                    <input type="text" nbInput fullWidth id="userRoleName" name="userRoleName"
                        [(ngModel)]="user.userRoleName" disabled="true" placeholder="Rol" maxlength="80" required>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="theme" class="label">Tema</label>
                    <nb-select fullWidth placeholder="Tema" (selectedChange)="changeTheme($event)" id="theme"
                        name="theme" [(ngModel)]="user.theme" required maxlength="0">
                        <nb-option *ngFor="let theme of themes" [value]="theme.value">
                            {{ theme.name }}</nb-option>
                    </nb-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="picture" class="label">Avatar</label>
                    <img [src]="user.picture === user.email ? 'assets/images/avatar/avatarStudent.png' : user.picture"
                        style="cursor: pointer;" *ngIf="user.picture" class="profileImg" (click)="addUserImage()">
                </div>
            </div>
        </div>
        <button nbButton (click)="saveUser()" nbTooltip="Guardar" ghost style="color: #0A7E12 !important;">
            Guardar
        </button>
        <button nbButton nbTooltip="Cancelar" (click)="cancel()" ghost style="color: #C81016 !important;">
            Cancelar
        </button>
    </nb-card-body>
</nb-card>