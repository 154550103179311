import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';


@Injectable()
export class NewCourseNotificationSES {
  AWS = require('aws-sdk');

  constructor() { }

  sendEmail(userName, userEmail, subdomain) {
    this.AWS.config.update({
      accessKeyId: environment.accessKeyId,
      secretAccessKey: environment.secretAccessKey,
      region: environment.regionSES
    });

    const ses = new this.AWS.SES({ apiVersion: '2010-12-01' });
    subdomain = subdomain === 'myapptraining' ? '' : subdomain + '.';
    const params = {
      Destination: {
        ToAddresses: [userEmail]
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data:
              `<html>
                <body>
                  <img src=` + environment.emailImage + ` style="width: 22rem;">
                    <h2>Hola ` + userName +
              ` un nuevo curso te ha sido asignado, porfavor ingresa a <a href=` +
              'https://' + subdomain + environment.emailSES.replace('https://', '') +
              `> mTraining</a> para iniciarlo.</h2>
                </body>
              </html>
              `
          },
          Text: {
            Charset: 'UTF-8',
            Data: 'Nuevo curso asignado.'
          }
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Nuevo curso asignado.'
        }
      },
      Source: 'mtraining@myappsoftware.com'
    };

    const sendEmail = ses.sendEmail(params).promise();

    sendEmail
      .then(data => {
      })
      .catch(error => {
        // console.error(error);
      });

  }
}
