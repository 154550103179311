<nb-card size="giant" accent="warning">
  <nb-card-header class="row">
    <nb-card-body class="row control-item">
      <app-new-course-tools (courseListResult)="courseListReceiver($event)"></app-new-course-tools>
    </nb-card-body>
  </nb-card-header>
  <nb-card-body>
    <app-new-course-table [courseList]="courseList" (courseListResult)="courseListReceiver($event)">
    </app-new-course-table>
  </nb-card-body>
</nb-card>