import { ExamResultComponent } from './exam-result/exam-result.component';
import { Component, OnInit, Input, Output } from '@angular/core';
import { Exam, Question } from 'src/app/models/exam';
import {
  NbDialogRef, NbDialogService,
  NbGlobalPosition, NbGlobalPhysicalPosition,
  NbComponentStatus, NbToastrService
} from '@nebular/theme';
import { UserService } from 'src/app/services/user.service';
import { UserCourse, UserCourseCourseExamResult, UserCourseCourses } from 'src/app/models/userCourse';
import { Course } from 'src/app/models/course';

@Component({
  selector: 'app-course-exam-viewer',
  templateUrl: './course-exam-viewer.component.html',
  styleUrls: ['./course-exam-viewer.component.scss']
})
export class CourseExamViewerComponent implements OnInit {
  @Input() course = {} as Course;
  @Input() exam = {} as Exam;

  userQuestions = new Array<Question>();
  examQuestions = new Array<Question>();
  loading;

  enableEdit = false;

  constructor(
    private dialogRef: NbDialogRef<CourseExamViewerComponent>,
    private dialogService: NbDialogService,
    private userService: UserService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
    this.examQuestions = this.exam.examQuestion;
    this.userQuestions = this.exam.examQuestion;
    this.exam.examQuestion.forEach((question, questionIndex) => {
      question.examQuestionAnswer.forEach((answer, answerIndex) => {
        if (answer.examQuestionAnswerResponseIsCorrect === true) {
          question.examQuestionAnswerOne = answer.examQuestionAnswerResponse;
        }
      });
    });
    localStorage.setItem('exam', JSON.stringify(this.exam));
    this.userQuestions.forEach((question, questionIndex) => {
      question.examQuestionAnswerOne = undefined;
      question.examQuestionAnswer.forEach((answer, answerIndex) => {
        answer.examQuestionAnswerResponseIsCorrect = false;
      });
    });

  }

  timesUp(event) {
    if (event.action === 'done') {
      this.enableEdit = true;
      this.checkExam();
    }
  }

  checkExam() {
    let countQuestion = 0;
    let countCorrectAnswer = 0;
    const e: Exam = JSON.parse(localStorage.getItem('exam'));
    e.examQuestion.forEach((question, questionIndex) => {
      countQuestion++;
      if (question.examQuestionAnswerCounter === 1) {
        if (question.examQuestionAnswerOne === this.userQuestions[questionIndex].examQuestionAnswerOne) {
          countCorrectAnswer++;
        }
      }
      if (question.examQuestionAnswerCounter > 1) {
        let countAnswer = 0;
        let countAnswerCorrect = 0;
        question.examQuestionAnswer.forEach((answer, answerIndex) => {
          countAnswer++;

          if ((answer.examQuestionAnswerResponseIsCorrect ? answer.examQuestionAnswerResponseIsCorrect : false) ===
            this.userQuestions[questionIndex].examQuestionAnswer[answerIndex].examQuestionAnswerResponseIsCorrect) {
            countAnswerCorrect++;
          }
        });
        if (countAnswer === countAnswerCorrect) {
          countCorrectAnswer++;
        }
      }
    }
    );
    localStorage.removeItem('exam');
    let userCourse: UserCourse;
    if (localStorage.getItem('userCourse')) {
      const uc: UserCourse = JSON.parse(localStorage.getItem('userCourse'));
      userCourse = {
        userCourseCode: uc.userCourseCode,
        userCourses: uc.userCourses,
      };

    }
    if (userCourse.userCourses !== undefined) {
      let indexSelected = -1;
      userCourse.userCourses.forEach((userCourses, index) => {
        if (userCourses.courseCode === this.course.courseCode) {
          indexSelected = index;
        }
      });
      if (indexSelected !== -1) {

        if (userCourse.userCourses[indexSelected].courseExamResults === undefined) {
          userCourse.userCourses[indexSelected].courseExamResults = new Array<UserCourseCourseExamResult>();
        }
        const userResult = {
          examCode: this.exam.examCode,
          examName: this.exam.examName,
          examScore: countCorrectAnswer,
          examScoreOver: countQuestion,
          examDate: new Date(Date.now()),
          examResultUser: this.userQuestions,
          examOverUser: this.examQuestions,
        }
        const userResultArr: UserCourseCourseExamResult[] = userCourse.userCourses[indexSelected].courseExamResults;
        userResultArr.push(userResult);
        const userCourseN: UserCourseCourses = {
          courseCode: userCourse.userCourses[indexSelected].courseCode,
          courseDescription: userCourse.userCourses[indexSelected].courseDescription,
          courseImage: userCourse.userCourses[indexSelected].courseImage,
          courseFlippedStatus: false,
          courseCertified: undefined,
          examCertified: undefined,
          courseVideo: userCourse.userCourses[indexSelected].courseVideo,
          courseName: userCourse.userCourses[indexSelected].courseName,
          courseStatus: userCourse.userCourses[indexSelected].courseStatus,
          courseExamResults: userResultArr,
          courseScormSelected: userCourse.userCourses[indexSelected].courseScormSelected,
          userCourseEnable: true
        };
        userCourse.userCourses.splice(indexSelected, 1);
        userCourse.userCourses.push(userCourseN);
      } else {
        const userResult = {
          examCode: this.exam.examCode,
          examName: this.exam.examName,
          examScore: countCorrectAnswer,
          examScoreOver: countQuestion,
          examDate: new Date(Date.now()),
          examResultUser: this.userQuestions,
          examOverUser: this.examQuestions,
          examCertified: false
        }
        const userResultArr: UserCourseCourseExamResult[] = new Array<UserCourseCourseExamResult>();
        userResultArr.push(userResult);
        const userCourseN: UserCourseCourses = {
          courseCode: this.course.courseCode,
          courseDescription: this.course.courseDescription,
          courseImage: this.course.courseImage,
          courseFlippedStatus: false,
          courseCertified: undefined,
          courseVideo: undefined,
          courseName: this.course.courseName,
          courseStatus: undefined,
          examCertified: undefined,
          courseExamResults: userResultArr,
          courseScormSelected: this.course.courseScormSelected,
          userCourseEnable: true
        };
        userCourse.userCourses.push(userCourseN);

      }

    }
    localStorage.setItem('userCourse', JSON.stringify(userCourse));
    this.updateUserCourse(userCourse);
    this.dialogService.open(ExamResultComponent, {
      context: {
        courseName: this.course.courseName,
        examResult: countCorrectAnswer,
        examResultOf: countQuestion,
        examCountQuestions: this.exam.examQuestion.length,
        examType: this.exam.examFinal,
      },
      hasBackdrop: true,
      closeOnEsc: false,
      closeOnBackdropClick: false
    });
    this.dialogRef.close();
  }

  updateUserCourse(userCourse) {
    this.loading = true;
    this.userService.updateUserCourseBackend(userCourse)
      .then(
        (result) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'success';
          this.toastrService.show('', 'Guardado', { position, status });
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err,
            { position, status });
          this.loading = false;
        }
      );
  }

}
