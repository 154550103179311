import { Component, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbGlobalPosition, NbThemeService,
  NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus
} from '@nebular/theme';
import { UserMyProfileImageComponent } from '../user-my-profile-image/user-my-profile-image.component';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { Router } from '@angular/router';
import userRole from '../../../../assets/data/userRole/userRole.json';

@Component({
  selector: 'app-user-my-profile',
  templateUrl: './user-my-profile.component.html',
  styleUrls: ['./user-my-profile.component.scss']
})
export class UserMyProfileComponent implements OnInit {
  user = {} as User;



  userRoleList = userRole;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    // {
    //   value: 'dark',
    //   name: 'Dark',
    // },
    // {
    //   value: 'cosmic',
    //   name: 'Cosmic',
    // },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];
  loading: boolean;

  constructor(
    private dialogService: NbDialogService,
    private authService: AuthService,
    private companyService: CompanyService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loading = true;
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.authService.getUser(userLogged.userId).then((result: any) => {
      const userBackend = result.UserAttributes;
      const sub = userBackend.find((x) => x.Name === 'sub');
      const username = userBackend.find((x) => x.Name === 'email');
      const email = userBackend.find((x) => x.Name === 'email');
      const name = userBackend.find((x) => x.Name === 'name');
      const picture = userBackend.find((x) => x.Name === 'picture');
      const userRole = userBackend.find((x) => x.Name === 'custom:role');
      const theme = userBackend.find((x) => x.Name === 'custom:theme');
      const email_verified = userBackend.find((x) => x.Name === 'email_verified');
      const companyCode = userBackend.find((x) => x.Name === 'custom:company');
      let rol = '';
      switch (userRole.Value) {
        case 'editor':
          rol = 'Editor';
          break;
        case 'user_administrator':
          rol = 'Administrator de Usuarios';
          break;
        case 'user':
          rol = 'Usuario';
          break;
        case 'administrator':
          rol = 'Administrador';
          break;
        default:
          break;
      }
      this.companyService.oneCompanyBackend(companyCode.Value).then(
        (resultCompany: any) => {
          const company = resultCompany.body;
          this.user = {
            userId: sub.Value,
            userRole: userRole.Value,
            userRoleName: rol,
            username: email.Value,
            email: email.Value,
            name: name.Value,
            theme: theme.Value,
            picture: picture.Value,
            userStatus: email_verified.Value,
            password: undefined,
            company: {
              companyCode: companyCode.Value,
              companyName: company.companyName
            }
          } as User;
          this.loading = false;
        }
      ).catch(
        (err: any) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );

    }).catch((err) => {
      const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status: NbComponentStatus = 'danger';
      this.toastrService.show(
        '',
        err.message,
        { position, status });
      this.loading = false;
    });
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  addUserImage() {
    this.dialogService.open(UserMyProfileImageComponent,
      {
        context: {
          user: this.user
        },
        hasBackdrop: true,
        closeOnEsc: false,
        closeOnBackdropClick: false
      }).onClose
      .subscribe(
        (user) => {
          this.user = user;
        });
  }

  saveUser() {
    this.authService.updateUser(this.user).then(
      (result) => {
        localStorage.setItem('user', JSON.stringify(this.user));
        this.authService.userProfileChanged.next(true);
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        this.toastrService.show('', 'Guardado', { position, status });
        this.loading = false;
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  cancel() {
    this.router.navigateByUrl('/home');
  }

}
