import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit, OnChanges {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  @Input() dataSourse;
  @Input() reportName: string;
  listUserCourse;

  constructor() {
  }

  ngOnChanges() {
  }

  ngOnInit() {
   }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, this.reportName);
    xlsx.writeFile(wb, this.reportName + '.xlsx');
  }

}
