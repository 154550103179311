import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-admin-user',
  templateUrl: './welcome-admin-user.component.html',
  styleUrls: ['./welcome-admin-user.component.scss']
})
export class WelcomeAdminUserComponent implements OnInit {
userName;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
      this.userName = user.name;
    }
  }


  mycourses(){
    this.router.navigateByUrl('/myCourse');
  }

  courses(){
    this.router.navigateByUrl('/course');
  }

  addUser() {
      this.router.navigateByUrl('/newUser');
  }

}
