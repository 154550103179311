import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  CanDeactivate
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../models/user';
import { NbDialogService } from '@nebular/theme';

@Injectable()
export class AuthGuard implements CanActivate {
  isLogged: boolean;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let result;
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.authService.isAuthenticated().
      subscribe((res) => {
        if (res) {
          if (state.url === '/login') {
            result = false;
          } else {
            result = true;
          }
        } else {
          if (state.url === '/login') {
            result = true;
          } else {
            result = false;
          }
        }
      },
        (err) => {
          // console.error(err);
        }
      );
    if (result === undefined) {
      this.takeOutApp();
    }
    if (user === undefined) {
      this.takeOutApp();
    }
    if (user.userRole !== undefined) {
      switch (user.userRole.toString()) {
        case 'user':
          if (state.url === '/newCourse') { this.takeOutApp(); }
          if (state.url === '/newUser') { this.takeOutApp(); }
          if (state.url === '/company') { this.takeOutApp(); }
          if (state.url === '/course') { this.takeOutApp(); }
          if (state.url === '/ReportUser') { this.takeOutApp(); }
          if (state.url === '/ReportUserResult') { this.takeOutApp(); }
          if (state.url === '/ReportCourseAvg') { this.takeOutApp(); }
          break;
        case 'user_administrator':
          if (state.url === '/newCourse') { this.takeOutApp(); }
          if (state.url === '/company') { this.takeOutApp(); }
          // if (state.url === '/course') { this.takeOutApp(); }
          if (state.url === '/ReportUser') { this.takeOutApp(); }
          if (state.url === '/ReportUserResult') { this.takeOutApp(); }
          if (state.url === '/ReportCourseAvg') { this.takeOutApp(); }
          break;
        case 'editor':
          if (state.url === '/newUser') { this.takeOutApp(); }
          if (state.url === '/company') { this.takeOutApp(); }
          if (state.url === '/ReportUser') { this.takeOutApp(); }
          if (state.url === '/ReportUserResult') { this.takeOutApp(); }
          if (state.url === '/ReportCourseAvg') { this.takeOutApp(); }
          break;

        default:
          break;
      }
    }
    return result;
  }


  takeOutApp() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
