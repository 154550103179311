<app-loading *ngIf="loading"></app-loading>
<nb-card>
    <nb-card-header>
        <h1>Reporte de Usuarios</h1>
    </nb-card-header>
    <nb-card-body>
        <table style="margin-left: 1rem;">
            <thead>
                <tr>
                    <th colspan="2" style="text-align: center;">
                        Resumen
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>Administradores de Usuarios</th>
                    <td>{{counterAdminU}}</td>
                </tr>
                <tr>
                    <th>Editores</th>
                    <td>{{counterEditor}}</td>
                </tr>
                <tr>
                    <th>Usuarios</th>
                    <td>{{counterUser}}</td>
                </tr>
                <tr>
                    <th>Almacenamiento</th>
                    <td>{{counterStorage / 1000000000 | number : '1.5-5'}} GB</td>
                </tr>
            </tbody>
        </table>
        <app-report-viewer [dataSourse]="dataSourse" [reportName]="reportName"></app-report-viewer>
    </nb-card-body>
</nb-card>
