import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../../../services/course.service';
import { debounceTime, tap } from 'rxjs/operators';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService, NbSearchService } from '@nebular/theme';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-new-course-tools',
  templateUrl: './new-course-tools.component.html',
  styleUrls: ['./new-course-tools.component.scss']
})
export class NewCourseToolsComponent implements OnInit {
  loading = false;

  courseList: any[];
  @Output() courseListResult = new EventEmitter<any[]>();


  constructor(
    private searchService: NbSearchService,
    private router: Router,
    private courseService: CourseService,
    private toastrService: NbToastrService) {
    this.searchService.onSearchInput().subscribe((data: any) => {
      this.onSearchKeyPress(data.term);
    });
  }

  ngOnInit() {
    this.listAllCourse();
  }

  addNewCourse() {
    this.router.navigate(['/courseFields']);
  }

  onSearchKeyPress(searchValue: string) {
    this.loading = true;
    this.courseList = [];
    if (searchValue.length >= 3) {
      this.courseService
        .searchCourseBackend(searchValue)
        .then(
          (result: any) => {
            this.courseList = result.body;
            this.courseListResult.emit(this.courseList);
            this.loading = false;
          }).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                status || 'Danger',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
    }
  }

  listAllCourse() {
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const userCompany = userLogged.company.companyCode.toString();
    this.courseService.allCourseCompanyBackend(userCompany)
      .then(
        (result) => {
          this.courseList = result.body;
          this.courseListResult.emit(this.courseList);
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );

  }
}
