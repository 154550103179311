import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../app/services/auth.service';
import { NbDialogRef, NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { SesService } from 'src/app/services/sesService';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/services/company.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class UserConfirmationComponent implements OnInit {
  userName: string;
  validationCode: string;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  company = {} as any;

  constructor(
    private authService: AuthService,
    private userCourseService: UserService,
    private sesService: SesService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<UserConfirmationComponent>) { }

  ngOnInit() {
  }


  onConfirmSubmit() {
    const companyLogo = window.location.hostname;
    const usrName = this.userName.trim();
    const subdomain = companyLogo.split('.')[0];
    const validationCode = this.validationCode.trim();
    let userId = '';
    let password = '';
    this.authService.confirmUser(usrName, validationCode).then(
      (result) => {
        this.authService.getUsers().then(
          (resultUsers: any) => {
            const listUsers = resultUsers.Users;
            listUsers.forEach(user => {
              const sub = user.Attributes.find((x) => x.Name === 'sub');
              const email = user.Attributes.find((x) => x.Name === 'email');
              if (usrName === email.Value) {
                userId = sub.Value;
              }
            });
            this.userCourseService.oneUserCourseBackendNoLog(userId).then(
              (resultUsersCourse: any) => {
                const userCourse = resultUsersCourse.body;
                password = userCourse.firstPassword;
                let request = {
                  userName: usrName,
                  userEmail: usrName,
                  userCompanyURL: 'https://' + subdomain + environment.emailSES.replace('https://' , ''),
                  userPassword: password,
                };
                this.sesService.sendEmail(request);
                const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status: NbComponentStatus = 'success';
                this.toastrService.show('', 'Usuario validado', { position, status });
                this.dialogRef.close();
              }
            ).catch((err) => {
              // console.error(err);
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err,
                { position, status });
              this.dialogRef.close();
            });
          }
        ).catch((err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err,
            { position, status });
          this.dialogRef.close();
        });

      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          'Usuario o código incorrecto.',
          { position, status });
        this.dialogRef.close();
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
