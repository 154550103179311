<nb-card style="width: 50rem !important; height: 40rem !important;">
  <nb-card-header>
    <span>Control multiples usuarios</span>
  </nb-card-header>
  <nb-card-body>
    <nb-tabset fullWidth>
      <nb-tab tabTitle="Desactivar" tabIcon="person-delete-outline" responsive>
        <ng2-smart-table [settings]="settings" [source]="userListActive" (userRowSelect)="onUserRowSelect($event)"></ng2-smart-table>
        <div class="row">
          <div class="col-md-6" style="text-align: center !important;">
              <button nbButton ghost style="color: #0A7E12 !important;" (click)="deleteUser(this.event)">Desactivar</button>
          </div>
          <div class="col-md-6" style="text-align: center !important;">
              <button nbButton ghost style="color: #C81016 !important;"
              (click)="cancelUnassignUser()">Cancelar</button>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Activar" tabIcon="person-done-outline" responsive>
        <ng2-smart-table [settings]="settings" [source]="userListInactive" (userRowSelect)="onUserRowSelect($event)"></ng2-smart-table>
        <div class="row">
          <div class="col-md-6" style="text-align: center !important;">
              <button nbButton ghost style="color: #0A7E12 !important;" (click)="activateUser(this.event)">Activar</button>
          </div>
          <div class="col-md-6" style="text-align: center !important;">
              <button nbButton ghost style="color: #C81016 !important;"
              (click)="cancelUnassignUser()">Cancelar</button>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </nb-card-body>
</nb-card>
