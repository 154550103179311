<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <div>
      <nb-card style="height: 20rem !important; border-radius: 30px !important;">
        <nb-card-header
          style="text-align: center; padding: 0.5rem; background-color: #1378DE !important; border-top-right-radius: 30px;border-top-left-radius: 30px;">
          <h4 style="color: white !important;">Editar usuario: {{user.name}}</h4>
        </nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 1rem !important;">
          <form>
            <!-- <div class="form-group" style="display: flex;">
              <div>
                <input type="text" fieldSize="medium" style="text-align: center; color: black;" name="userName" id="userName" [(ngModel)]="user.name" nbInput disabled>
              </div>
            </div> -->
            <div class="form-group" style="display: flex; margin-bottom: 1rem;">
              <div>
                <span style="margin-top: 0.6rem; margin-right: 1rem;">Rol actual</span>
                <input type="text" fieldSize="medium" style="text-align: center; color: black;" nbInput name="userActualRole" id="userActualRole" [(ngModel)]="user.userRoleName" disabled>
              </div>
              <div>
                <span style="margin-top: 0.6rem; margin-right: 1rem;">Departamento actual</span>
                <input type="text" fieldSize="medium" style="text-align: center; color: black;" name="userActualDepartment" id="userActualDepartment" [(ngModel)]="user.department" nbInput disabled>
              </div>
            </div>
            <div class="form-group ">
              <div style="padding-bottom: 3px !important; ">
                <nb-select placeholder="Seleccione Rol" fullWidth id="userRole" name="userRole" [(ngModel)]="userNewRol"
                required maxlength="0" nbTooltip="Editor: encargado de crear contenido, como los cursos sus exámenes.  Administrador de usuarios: es el encargado de crear usuarios y administrar los mismos, dándole de bajé o alta a cada uno, asignación de cursos. Usuario: es el alumno el cual solo puede ver los cursos y tomarlos.">
                  <nb-option [value]="userRole" *ngFor="let userRole of userRoleList">{{userRole.type}}</nb-option>
                </nb-select>
              </div>
              <div>
                <div class="form-group">
                  <nb-select placeholder="Seleccione Departmento" fullWidth id="department" name="department"
                    [(ngModel)]="userNewDepartment" required maxlength="0">
                    <nb-option [value]="department" *ngFor="let department of departmentList">{{department}}
                    </nb-option>
                  </nb-select>
                </div>
              </div>
            </div>
            <div class="form-group " style="text-align: -webkit-center;">
              <div style="margin-top: 5px; display: flex; justify-content: space-evenly;">
                <button type="submit" nbButton ghost style="margin-right: 14rem; color: #0A7E12 !important;" (click)="saveUser()" *ngIf="userNewRol || userNewDepartment">Confirmar</button>
                <button type="submit" nbButton ghost style="margin-left: 5px; color: rgb(200, 99, 16) !important;" (click)="resendUserCode()">Reenviar código</button>
                <button type="submit" nbButton ghost style="margin-left: 5px; color: #C81016 !important;" (click)="close()">Regresar</button>
              </div>
            </div>
          </form>
        </nb-card-body>
      </nb-card>
