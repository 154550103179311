import { Component, OnInit } from '@angular/core';
import { Course } from 'src/app/models/course';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { UserCourse, UserCourseCourses } from 'src/app/models/userCourse';

@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  styleUrls: ['./my-course.component.scss']
})
export class MyCourseComponent implements OnInit {

  loading = false;
  courseList: UserCourseCourses[];
  courseListLocal: UserCourseCourses[];
  courseProgress = 0;
  courseProgressTooltip;

  constructor(
    private router: Router,
    private userService: UserService,
    private courseService: CourseService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
    this.listAllcourse();
  }

  toggleView(course) {
    if (course.courseFlippedStatus === undefined) {
      course.courseFlippedStatus = false;
    } else if (course.courseFlippedStatus === false) {
      course.courseFlippedStatus = true;
    } else {
      course.courseFlippedStatus = false;
    }
  }

  viewCourse(courseCode, courseScormSelected = false) {
    if (courseScormSelected === true) {
      this.router.navigate(['/courseScormPlayer/' + courseCode]);
    } else {
      this.router.navigate(['/courseVideoViewer/' + courseCode]);
    }
  }

  listAllcourse() {
    this.loading = true;
    let user = {} as User;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    let userCourse = {} as UserCourse;
    if (localStorage.getItem('userCourse')  !== null) {
      userCourse = JSON.parse(localStorage.getItem('userCourse'));
      this.userService.oneUserCourseBackend(user.userId).then(
        (result) => {
          userCourse = result.body !== undefined ? result.body : {userCourses: undefined};
          localStorage.setItem('userCourse', JSON.stringify(userCourse));
          this.courseListLocal = new Array<UserCourseCourses>();
          this.courseList = this.courseListLocal;
          if (userCourse.userCourses !== undefined) {
            this.courseListLocal = userCourse.userCourses;
            this.listAllcourseBackend();
          } else {
            const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status: NbComponentStatus = 'info';
            this.toastrService.show(
              'Info',
              'No tiene cursos asignados, Actualice para refrescar los cursos',
              { position, status });
          }
          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    }
    this.loading = false;
  }

  listAllcourseBackend() {
    let counterVideo = 0;
    this.loading = true;
    const userLogged: User = JSON.parse(localStorage.getItem('user'));
    const userCompany = userLogged.company.companyCode.toString();
    this.courseService.allCourseCompanyBackend(userCompany)
      .then(
        (result) => {
          const courseListBackend: Course[] = result.body;
          courseListBackend.forEach(courseBackend => {
            courseBackend.courseFlippedStatus = false;
            if (this.courseListLocal !== undefined) {
              this.courseListLocal.forEach((course, index) => {
                if (course.courseCode === courseBackend.courseCode) {
                  course.courseDescription = courseBackend.courseDescription;
                  course.courseName = courseBackend.courseName;
                  course.courseImage = courseBackend.courseImage;
                  if (course.courseVideo !== undefined) {
                    course.courseVideo.forEach(video => {
                      if (video.videoCompleted !== undefined) {
                        if (video.videoCompleted === true ) {
                          counterVideo++;
                          this.courseProgress = (counterVideo * 100) / course.courseVideo.length;
                          this.courseProgressTooltip = 'Progreso: ' + this.courseProgress + '%';
                        }
                      }
                    });
                  } else if (course.courseVideo === undefined) {

                  }
                  if (courseBackend.deleteStatus === 'deleted') {
                    this.courseListLocal.splice(index, 1);
                  }
                }
              });
              this.courseList = this.courseListLocal;
              counterVideo = 0;
            }
          });
          this.courseList.forEach((course, index) => {
            const courseBk = courseListBackend.find((x) => x.courseCode === course.courseCode);
            if (courseBk === undefined) {
              this.courseList.splice(index, 1);
            }

          });

          this.loading = false;
        }
      ).catch(
        (err) => {
          const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status: NbComponentStatus = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );

  }

  get status() {
    if (this.courseProgress <= 25) {
      return 'danger';
    } else if (this.courseProgress <= 50) {
      return 'warning';
    } else if (this.courseProgress <= 75) {
      return 'info';
    } else {
      return 'success';
    }
  }

}
