<app-loading *ngIf="loading"></app-loading>
<div class="card-col">
    <nb-card style="height: 20rem !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;">
            <h4 style="color: white !important;">Imagen de perfil</h4>
        </nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 2rem !important;">
            <form class="form-inline" style="text-align: center">
                <div id="upload_button">
                    <label>
                        <input type="file" nbInput readonly fieldSize="giant" (change)="imageEvent($event)"
                            accept="image/x-png,image/gif,image/jpeg">
                        <span class="btn btn-primary" nbButton outline
                            style="color: #8d8d8d; background-color: white; padding: 0.6875rem 1.125rem; border-radius: 0.25rem;">BUSCAR
                            IMAGEN</span>
                        <span></span>
                    </label>
                </div>
                <span style="text-align: center; width: 100%; display: block; margin-top: 1rem;">{{fileNameShow}}</span>
            </form>
            <button type="button" nbButton ghost
                style="margin-right: 17rem; color: #0A7E12 !important; vertical-align: baseline; margin-top: 1rem; margin-right: 18rem; margin-bottom: 1rem;"
                (click)="upload()" [disabled]="!selectedFiles">Subir</button>
            <button type="button" nbButton ghost
                style="margin-left: 5px; color: #C81016 !important; vertical-align: baseline"
                (click)="close()">Cancelar</button>
            <app-progress-bar [progress]="progress"></app-progress-bar>
        </nb-card-body>
    </nb-card>
</div>