import { UserResetPasswordComponent } from './../user-reset-password/user-reset-password.component';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-user-fotgot-password-view',
  templateUrl: './user-fotgot-password-view.component.html',
  styleUrls: ['./user-fotgot-password-view.component.scss']
})
export class UserFotgotPasswordViewComponent implements OnInit {
  verificationCode: string;
  newPassword: string;

  constructor(private dialogRef: NbDialogRef<UserFotgotPasswordViewComponent>) { }

  ngOnInit() {
  }

  closeWithData() {
    const reset = {
      verificationCode: this.verificationCode.trim(),
      newPassword: this.newPassword.trim(),
    };
    this.dialogRef.close(reset);
  }

  close() {
    this.dialogRef.close();
  }

}
