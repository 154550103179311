<app-loading *ngIf="loading"></app-loading>
<nb-card>
    <nb-card-header style="height: 1.5rem; padding-top: 0.15rem; padding-bottom: 0.1rem;">
        <button type="button" nbButton ghost style="color: #C81016 !important; vertical-align: baseline; float: right; padding: 0;"
        (click)="close()" ><nb-icon icon="close-outline"></nb-icon></button>
    </nb-card-header>
    <nb-card-body size="large">
      <nb-tabset fullWidth>
        <nb-tab tabTitle="Parámetros" tabIcon="person-outline" responsive fullWidth>
            <nb-alert accent="basic" style="text-align: center;">Llene los siguientes campos para continuar.</nb-alert>
            <nb-alert status="danger" *ngIf="exam.examTime <= 0">El tiempo del examen debe ser mayor a 1 minuto</nb-alert>
            <p><strong>Parámetros</strong></p>
            <div class="row">
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="examName" class="label">Nombre de examen</label><span style="color:red;">*</span>
                        <input type="text" nbInput fullWidth id="examName" name="examName" [(ngModel)]="exam.examName"
                            placeholder="Nombre Examen" [disabled]="edit === 'solved'" maxlength="40" required autocomplete="off">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="examTime" class="label">Tiempo de examen (Minutos)</label><span
                            style="color:red;">*</span>
                        <input type="number" nbInput fullWidth id="examTime" name="examTime" [(ngModel)]="exam.examTime"
                            placeholder="Tiempo Examen" [disabled]="edit === 'solved'" maxlength="6" min="1" max="300"
                            required autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="col-md-5 row2">
                <div class="row">
                    <label for="examFinal" class="label">Es examen final?</label>
                    <nb-checkbox class="check" [disabled]="enableEdit" [(ngModel)]="exam.examFinal">
                    </nb-checkbox>
                </div>
                <div class="row rowT" *ngIf="exam.examFinal">
                    <label class="label">Certificado de aprobación</label>
                    <nb-checkbox class="check" [(ngModel)]="exam.examFinalCertified"></nb-checkbox>
                </div>
                <div class="row rowT">
                    <label for="examStatus" class="label label2">Inactivo</label>
                    <nb-toggle class="toggle" id="examStatus" name="examStatus" [(ngModel)]="exam.examStatus"
                        [(checked)]="exam.examStatus"></nb-toggle>
                    <label for="examStatus" class="label label3">Activo</label>
                </div>
            </div>
        </nb-tab>
        <nb-tab tabTitle="Preguntas" tabIcon="bell-outline" *ngIf="exam.examName && exam.examTime" responsive fullWidth>
        <nb-alert accent="basic" style="text-align: center;" fullWidth>Agregue preguntas al examen para poder continuar.</nb-alert>
          <p><strong>Preguntas
                <button nbButton ghost nbTooltip="Crear Pregunta" [disabled]="edit === 'solved'"
                *ngIf="!editQuestion && !addQuestion" (click)="addNewQuestion()">
                <nb-icon icon="plus-outline"></nb-icon>
                </button>
          </strong></p>
                <ng2-smart-table *ngIf="!editQuestion && !addQuestion" [settings]="settingsQuestion" [source]="examQuestionList"
                (edit)="editNewQuestion($event)" (delete)="deleteNewQuestion($event)" style="text-align: center">
            </ng2-smart-table>
            <div *ngIf="editQuestion || addQuestion">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="examQuestion" class="label">Pregunta</label><span style="color:red;">*</span>
                            <input type="text" nbInput fullWidth id="examQuestion" name="examQuestion"
                                [(ngModel)]="question.examQuestion" placeholder="Pregunta" required autocomplete="off">
                        </div>
                    </div>
                </div>
                <h6>Respuesta
                    <button nbButton ghost nbTooltip="Crear Respuesta" (click)="addNewAnswer()"
                        *ngIf="!editAnswer && !addAnswer">
                        <nb-icon icon="plus-outline"></nb-icon>
                    </button>
                </h6>
                <ng2-smart-table *ngIf="!editAnswer && !addAnswer" [settings]="settingsAnswer" [source]="examAnswerList"
                    (edit)="editNewAnswer($event)" (delete)="deleteNewAnswer($event)" style="text-align: center">
                </ng2-smart-table>
                <div *ngIf="editAnswer || addAnswer">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="examAnswer" class="label">Respuesta</label><span style="color:red;">*</span>
                                <input type="text" nbInput fullWidth id="examAnswer" name="examAnswer"
                                    [(ngModel)]="answer.examQuestionAnswerResponse" placeholder="Respuesta" required autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="examAnswerType" class="label">¿Es la respuesta correcta?</label><span
                                    style="color:red;">*</span><br>
                                <nb-checkbox fullWidth id="examAnswerType" name="examAnswerType"
                                    [(ngModel)]="answer.examQuestionAnswerResponseIsCorrect" required>
                                </nb-checkbox>
                            </div>
                        </div>
                    </div>
                    <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                        (click)="saveCourseExamAnswer()">Guardar respuesta</button>
                    <button type="button" nbButton status="basic" ghost
                        style="color: #C81016 !important; vertical-align: baseline;"
                        (click)="cancelAddNewAnswer()">Cancelar</button>
                </div>
                <div *ngIf="!editAnswer && !addAnswer">
                    <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                        (click)="saveCourseExamQuestion()">Guardar pregunta</button>
                </div>
            </div>
        </nb-tab>
        <nb-tab tabTitle="Guardar" tabIcon="email-outline" style="text-align: center;" *ngIf="exam.examName && exam.examTime && question.examQuestion && answer.examQuestionAnswerResponse && exam.examQuestion" responsive fullWidth>
          <p><strong>Guardar examen</strong></p>
          <div *ngIf="!editQuestion && !addQuestion">
            <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                (click)="saveCourseExam()">Guardar</button>
            <button type="button" nbButton ghost style="color: #C81016 !important; vertical-align: baseline;"
                (click)="close()">Cancelar</button>
        </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
</nb-card>

<!-- <nb-card>
    <nb-card-body size="large">
        <nb-alert status="danger" *ngIf="exam.examTime <= 0">El tiempo del examen debe ser mayor a 1 minuto</nb-alert>
        <h4>Examen</h4>
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="examName" class="label">Nombre de examen</label><span style="color:red;">*</span>
                    <input type="text" nbInput fullWidth id="examName" name="examName" [(ngModel)]="exam.examName"
                        placeholder="Nombre Examen" [disabled]="edit === 'solved'" maxlength="40" required autocomplete="off">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="examTime" class="label">Tiempo de examen (Minutos)</label><span
                        style="color:red;">*</span>
                    <input type="number" nbInput fullWidth id="examTime" name="examTime" [(ngModel)]="exam.examTime"
                        placeholder="Tiempo Examen" [disabled]="edit === 'solved'" maxlength="6" min="1" max="300"
                        required autocomplete="off">
                </div>
            </div>
            <div class="col-md-3 row2">
                <div class="row">
                    <label for="examFinal" class="label">Es examen final?</label>
                    <nb-checkbox class="check" [disabled]="enableEdit" [(ngModel)]="exam.examFinal">
                    </nb-checkbox>
                </div>
                <div class="row rowT">
                    <label for="examStatus" class="label label2">Inactivo</label>
                    <nb-toggle class="toggle" id="examStatus" name="examStatus" [(ngModel)]="exam.examStatus"
                        [(checked)]="exam.examStatus"></nb-toggle>
                    <label for="examStatus" class="label label3">Activo</label>
                </div>
            </div>
        </div>
        <h6>Preguntas
            <button nbButton ghost nbTooltip="Crear Pregunta" [disabled]="edit === 'solved'"
                *ngIf="!editQuestion && !addQuestion" (click)="addNewQuestion()">
                <nb-icon icon="plus-outline"></nb-icon>
            </button>
        </h6>
        <ng2-smart-table *ngIf="!editQuestion && !addQuestion" [settings]="settingsQuestion" [source]="examQuestionList"
            (edit)="editNewQuestion($event)" (delete)="deleteNewQuestion($event)" style="text-align: center">
        </ng2-smart-table>
        <div *ngIf="editQuestion || addQuestion">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="examQuestion" class="label">Pregunta</label><span style="color:red;">*</span>
                        <input type="text" nbInput fullWidth id="examQuestion" name="examQuestion"
                            [(ngModel)]="question.examQuestion" placeholder="Pregunta" required autocomplete="off">
                    </div>
                </div>
            </div>
            <h6>Respuesta
                <button nbButton ghost nbTooltip="Crear Respuesta" (click)="addNewAnswer()"
                    *ngIf="!editAnswer && !addAnswer">
                    <nb-icon icon="plus-outline"></nb-icon>
                </button>
            </h6>
            <ng2-smart-table *ngIf="!editAnswer && !addAnswer" [settings]="settingsAnswer" [source]="examAnswerList"
                (edit)="editNewAnswer($event)" (delete)="deleteNewAnswer($event)" style="text-align: center">
            </ng2-smart-table>
            <div *ngIf="editAnswer || addAnswer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="examAnswer" class="label">Respuesta</label><span style="color:red;">*</span>
                            <input type="text" nbInput fullWidth id="examAnswer" name="examAnswer"
                                [(ngModel)]="answer.examQuestionAnswerResponse" placeholder="Respuesta" required autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="examAnswerType" class="label">¿Es la respuesta correcta?</label><span
                                style="color:red;">*</span><br>
                            <nb-checkbox fullWidth id="examAnswerType" name="examAnswerType"
                                [(ngModel)]="answer.examQuestionAnswerResponseIsCorrect" required>
                            </nb-checkbox>
                        </div>
                    </div>
                </div>
                <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                    (click)="saveCourseExamAnswer()">Guardar respuesta</button>
                <button type="button" nbButton status="basic" ghost
                    style="color: #C81016 !important; vertical-align: baseline;"
                    (click)="cancelAddNewAnswer()">Cancelar</button>
            </div>
            <div *ngIf="!editAnswer && !addAnswer">
                <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                    (click)="saveCourseExamQuestion()">Guardar pregunta</button>
                <button type="button" nbButton ghost style="color: #C81016 !important; vertical-align: baseline;"
                    (click)="cancelAddNewQuestion()">Cancelar</button>
            </div>
        </div>
        <div *ngIf="!editQuestion && !addQuestion">
            <button type="button" nbButton ghost style="color: #0A7E12 !important;"
                (click)="saveCourseExam()">Guardar</button>
            <button type="button" nbButton ghost style="color: #C81016 !important; vertical-align: baseline;"
                (click)="close()">Cancelar</button>
        </div>
    </nb-card-body>
</nb-card> -->