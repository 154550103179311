import { CourseService } from 'src/app/services/course.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import courseAttachmentTable from '../../../../../assets/config/courseTable/courseAttachmentTable.json';
import { Course } from '../../../../models/course';
import { Attachment } from '../../../../models/attachment';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-new-course-attachment-table',
  templateUrl: './new-course-attachment-table.component.html',
  styleUrls: ['./new-course-attachment-table.component.scss']
})
export class NewCourseAttachmentTableComponent implements OnInit, OnChanges {

  @Input() course = {} as Course;
  @Output() attachmentEdit = new EventEmitter<any[]>();
  @Output() attachmentDelete = new EventEmitter<any[]>();
  settings = courseAttachmentTable;
  courseAttachmentList = new Array<Attachment>();

  constructor(
    private courseService: CourseService,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.courseAttachmentList = this.course.courseAttachment;
  }

  deleteAttachment(event) {
    this.attachmentDelete.emit(event);
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    const attachmentName = { name: event.data.attachmentName };
    this.courseService.deleteObject(
      attachmentName,
      companyCode,
      this.course,
      'attachment'
    ).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        // this.toastrService.show('', 'Archivo borrado', { position, status });
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    );
  }

  editAttachment(event) {
    this.attachmentEdit.emit(event);
  }

}
