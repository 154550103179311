import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-window',
  templateUrl: './confirm-window.component.html',
  styleUrls: ['./confirm-window.component.scss']
})
export class ConfirmWindowComponent implements OnInit {
  @Input() message: string;
  loading;

  constructor(
    private dialogRef: NbDialogRef<ConfirmWindowComponent>
  ) { }

  ngOnInit() {
  }

  closeWindow(accept) {
    this.dialogRef.close(accept);
  }

}
