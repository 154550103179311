import { Component, OnInit } from '@angular/core';
import sidebarMenu from '../../../assets/data/sidebarMenu/sidebarMenu.json';
import sidebarMenuAdministrator from '../../../assets/data/sidebarMenu/sidebarMenuAdministrator.json';
import sidebarMenuEditor from '../../../assets/data/sidebarMenu/sidebarMenuEditor.json';
import sidebarMenuUAdmin from '../../../assets/data/sidebarMenu/sidebarMenuUAdmin.json';
import sidebarMenuUser from '../../../assets/data/sidebarMenu/sidebarMenuUser.json';
import { NbMenuItem } from '@nebular/theme';
import { User } from 'src/app/models/user.js';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menu: NbMenuItem[];
  role;

  constructor(
  ) {
    let user = {} as User;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.role = user.userRole ? user.userRole : 'user';
    switch (this.role) {
      case 'administrator':
        this.menu = sidebarMenuAdministrator;
        break;
      case 'editor':
        this.menu = sidebarMenuEditor;
        break;
      case 'user_administrator':
        this.menu = sidebarMenuUAdmin;
        break;
      case 'user':
        this.menu = sidebarMenuUser;
        break;
      default:
        this.menu = sidebarMenu;
        break;
    }
  }

  ngOnInit() {
  }

}
