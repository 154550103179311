<nb-card style="height: 100%;">
  <nb-card-body>
      <div>
          <img src="assets/images/mTraining.png" style="height: 70px;">
      </div>
    <div style="text-align: center;">
        <h1>Bienvenido</h1>
    </div>
    <div style="text-align: center;">
      <h2>{{userName}}</h2>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md">
          <div style="text-align: center;">
            <button nbButton outline status="primary" style="background: transparent;" (click)="mycourses()"><nb-icon icon="book-outline"></nb-icon>Mis cursos</button>
          </div>
        </div>
        <div class="col-md">
          <div style="text-align: center;">
            <button nbButton outline status="primary" style="background: transparent;" (click)="courses()"><nb-icon icon="edit-outline"></nb-icon>Cursos</button>
          </div>
        </div>
        <div class="col-md">
          <div style="text-align: center;">
            <button nbButton outline status="primary" style="background: transparent;" (click)="addUser()"><nb-icon icon="people-outline"></nb-icon>Usuarios</button>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
