<div class="card-col">
    <nb-card style="height: 20rem !important;">
        <nb-card-header style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;"><h4 style="color: white !important;">Resultado</h4></nb-card-header>
        <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
            <h4>{{((100 / examCountQuestions) * examResult)}} / 100</h4>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="close()">Aceptar</button>
        </nb-card-footer>
    </nb-card>
</div>