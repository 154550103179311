import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-notes',
  templateUrl: './user-notes.component.html',
  styleUrls: ['./user-notes.component.scss']
})
export class UserNotesComponent implements OnInit {
  userNoteLists: any[];

  constructor() { }

  ngOnInit() {
  }

  userNotesListReceiver($event){
    this.userNoteLists = $event;
  }

}
