import { Component, Input, OnInit } from '@angular/core';
import { NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';
import { NbDialogRef } from '@nebular/theme';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { Company } from 'src/app/models/company.js';
import unassignMultipleUsersTable from '../../../../assets/config/unassignMultipleUsersTable.json';
import { LocalDataSource } from 'ng2-smart-table';
import { element } from 'protractor';


@Component({
  selector: 'app-unassign-multiple-users',
  templateUrl: './unassign-multiple-users.component.html',
  styleUrls: ['./unassign-multiple-users.component.scss']
})
export class UnassignMultipleUsersComponent implements OnInit {
  @Input() userList;
  loading: boolean;
  checkedUser = false;
  settings = unassignMultipleUsersTable;
  userListTable: LocalDataSource;
  userListActive = new Array();
  userListInactive = new Array();
  event = new Array();

  toggleUser($event , checked: boolean) {
    console.log($event);
    this.checkedUser = checked;
  }

  constructor(
    private dialogRef: NbDialogRef<UnassignMultipleUsersComponent>,
    private authService: AuthService,
    private toastrService: NbToastrService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.activeUser();
  }

  activeUser() {
    this.userList.forEach(element => {
      if (element.userStatus === 'Activo') {
        this.userListActive.push(element);
      } else if (element.userStatus === 'Inactivo') {
        this.userListInactive.push(element);
      }
    });
  }

  cancelUnassignUser() {
    this.dialogRef.close();
  }

  onUserRowSelect(event) {
    this.event = event;
  }

  deleteUser(event) {
    event.selected.forEach(element => {
      this.loading = true;
      const username = element.username;
      const name = element.name;
      const userRole = element.userRole;
      this.authService.disableUser(username).then(
        (result) => {
          let user = {} as User;
          if (localStorage.getItem('user') !== null) {
            user = JSON.parse(localStorage.getItem('user'));
          }
          this.companyService.oneCompanyBackend(user.company.companyCode).then(
            (resultCompany) => {
              const company: Company = resultCompany.body;

              switch (userRole) {
                case 'user':
                  company.companyCountUser--;
                  break;
                case 'user_administrator':
                  company.companyCountUserAdmin--;
                  break;
                case 'editor':
                  company.companyCountUserEditor--;
                  break;

                default:
                  break;
              }

              this.companyService.updateCompanyBackend(company).then(
                (resultCompanyUpdate) => {
                  const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status = 'success';
                  this.toastrService.show(
                    '',
                    'Usuario ' + name + ' deshabilitado',
                    { position, status });
                  console.log(this.userList);
                  this.userList.userStatus = 'Inactivo';
                  this.userListTable = new LocalDataSource(this.userList);
                  this.userListTable = new LocalDataSource(this.userList);
                  this.loading = false;
                }
              ).catch(
                (err) => {
                  const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                });
            }
          ).catch(
            (err) => {
              const position = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        }
      ).catch(
        (err) => {
          const position = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        }
      );
    });
    this.cancelUnassignUser();
  }

  activateUser(event) {
    event.selected.forEach(element => {
      this.loading = true;
      let user = {} as User;
      const username = element.username;
      const name = element.name;
      const userRole = element.userRole;
      if (localStorage.getItem('user') !== null) {
        user = JSON.parse(localStorage.getItem('user'));
      }
      this.companyService.oneCompanyBackend(user.company.companyCode).then(
        (resultCompanyValidation) => {
          const companyValidator: Company = resultCompanyValidation.body;
          switch (userRole) {
            case 'user':
              if (companyValidator.companyCountUser >= companyValidator.companyMaxUser) {
                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'danger';
                this.toastrService.show(
                  '',
                  'Cantidad máxima de tipo USUARIO excedida',
                  { position, status });
                this.loading = false;
                return false;
              }
              break;
            case 'user_administrator':
              if (companyValidator.companyCountUserAdmin >= companyValidator.companyMaxUserAdmin) {
                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'danger';
                this.toastrService.show(
                  '',
                  'Cantidad máxima de tipo ADMINISTRADOR DE USUARIO excedida',
                  { position, status });
                this.loading = false;
                return false;
              }
              break;
            case 'editor':
              if (companyValidator.companyCountUserEditor >= companyValidator.companyMaxUserEditor) {
                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'danger';
                this.toastrService.show(
                  '',
                  'Cantidad máxima de tipo EDITOR excedida',
                  { position, status });
                this.loading = false;
                return false;
              }
              break;

            default:
              break;
          }

          this.authService.enableUser(username).then(
            (result) => {
              this.companyService.oneCompanyBackend(user.company.companyCode).then(
                (resultCompany) => {
                  const company: Company = resultCompany.body;

                  switch (userRole) {
                    case 'user':
                      company.companyCountUser++;
                      break;
                    case 'user_administrator':
                      company.companyCountUserAdmin++;
                      break;
                    case 'editor':
                      company.companyCountUserEditor++;
                      break;

                    default:
                      break;
                  }

                  this.companyService.updateCompanyBackend(company).then(
                    (resultCompanyUpdate) => {

                      const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                      const status = 'success';
                      this.toastrService.show(
                        '',
                        'Usuario ' + name + ' habilitado',
                        { position, status });
                      this.userList.userStatus = 'Activo';
                      this.userListTable = new LocalDataSource(this.userList);
                      this.loading = false;
                    }
                  ).catch(
                    (err) => {
                      const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                      const status = 'danger';
                      this.toastrService.show(
                        '',
                        err.message,
                        { position, status });
                      this.loading = false;
                    });
                }
              ).catch(
                (err) => {
                  const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                  const status = 'danger';
                  this.toastrService.show(
                    '',
                    err.message,
                    { position, status });
                  this.loading = false;
                }
              );
            }
          ).catch(
            (err) => {
              const position = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
        }
      ).catch(
        (err) => {
          const position = NbGlobalPhysicalPosition.TOP_RIGHT;
          const status = 'danger';
          this.toastrService.show(
            '',
            err.message,
            { position, status });
          this.loading = false;
        });
    });
    this.cancelUnassignUser();
  }

}
