import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterDepartmentAssignPipe'
})
export class FilterDepartmentAssignPipe implements PipeTransform {

    transform(list: any, arg: any): any {
        if (arg === '' || arg.length < 3) { return list; }
        const result = [];
        for (const value of list) {
            if (value.department.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
                result.push(value);
            }
        }
        return result;
    }
}
