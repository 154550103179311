import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbToastrService } from '@nebular/theme';
import { CourseService } from 'src/app/services/course.service';
import { User } from 'src/app/models/user.js';
import { Company } from 'src/app/models/company.js';
import { float } from 'aws-sdk/clients/lightsail';
@Component({
  selector: 'app-storage-admin-chart',
  templateUrl: './storage-admin-chart.component.html',
  styleUrls: ['./storage-admin-chart.component.scss']
})
export class StorageAdminChartComponent implements OnInit, AfterViewInit {
  loading: boolean;
  usedSpace: float;
  usedSpaceCognotation = '';
  allSpace: float;
  allSpaceCognotation = '';
  freeSpace: float;
  freeSpaceCognotation = '';
  percent: float;
  status = '';

  constructor(
    private companyService: CompanyService,
    private toastrService: NbToastrService,
    private courseService: CourseService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getReportStorageData();
  }

  getStatus(percent) {
    if (percent <= 50) {
      return 'success';
    } else if (percent <= 75) {
      return 'warning';
    } else if (percent <= 100) {
      return 'danger';
    } else {
      return 'success';
    }
  }

  getReportStorageData() {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    this.loading = true;
    this.courseService.listFileS3().then(
      (result) => {
        const content = result.Contents;
        const sizeByCourse = new Array();
        content.forEach((file) => {
          const fileKey = file.Key.split('/');
          const companyFolderRoot = fileKey[0];
          const companyFolder = fileKey[1];
          if (companyFolderRoot === 'company') {
            if (companyFolder === userLogged.company.companyCode) {
              sizeByCourse[companyFolder] = (sizeByCourse[companyFolder] ? sizeByCourse[companyFolder] : 0) + file.Size;
            }
          }
        });
        this.courseService.allCourseCompanyBackend(userLogged.company.companyCode)
          .then(
            (resultCompany) => {
              const companys = resultCompany.body;
              const valueBucket = (sizeByCourse[userLogged.company.companyCode] ? sizeByCourse[userLogged.company.companyCode] : 0);
              this.companyService.oneCompanyBackend(userLogged.company.companyCode).then(
                (companyResult) => {
                  const company: Company = companyResult.body;
                  const valueTable = parseInt(company.companyMaxFileStorage, 10);
                  const formatBucket = this.formatSizeUnits(valueBucket);
                  const formatTable = this.formatSizeUnits(valueTable);
                  const freeSpace = valueTable - valueBucket;
                  const formatFree = this.formatSizeUnits(freeSpace);
                  const percent = ((valueBucket * 100) / valueTable).toFixed(8);

                  this.status = this.getStatus(percent);
                  this.percent = +percent;
                  this.usedSpace = formatBucket.unit;
                  this.usedSpaceCognotation = formatBucket.cognotation;
                  this.allSpace = formatTable.unit;
                  this.allSpaceCognotation = formatTable.cognotation;
                  this.freeSpace = formatFree.unit;
                  this.freeSpaceCognotation = formatFree.cognotation;
                });

            }
          ).catch(
            (err) => {
              const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status: NbComponentStatus = 'danger';
              this.toastrService.show(
                '',
                err.message,
                { position, status });
              this.loading = false;
            }
          );
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  formatSizeUnits(value: float) {
    let unit = value;
    let cognotation = 'bytes';

    if (value >= 1000000000) {
      unit = +(value / 1000000000).toFixed(2);
      cognotation = 'gb';
    } else if (value >= 1000000) {
      unit = +(value / 1000000).toFixed(2);
      cognotation = 'mb';
    } else if (value >= 1000) {
      unit = +(value / 1000).toFixed(2);
      cognotation = 'kb';
    } else if (value > 1) {
      unit = value;
      cognotation = 'bytes';
    } else if (value === 1) {
      unit = value;
      cognotation = 'bytes';
    } else if (value < 1) {
      unit = value;
      cognotation = 'bytes';
    }
    return {
      unit,
      cognotation
    };
  }

}
