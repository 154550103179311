// export const environment = {
//   production: false,
//   UserPoolId: 'us-east-1_84wQhGmsc',
//   ClientId: '7l1jvb3i2pd4c68aif7i843ii7',
//   API_URL: 'https://l904nl01i4.execute-api.us-east-1.amazonaws.com/dev/',
//   accessKeyId: 'AKIAV42YBFE6YT626ILD',
//   secretAccessKey: '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',
//   region: 'us-east-1',
//   bucketName: 'mtraining-dev-videos',
//   bucketNameApp: 'mtraining-dev-myapp',
//   regionSES: 'us-east-1',
//   emailSES: 'https://d3l66c711jieyd.cloudfront.net/',
//   emailImage: 'https://d3l66c711jieyd.cloudfront.net/assets/images/emailLogo.jpg',
//   cloudFrontUrl: 'https://d3l66c711jieyd.cloudfront.net/'
// };

/* export const environment = {
  production: true,
  UserPoolId: 'us-east-1_fBUewfjLm',
  ClientId: 'scqvcv1aoiljjg3t6rk09ubnd',
  API_URL: 'https://ehqs163u3b.execute-api.us-east-1.amazonaws.com/qa/',
  accessKeyId: 'AKIAV42YBFE6TQQVPSHC',
  secretAccessKey: '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',
  region: 'us-east-1',
  bucketName: 'mtraining-qa-videos',
  bucketNameApp: 'mtraining-qa-myapp',
  regionSES: 'us-east-1',
  emailSES: 'https://myapptraining.net/',
  emailImage: 'https://myapptraining.net/assets/images/emailLogo.jpg',
  cloudFrontUrl: 'https://d2pad8tqrcimg.cloudfront.net/',

  identityPoolId: 'us-east-1:7efccf5e-b779-41c4-be5f-6d152be34c96',
  cognitoDomain: 'mtraining-qa.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'http://localhost:8083/',
  cognitoRedirectSignOut: 'http://localhost:8083/',
  cognitoResponseType: 'code',
};
 */
export const environment = {
  production: true,
  UserPoolId: 'us-west-2_avKyGksjx',
  ClientId: '3l5euonligqgqti29ei0n2k0tj',
  API_URL: 'https://pmn8i7v32h.execute-api.us-west-2.amazonaws.com/prod/',
  accessKeyId: 'AKIAV42YBFE6WNXZ4RVD',
  secretAccessKey: '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',
  region: 'us-west-2',
  bucketName: 'mtraining-prod-videos',
  bucketNameApp: 'mtraining-prod-myapp',
  regionSES: 'us-east-1',
  emailSES: 'https://myapptraining.com/',
  emailImage: 'https://myapptraining.com/assets/images/emailLogo.jpg',
  cloudFrontUrl: 'https://dd9nqwrdd86ig.cloudfront.net/',

  identityPoolId: 'us-east-1:7efccf5e-b779-41c4-be5f-6d152be34c96',
  cognitoDomain: 'mtraining-qa.auth.us-east-1.amazoncognito.com',
  cognitoRedirectSignIn: 'http://localhost:8083/',
  cognitoRedirectSignOut: 'http://localhost:8083/',
  cognitoResponseType: 'code',
};
