<div class="col-md-12 table-responsive" #epltable *ngIf="dataSourse[0]">
    <div style="padding-bottom: 10px;padding-top: 10px;">
        <div class="row">
            <div class="col-sm-12">
                <button nbButton ghost style="color: #0A7E12 !important;" (click)="exportToExcel()"
                    nbTooltip="Descargar Excel">
                    Descargar Excel
                    <nb-icon icon="cloud-download-outline"></nb-icon>
                </button>
            </div>
        </div>
    </div>
    <table>
        <thead>
            <tr>
                <th style="text-align: center;" *ngFor="let head of dataSourse[0] | keys">{{head}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of dataSourse">
                <td *ngFor="let list of item | keys">{{item[list]}}</td>
            </tr>
        </tbody>
    </table>
</div>