import { CourseService } from 'src/app/services/course.service';
import { CompanyService } from 'src/app/services/company.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Course } from 'src/app/models/course';
import courseVideoTable from '../../../../../assets/config/courseTable/courseVideoTable.json';
import { Video } from 'src/app/models/video.js';
import { NbToastrService, NbGlobalPosition, NbGlobalPhysicalPosition, NbComponentStatus, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-new-course-video-table',
  templateUrl: './new-course-video-table.component.html',
  styleUrls: ['./new-course-video-table.component.scss']
})
export class NewCourseVideoTableComponent implements OnInit, OnChanges {

  @Input() course = {} as Course;
  @Output() courseEdit = new EventEmitter<any[]>();
  @Output() courseDelete = new EventEmitter<any[]>();
  courseVideoList = new Array<Video>();
  settings = courseVideoTable;

  constructor(
    private companyService: CompanyService,
    private courseService: CourseService,
    private toastrService: NbToastrService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.courseVideoList = this.course.courseVideo;
  }

  deleteVideo(event) {
    const userLogged = JSON.parse(localStorage.getItem('user'));
    const companyCode = userLogged.company.companyCode;
    const videoName = { name: event.data.videoName };
    this.courseDelete.emit(event);
    this.courseService.deleteObject(
      videoName,
      companyCode,
      this.course,
      'video'
    ).then(
      (result) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'success';
        // this.toastrService.show('', 'Video borrado', { position, status });
      }
    ).catch(
      (err) => {
        const position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status: NbComponentStatus = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
      }
    );
  }

  editVideo(event) {
    this.courseEdit.emit(event);
  }

}
