import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Course } from '../models/course';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import attachmentExpireUrl from '../../assets/data/restrictions/attachmentExpireUrl.json';

@Injectable({
  providedIn: 'root'
})

export class CourseService {
  courseProgress = new Subject<any>();
  courseTable = 'mtraining-course';
  courseFolder = 'course';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  searchCourseBackend(searchValue: any): Promise<any> {
    let filter = searchValue.toLowerCase();
    filter = filter.replace(' ', '-');
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.courseTable + '/search/' + filter + queryParam, { headers }).toPromise();
  }

  oneCourseBackend(courseCode: string): Promise<any> {
    const filter = courseCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.courseTable + '/one/' + filter + queryParam, { headers }).toPromise();
  }

  updateCourseBackend(course: Course): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.put(environment.API_URL + this.courseTable + '/', course, { headers }).toPromise();
  }

  allCourseBackend(): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.courseTable + queryParam, { headers }).toPromise();
  }

  allCourseCompanyBackend(companyCode): Promise<any> {
    const filter: string = companyCode;
    const openedSession = this.authService.getAuthenticatedUserSession();
    const queryParam = '?accessToken=' + openedSession.getAccessToken().getJwtToken();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.get(environment.API_URL + this.courseTable + '/company/' + filter + queryParam, { headers }).toPromise();
  }

  insertCourseBackend(newcourse: Course): Promise<any> {
    const openedSession = this.authService.getAuthenticatedUserSession();
    const headers = new HttpHeaders({ Authorization: openedSession.getIdToken().getJwtToken() });
    return this.http.post(environment.API_URL + this.courseTable, newcourse, { headers }).toPromise();
  }

  uploadFileS3(file, companyFolder, course: Course, folder): Promise<any> {
    this.courseProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + companyFolder + '/' + this.courseFolder + '/' + course.courseCode + '/' + folder + '/' + file.name,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.courseProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        data.Location = environment.cloudFrontUrl + params.Key.replace(' ', '+');
        return resolve(data);
      });
    });
  }

  deleteObject(file, companyFolder, course: Course, folder) {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + companyFolder + '/' + this.courseFolder + '/' + course.courseCode + '/' + folder + '/' + file.name,
    };
    return new Promise((resolve, reject) => {
      bucket.deleteObject(params).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

  signS3Url(file, companyFolder, course: Course, folder): Promise<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region,
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + companyFolder + '/' + this.courseFolder + '/' + course.courseCode + '/' + folder + '/' + file.name,
      Expires: attachmentExpireUrl.expire
    };
    return new Promise((resolve, reject) => {
      bucket.getSignedUrl('getObject', params, (err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(data);
      });
    });
  }

  blockFileS3(file, companyFolder, course: Course, folder, i): Promise<any> {
    this.courseProgress.next(0);
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName,
      Key: 'company' + '/' + companyFolder + '/' + this.courseFolder + '/' + course.courseCode + '/' + folder,
      Body: file,
      ACL: 'private',
      ContentType: contentType
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params).on('httpUploadProgress', (evt) => {
        const percent = (evt.loaded / evt.total) * 100;
        this.courseProgress.next(percent);
      }).send((err, data) => {
        if (err) {
          return reject(err);
        }
        return resolve(i);
      });
    });
  }

  listFileS3(): Promise<any> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region
      }
    );
    const params = {
      Bucket: environment.bucketName
    };
    return new Promise((resolve, reject) => {
      bucket.listObjects(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

}
