<app-loading *ngIf="loading"></app-loading>
<nb-card style="height: 95% !important;">
    <nb-card-body>
        <nb-tabset fullWidth>
            <nb-tab tabTitle="Empresa" tabIcon="file-add-outline" responsive>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="companyName" class="label">Empresa</label>
                            <input type="text" nbInput fullWidth id="companyName" name="companyName"
                                [(ngModel)]="company.companyName" placeholder="Empresa" required [readonly]="true"
                                autocomplete="off" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="companyMaxUserAdmin" class="label">Administradores de usuarios</label>
                            <input type="text" nbInput fullWidth id="companyMaxUserAdmin" name="companyMaxUserAdmin"
                                [(ngModel)]="company.companyMaxUserAdmin" placeholder="Administradores de usuarios"
                                required [readonly]="true" autocomplete="off" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="companyMaxUserEditor" class="label">Editores</label>
                            <input type="text" nbInput fullWidth id="companyMaxUserEditor" name="companyMaxUserEditor"
                                [(ngModel)]="company.companyMaxUserEditor" placeholder="Editores" required
                                [readonly]="true" autocomplete="off" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="companyMaxUser" class="label">Usuarios</label>
                            <input type="text" nbInput fullWidth id="companyMaxUser" name="companyMaxUser"
                                [(ngModel)]="company.companyMaxUser" placeholder="Usuarios" required [readonly]="true"
                                autocomplete="off" maxlength="20">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label for="companyMaxFileStorage" class="label">Almacenamineto</label>
                            <input type="text" nbInput fullWidth id="companyMaxFileStorage" name="companyMaxFileStorage"
                                value="{{storage | number : '1.0-5'}} GB" placeholder="Espacio Disponible" required
                                [readonly]="true" autocomplete="off" maxlength="20">
                        </div>
                    </div>
                </div>
                <div style="display: flex; margin-top: 0.5rem;">
                    <!-- <span style="padding-top: 0.4rem; margin-right: 0.35rem;">Scorm</span> -->
                    <!-- <nb-toggle id="companyScormSelected" name="companyScormSelected" [(ngModel)]="company.companyScormSelected" [(checked)]="companyScormSelected"></nb-toggle> -->
                    <button nbButton nbTooltip="Guardar" ghost style="color: #0A7E12 !important;" (click)="updateCompany()">
                        Guardar
                      </button>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Portada" tabIcon="image-outline" responsive *ngIf="company.companyCode">
                <button nbButton ghost (click)="addCompanyImage()">
                    <nb-icon icon="plus-outline" size="giant"></nb-icon>
                </button>
                <button nbButton ghost>
                    <nb-icon icon="refresh-outline" size="giant"></nb-icon>
                </button>
                <div *ngIf="company.companyLogo">
                    <h2>Imagen de Empresa: </h2>
                    <div style="padding-left: 8rem !important;"><img [src]="company.companyLogo" style="width: 10rem;">
                    </div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Departamentos" tabIcon="image-outline" responsive *ngIf="company.companyCode">
                <button nbButton ghost nbTooltip="Agregar Departamentos" (click)="addCompanyDepartments()">
                    <nb-icon icon="plus-outline"></nb-icon>
                </button>
                <button nbButton ghost nbTooltip="Actualizar Departamentos"
                    (click)="getCompany(this.company.companyCode)">
                    <nb-icon icon="refresh-outline"></nb-icon>
                </button>
                <app-company-departments-table [company]="company"
                    (departmentsDelete)="deleteCompanyDepartments($event)">
                </app-company-departments-table>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>
</nb-card>