<app-loading *ngIf="loading"></app-loading>
<nb-card>
    <nb-card-header>
        <h1>Reporte de resultados por usuario por curso</h1>
    </nb-card-header>
    <nb-card-body>
      <app-report-user-result-table ></app-report-user-result-table>
      <!-- <app-report-viewer [dataSourse]="dataSourse" [reportName]="reportName"></app-report-viewer> -->
    </nb-card-body>
</nb-card>
