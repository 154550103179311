import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  urlAlt = 'Myappsoftware';
  urlLog = 'assets/images/mLogo.png';

  constructor(private companyService: CompanyService, ) { }

  async ngOnInit() {
    await this.setBackground();
  }

  setBackground() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user !== undefined) {
      this.companyService.oneCompanyBackend(user.company.companyCode).then(
        (result) => {
          this.urlLog = result.body.companyLogo === undefined ? 'assets/images/mLogo.png' : result.body.companyLogo;

        }).catch(
          (err) => {
            this.urlLog = 'assets/images/mLogo.png';
          }
        ).finally(
          () => {
          }
        );
    }
  }

}