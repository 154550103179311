<div class="card-col">
  <div>
    <nb-card style="height: 20rem !important;border-radius: 30px !important;">
      <nb-card-header
        style="text-align: center; padding: 0.5rem; background-color: #1378DE !important;border-top-right-radius: 30px;border-top-left-radius: 30px;">
        <h4 style="color: white !important;">Cambiar contraseña</h4>
      </nb-card-header>
      <nb-card-body style="text-align: center !important; margin-top: 3rem !important;">
        <form>
          <div class="form-group">
            <div style="padding-bottom: 3px !important; ">
              <input type="text" nbInput fullWidth [(ngModel)]="verificationCode" name="verificationCode"
                id="verificationCode" placeholder="Código de validación" autocomplete="off" maxlength="8"
                style="width: 30rem !important; margin-bottom: 1rem;">
            </div>
          </div>
          <div class="form-group">
            <div style="padding-bottom: 3px !important; ">
              <input type="text" nbInput fullWidth [(ngModel)]="newPassword" name="newPassword" id="newPassword"
                autocomplete="off" placeholder="Ingrese su nueva contraseña" maxlength="25"
                style="width: 30rem !important; margin-bottom: 1rem;">
            </div>
          </div>
          <div class="form-group" style="text-align: -webkit-center;">
            <div style="margin-top: 5px;">
              <button type="submit" nbButton ghost style="margin-right: 17rem; color: #0A7E12 !important;"
                (click)="closeWithData()">Confirmar</button>
              <button type="submit" nbButton ghost style="margin-left: 5px; color: #C81016 !important;"
                (click)="close()">Salir</button>
            </div>
          </div>
        </form>
      </nb-card-body>
    </nb-card>