import { UserEditComponent } from './../new-user-fields/user-edit/user-edit.component';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import newUserTableSettings from '../../../../assets/config/courseTable/newUserTableSettings.json';
import { AuthService } from 'src/app/services/auth.service';
import { LocalDataSource } from 'ng2-smart-table';
import { NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';
import { CompanyService } from 'src/app/services/company.service.js';
import { Company } from 'src/app/models/company.js';
import { User } from 'src/app/models/user.js';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-new-user-table',
  templateUrl: './new-user-table.component.html',
  styleUrls: ['./new-user-table.component.scss']
})
export class NewUserTableComponent implements OnInit, OnChanges {
  settings = newUserTableSettings;
  @Input() userList: any[];
  filterUser = '';
  userRoleForEdit: any;

  userListTable: LocalDataSource;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private toastrService: NbToastrService,
    private companyService: CompanyService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit() {
  }

  onSearchKeyPress(query: string = '') {
    if (query.length >= 3) {
      this.userListTable.setFilter([
        {
          field: 'username',
          search: query
        }
      ], false);
    } else {
      this.userListTable = new LocalDataSource(this.userList);
    }
  }

  ngOnChanges() {
    this.userListTable = new LocalDataSource(this.userList);
  }

  deleteUser(event) {
    if (event.data.userStatus === 'Inactivo') {
      const position = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status = 'danger';
      this.toastrService.show(
        '',
        'Este usuario ya esta inactivo.',
        { position, status });
      this.loading = false;
      return false;
    }
    this.loading = true;
    const username = event.data.username;
    const name = event.data.name;
    const userRole = event.data.userRole;
    this.authService.disableUser(username).then(
      (result) => {
        let user = {} as User;
        if (localStorage.getItem('user') !== null) {
          user = JSON.parse(localStorage.getItem('user'));
        }
        this.companyService.oneCompanyBackend(user.company.companyCode).then(
          (resultCompany) => {
            const company: Company = resultCompany.body;

            switch (userRole) {
              case 'user':
                company.companyCountUser--;
                break;
              case 'user_administrator':
                company.companyCountUserAdmin--;
                break;
              case 'editor':
                company.companyCountUserEditor--;
                break;

              default:
                break;
            }

            this.companyService.updateCompanyBackend(company).then(
              (resultCompanyUpdate) => {

                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'success';
                this.toastrService.show(
                  '',
                  'Usuario ' + name + ' deshabilitado',
                  { position, status });
                this.userList[event.index].userStatus = 'Inactivo';
                this.userListTable = new LocalDataSource(this.userList);
                this.userListTable = new LocalDataSource(this.userList);
                this.loading = false;
              }
            ).catch(
              (err) => {
                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'danger';
                this.toastrService.show(
                  '',
                  err.message,
                  { position, status });
                this.loading = false;
              });
          }
        ).catch(
          (err) => {
            const position = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
      }
    ).catch(
      (err) => {
        const position = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      }
    );
  }

  activateUser(event) {
    if (event.data.userStatus === 'Activo') {
      const position = NbGlobalPhysicalPosition.TOP_RIGHT;
      const status = 'danger';
      this.toastrService.show(
        '',
        'Este usuario ya esta activo.',
        { position, status });
      this.loading = false;
      return false;
    }
    this.loading = true;
    let user = {} as User;
    const username = event.data.username;
    const name = event.data.name;
    const userRole = event.data.userRole;
    if (localStorage.getItem('user') !== null) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    this.companyService.oneCompanyBackend(user.company.companyCode).then(
      (resultCompanyValidation) => {
        const companyValidator: Company = resultCompanyValidation.body;
        switch (userRole) {
          case 'user':
            if (companyValidator.companyCountUser >= companyValidator.companyMaxUser) {
              const position = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status = 'danger';
              this.toastrService.show(
                '',
                'Cantidad máxima de tipo USUARIO excedida',
                { position, status });
              this.loading = false;
              return false;
            }
            break;
          case 'user_administrator':
            if (companyValidator.companyCountUserAdmin >= companyValidator.companyMaxUserAdmin) {
              const position = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status = 'danger';
              this.toastrService.show(
                '',
                'Cantidad máxima de tipo ADMINISTRADOR DE USUARIO excedida',
                { position, status });
              this.loading = false;
              return false;
            }
            break;
          case 'editor':
            if (companyValidator.companyCountUserEditor >= companyValidator.companyMaxUserEditor) {
              const position = NbGlobalPhysicalPosition.TOP_RIGHT;
              const status = 'danger';
              this.toastrService.show(
                '',
                'Cantidad máxima de tipo EDITOR excedida',
                { position, status });
              this.loading = false;
              return false;
            }
            break;

          default:
            break;
        }

        this.authService.enableUser(username).then(
          (result) => {
            this.companyService.oneCompanyBackend(user.company.companyCode).then(
              (resultCompany) => {
                const company: Company = resultCompany.body;

                switch (userRole) {
                  case 'user':
                    company.companyCountUser++;
                    break;
                  case 'user_administrator':
                    company.companyCountUserAdmin++;
                    break;
                  case 'editor':
                    company.companyCountUserEditor++;
                    break;

                  default:
                    break;
                }

                this.companyService.updateCompanyBackend(company).then(
                  (resultCompanyUpdate) => {

                    const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status = 'success';
                    this.toastrService.show(
                      '',
                      'Usuario ' + name + ' habilitado',
                      { position, status });
                    this.userList[event.index].userStatus = 'Activo';
                    this.userListTable = new LocalDataSource(this.userList);
                    this.loading = false;
                  }
                ).catch(
                  (err) => {
                    const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                    const status = 'danger';
                    this.toastrService.show(
                      '',
                      err.message,
                      { position, status });
                    this.loading = false;
                  });
              }
            ).catch(
              (err) => {
                const position = NbGlobalPhysicalPosition.TOP_RIGHT;
                const status = 'danger';
                this.toastrService.show(
                  '',
                  err.message,
                  { position, status });
                this.loading = false;
              }
            );
          }
        ).catch(
          (err) => {
            const position = NbGlobalPhysicalPosition.TOP_RIGHT;
            const status = 'danger';
            this.toastrService.show(
              '',
              err.message,
              { position, status });
            this.loading = false;
          }
        );
      }
    ).catch(
      (err) => {
        const position = NbGlobalPhysicalPosition.TOP_RIGHT;
        const status = 'danger';
        this.toastrService.show(
          '',
          err.message,
          { position, status });
        this.loading = false;
      });
  }
  editUser(event) {
    this.dialogService.open(UserEditComponent,
      {
        context: {
          userRoleForEdit: event.data.userId
        },
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: false
      }).onClose .subscribe((result) => {
        if (result !== undefined) {
          const idForIndex = this.userList.find((x) => x.userId === result.userId);
          const indexFotRefresh = this.userList.indexOf(idForIndex);
          this.userList[indexFotRefresh].userRoleName = result.userRoleName;
          this.userListTable = new LocalDataSource(this.userList);
        }
      });
  }
}
