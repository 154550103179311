<div>
    <div>
      <nb-card>
        <nb-card-header>Notas de usuarios</nb-card-header>
        <nb-card-body>
          <div echarts [options]="options" class="echart"></div>
        </nb-card-body>
      </nb-card>
    </div>
</div>
