import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Attachment } from '../../../models/attachment';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  uploadProgress: Attachment[];
  @Input() progress;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

}
