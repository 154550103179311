<app-loading *ngIf="loading"></app-loading>
<nb-card accent="warning">
    <nb-card-header>
        <h2>{{exam.examName}}</h2>
        <countdown [config]="{leftTime: (exam.examTime * 60)}" (event)="timesUp($event)">$!m!:$!s!</countdown>
    </nb-card-header>
    <nb-card-body>
        <div>
            <div *ngFor="let question of examQuestions; let questionIndex = index">
                <span style="font-weight: bold;">{{questionIndex + 1}}. {{question.examQuestion}}</span>
                <div *ngIf="question.examQuestionAnswerCounter > 1">
                    <div *ngFor="let answer of question.examQuestionAnswer; let answerIndex = index">
                        <nb-checkbox [disabled]="enableEdit"
                            [(ngModel)]="userQuestions[questionIndex].examQuestionAnswer[answerIndex].examQuestionAnswerResponseIsCorrect">
                            {{answer.examQuestionAnswerResponse}}</nb-checkbox>
                    </div>
                </div>
                <div *ngIf="question.examQuestionAnswerCounter === 1">
                    <nb-radio-group [name]="question.examQuestion"
                        [(ngModel)]="userQuestions[questionIndex].examQuestionAnswerOne">
                        <nb-radio [value]="answer.examQuestionAnswerResponse" [disabled]="enableEdit"
                            *ngFor="let answer of question.examQuestionAnswer">
                            {{answer.examQuestionAnswerResponse}}</nb-radio>
                    </nb-radio-group>
                </div>
            </div>
            <br>
            <button nbButton (click)="checkExam()">Terminar</button>
        </div>
    </nb-card-body>
</nb-card>