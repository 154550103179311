import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  userList: any[];

  constructor() { }

  ngOnInit() {
  }

  userListReceiver($event) {
    this.userList = $event;
  }
}
