<div class="row" fullWidth>
  <div class="col-xxl-12">
    <div class="row">
      <div class="col-md-6">
        <app-user-count-status></app-user-count-status>
      </div>
      <div class="col-md-6">
        <app-storage-admin-chart></app-storage-admin-chart>
      </div>
    </div>
  </div>
</div>
<div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div class="col-md-6">
          <app-bar-chart-course-notes></app-bar-chart-course-notes>
        </div>
        <div class="col-md-6">
          <app-dispersion-chart-admin></app-dispersion-chart-admin>
        </div>
      </div>
  </div>
</div>






<!-- <div class="row">
  <app-storage-admin-chart class="col-lg-6"></app-storage-admin-chart>
  <div class="col-lg-6">
    <nb-card>
      <nb-card-header>Estado de usuarios</nb-card-header>
      <nb-card-body>
        <div echarts [options]="options" class="echart"></div>
      </nb-card-body>
    </nb-card>
  </div>
  <app-bar-chart-course-notes class="col-lg-6"></app-bar-chart-course-notes>
  <app-dispersion-chart-admin class="col-lg-6"></app-dispersion-chart-admin>
</div> -->